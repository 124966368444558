import { notification } from "antd";
import { Functions, applyReplacements } from "../../../../../../../helpers/constraintParsers";
import { getForeignInputNames } from "../helpers/getForeignInputNames";
import useInputValuesByName from "./useInputValueByName";

const useEvaluateConstraint = (constraint, value, meta = []) => {
   const trimmedConstraint = constraint?.trim();
   const inputNames = getForeignInputNames(constraint);
   const relatedInputs = useInputValuesByName(inputNames);

   if (!trimmedConstraint || !value) {
      return null;
   }

   const parsedConstraint = applyReplacements(trimmedConstraint);

   console.log("🚀 ~ evaluateConstraint ~ parsedConstraint", {
      constraint: trimmedConstraint,
      parsedConstraint,
      value,
      relatedInputs
   });
   try {
      // eslint-disable-next-line no-new-func
      const evaluate = new Function(
         "value",
         ...Object.keys(Functions),
         "relatedInputs",
         "meta",
         `return ${parsedConstraint};`
      );
      return evaluate(value, ...Object.values(Functions), relatedInputs, meta);
   } catch (e) {
      notification.warning({
         message: "Error evaluating xlsform fields",
         description:
            "Please check if the syntax is correct. If you believe this is an error, please contact your administrator."
      });
      console.log(
         "Error evaluating constraint. please check if the constraint syntax is correct. -- " + e
      );

      // throw new Error(
      //    "Error evaluating constraint. please check if the constraint syntax is correct. -- " + e
      // );
   }
};

export default useEvaluateConstraint;
