import objectPath from "object-path";
import React, { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Brand } from "../brand/Brand";
import { AdminAsideMenu } from "./aside-menu/AdminAsideMenu";
import { FiChevronLeft } from "react-icons/fi";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export function AdminAside({ onMenuMinimize }) {
   const uiService = useHtmlClassService();
   //  const location = useLocation();
   const layoutProps = useMemo(() => {
      return {
         disableScroll: objectPath.get(uiService.config, "aside.menu.dropdown") === "true" || false,
         isAdminLayout: true,
         asideClassesFromConfig: uiService.getClasses("aside", true),
         disableAsideSelfDisplay: objectPath.get(uiService.config, "aside.self.display") === false,
         headerLogo: uiService.getLogo()
      };
   }, [uiService]);

   const [isMinimized, setIsMinimized] = useState(false);

   // const minimizeMenu = () => {
   //    document.body.classList.toggle("sidebar-minimize");
   //    onMenuMinimize(!isMinimized);
   //    setIsMinimized(!isMinimized);
   // };

   return (
      <>
         {/* begin::Aside */}
         <div
            id="kt_aside"
            className={`aside aside-left aside-admin ${
               layoutProps.asideClassesFromConfig
            } d-flex flex-column flex-row-auto admin-aside ${isMinimized ? "minimized" : ""}
            `}
         >
            <Brand />

            {/* begin::Aside Menu */}
            <div
               id="kt_aside_menu_wrapper"
               className="aside-menu-left aside-menu-wrapper flex-column-fluid"
            >
               {layoutProps.disableAsideSelfDisplay && (
                  <>
                     {/* begin::Header Logo */}
                     <div className="header-logo">
                        <Link to="">
                           <img alt="logo" src={layoutProps.headerLogo} />
                        </Link>
                     </div>
                     {/* end::Header Logo */}
                  </>
               )}
               <AdminAsideMenu disableScroll={layoutProps.disableScroll} />
            </div>
            {/* end::Aside Menu */}
            {/* <div className="menu-minimizer">
               <button onClick={() => minimizeMenu()}>
                  {!isMinimized ? (
                  <LeftOutlined style={{fontSize:'20px'}} />

                  ): <RightOutlined style={{fontSize:'20px'}} />}
               </button>
            </div> */}
         </div>
         {/* end::Aside */}
      </>
   );
}
