import React from "react";
import style from "./RangeQuestion.module.scss";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { Col, Slider } from "antd";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { useSurveyInputStore } from "../../../../surveyStore";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";

function generateMarksObject(start, end) {
   const marks = {};

   for (let i = start; i <= end; i++) {
      marks[i] = i.toString();
   }

   return marks;
}

export const RangeQuestion = React.memo(({ props, surveyId, userId, label, hint }) => {
   const { id, questionType, isRequired, range } = props;

   const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
   const setInputs = useSurveyInputStore((state) => state.setInputs);

   const onChange = (e) => {
      if (!e) {
         setInputs({
            target: {
               name: id,
               value: ""
            }
         });
      } else {
         setInputs({
            target: {
               name: id,
               value: {
                  surveyId: surveyId,
                  userId: userId,
                  questionId: id,
                  questionType: questionType,
                  name: props.name,
                  skipLogicAnswer: e,
                  answerDetail: {
                     number: e
                  }
               }
            }
         });
      }
   };

   return (
      <Col lg={24} md={24} sm={24} xs={24}>
         <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />
         <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />

         <div className={style.wrapper}>
            <div className={`${style.absolute} ${style.left}`}>
               <p className={style.text}> {range?.firstLabel}</p>
               <FrownOutlined className="icon" />
            </div>

            <Slider
               min={range?.from || 1}
               max={range?.to || 5}
               marks={generateMarksObject(range?.from || 1, range?.to || 5)}
               onChange={onChange}
               value={inputState ? inputState?.answerDetail?.number : null}
            />
            <div className={`${style.absolute} ${style.right}`}>
               <p className={style.text}> {range?.secondLabel} </p>
               <SmileOutlined className="icon" />
            </div>
         </div>
      </Col>
   );
});
