import React from "react";
import Icon from "@ant-design/icons";

export const SaveDraftIcon = (props) => {
   return (
      <Icon
         component={() => (
            <svg
               width="15"
               height="16"
               viewBox="0 0 15 16"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M1.21387 12.0011H13.7853M4.64244 3.42965H10.3567M4.64244 6.2868H10.3567M4.64244 9.14394H8.07101M12.6424 0.57251H2.35672C1.72555 0.57251 1.21387 1.08418 1.21387 1.71537V14.2868C1.21387 14.918 1.72555 15.4297 2.35672 15.4297H12.6424C13.2736 15.4297 13.7853 14.918 13.7853 14.2868V1.71537C13.7853 1.08418 13.2736 0.57251 12.6424 0.57251Z"
                  stroke="#4158CF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               />
            </svg>
         )}
         {...props}
      />
   );
};
