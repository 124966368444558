import React, { useEffect, useState } from "react";
import { Button, Flex, Typography, Upload } from "antd";
import Resizer from "react-image-file-resizer";
import Icon, { CloseCircleOutlined, ExportOutlined, SyncOutlined } from "@ant-design/icons";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import { httpService } from "../../../../../../../base/services/httpService.service";
import { formatFileSize } from "../../../../../../../utils/formatFileSize";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";

import "./Photograph.scss";

const { Dragger } = Upload;

const getFileName = (text) => {
   if (!text) {
      return;
   }
   var parts = text.split("-");
   return parts[parts.length - 1];
};

export const Photograph = React.memo(
   ({ props, hasErrors, surveyId, userId, submissionId, label, hint }) => {
      const { id, questionType, isRequired } = props;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const [loadingPreview, setLoadingPreview] = useState(false);
      const [fileList, setFileList] = useState(
         inputState?.answerDetail && [
            {
               name:
                  inputState?.answerDetail?.answerFileBase64?.name ??
                  inputState?.answerDetail?.answerFiles?.name,
               size: inputState?.answerDetail?.answerFiles?.size,
               url: ""
            }
         ]
      );

      const hasSubmissionId = Boolean(submissionId);

      const showErrors =
         hasErrors &&
         isRequired &&
         (!inputState?.answerDetail?.answerFiles ||
            inputState?.answerDetail?.answerFiles?.length === 0);

      const onChange = ({ file, fileList }) => {
         const { status, originFileObj } = file;
         if (status === "removed") {
            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     questionType: questionType,
                     name: props.name,
                     skipLogicAnswer: null,
                     answerDetail: {
                        answerFiles: null
                     }
                  }
               }
            });
            setFileList(fileList);
         } else {
            const fileType = originFileObj.type;
            const isImage =
               fileType === "image/png" || fileType === "image/jpeg" || fileType === "image/jpg";

            if (isImage) {
               Resizer.imageFileResizer(
                  originFileObj,
                  1000,
                  1000,
                  "JPEG",
                  200,
                  0,
                  (uri) => {
                     fetch(uri)
                        .then((res) => res.blob())
                        .then((blob) => {
                           const resizedFile = new File([blob], originFileObj.name, {
                              type: originFileObj.type
                           });
                           setInputs({
                              target: {
                                 name: id,
                                 value: {
                                    surveyId: surveyId,
                                    userId: userId,
                                    questionId: id,
                                    questionType: questionType,
                                    name: props.name,
                                    skipLogicAnswer: resizedFile,
                                    answerDetail: {
                                       answerFiles: resizedFile
                                    }
                                 }
                              }
                           });
                           const updatedFileList = fileList.map((f) =>
                              f.uid === file.uid
                                 ? { ...f, size: resizedFile.size, status: "done" }
                                 : f
                           );
                           setFileList(updatedFileList);
                        });
                  },
                  "base64"
               );
            } else {
               setInputs({
                  target: {
                     name: id,
                     value: {
                        surveyId: surveyId,
                        userId: userId,
                        questionId: id,
                        questionType: questionType,
                        name: props.name,
                        skipLogicAnswer: originFileObj,
                        answerDetail: {
                           answerFiles: originFileObj
                        }
                     }
                  }
               });
               setFileList(fileList);
            }
         }
      };

      const previewFiles = (filePath, id) => {
         let correctedFilePath = filePath.replace(/\\202/g, "\\x82");
         const payload = [
            {
               fileKey: correctedFilePath,
               entityId: id
            }
         ];

         setLoadingPreview(true);
         httpService
            .post(
               `/FileData/getFileUrlByFileKeyAndEntityId?platformId=006d1c1e-81b6-4d87-b4b0-f1850543f2cc`,
               payload,
               (response) => {
                  setLoadingPreview(false);
                  if (response && response.data && response.data && response.data[0].fileUrl) {
                     window.open(response.data[0].fileUrl, "_blank");
                  } else {
                     console.log("Something went wrong");
                  }
               }
            )
            .catch((error) => {
               setLoadingPreview(false);
               console.log("error:", error);
            });
      };

      useEffect(() => {
         if (!inputState) {
            setFileList([]);
         } else if (inputState?.answerDetail?.answerFileBase64) {
            setFileList(
               inputState?.answerDetail && [
                  {
                     name:
                        inputState?.answerDetail?.answerFileBase64?.name ??
                        inputState?.answerDetail?.answerFiles?.name,
                     size: inputState?.answerDetail?.answerFileBase64
                        ? Buffer.from(inputState?.answerDetail?.answerFileBase64?.data, "base64")
                             .length
                        : inputState?.answerDetail?.answerFiles?.size,
                     url: ""
                  }
               ]
            );
         }
      }, [id, inputState, setFileList]);

      return (
         <div className="file-submission-type-wrapper">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />
            <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />

            <div className="file-submission-type-wrapper--input">
               <Dragger
                  {...props}
                  // TODO: set logic for max file size accepted
                  // beforeUpload={(file) => {
                  //    const isLt5M = file.size / 1024 / 1024 <= 4;
                  //    if (!isLt5M) {
                  //       alert("File must smaller than 4MB!");
                  //       return Upload.LIST_IGNORE;
                  //    }
                  // }}
                  onChange={onChange}
                  onPreview={({ url }) => {
                     if (!hasSubmissionId) {
                        return;
                     }
                     previewFiles(url, props.id);
                  }}
                  listType="picture"
                  itemRender={(el, file, fileList, fn) =>
                     fileItemRenderer({
                        el,
                        file,
                        fileList,
                        fn,
                        hasSubmissionId,
                        loadingPreview
                     })
                  }
                  multiple={false}
                  maxCount={1}
                  disabled={hasSubmissionId}
                  prefixCls="file-submission-type-wrapper--input"
                  className={`${showErrors ? "drag-error" : ""}`}
                  fileList={hasSubmissionId ? undefined : fileList}
                  defaultFileList={
                     hasSubmissionId &&
                     props.answers && [
                        {
                           name: getFileName(
                              props.answers[0]?.answerDetail?.filePaths[0]?.filePath
                           ),
                           url: props.answers[0]?.answerDetail?.filePaths[0]?.filePath
                        }
                     ]
                  }
               >
                  <div className="ant-upload-drag-icon">
                     <Icon
                        component={() => (
                           <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M4.32084 17.2432H1.88494C1.56192 17.2432 1.25213 17.1088 1.02372 16.8697C0.795311 16.6305 0.666992 16.3062 0.666992 15.968V1.94174C0.666992 1.60356 0.795311 1.27923 1.02372 1.0401C1.25213 0.800968 1.56192 0.666626 1.88494 0.666626H15.2824C15.6054 0.666626 15.9152 0.800968 16.1436 1.0401C16.372 1.27923 16.5003 1.60356 16.5003 1.94174V15.968C16.5003 16.3062 16.372 16.6305 16.1436 16.8697C15.9152 17.1088 15.6054 17.2432 15.2824 17.2432H12.8465M0.666992 4.49228H16.5003M8.58311 17.2421V8.9538M8.58311 8.9538L5.53911 12.1416M8.58311 8.9538L11.6288 12.1416"
                                 stroke="#4158CF"
                                 strokeWidth="1.25"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                        )}
                     />
                  </div>
                  <div className="ant-upload-text">
                     Drag and drop files here, or <span>click to select files</span>
                  </div>
                  <div className="ant-upload-hint">
                     You can upload a file by dragging and dropping over this area. Otherwise you
                     can click and select a file from your system.
                  </div>
                  <div className="ant-upload-hint-lite">
                     CAUTION: files over 5MB cannot be saved as draft
                  </div>
               </Dragger>
            </div>

            <span className="file-submission-type-wrapper--error">
               {showErrors ? "This field is required" : " "}
            </span>
         </div>
      );
   }
);

const fileItemRenderer = ({ el, file, fileList, fn, hasSubmissionId, loadingPreview }) => {
   const readableFileSize = file.size ? formatFileSize(file.size, 2) : "N/A Bytes";
   const readableLoadPercentage = file.percent?.toFixed(2) ?? 0;

   return (
      <div className="custom-file-item-renderer">
         <Flex gap={10}>
            <Flex className="custom-file-item-renderer_icon" justify="center">
               <Icon
                  component={() => (
                     <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M4.32084 17.2432H1.88494C1.56192 17.2432 1.25213 17.1088 1.02372 16.8697C0.795311 16.6305 0.666992 16.3062 0.666992 15.968V1.94174C0.666992 1.60356 0.795311 1.27923 1.02372 1.0401C1.25213 0.800968 1.56192 0.666626 1.88494 0.666626H15.2824C15.6054 0.666626 15.9152 0.800968 16.1436 1.0401C16.372 1.27923 16.5003 1.60356 16.5003 1.94174V15.968C16.5003 16.3062 16.372 16.6305 16.1436 16.8697C15.9152 17.1088 15.6054 17.2432 15.2824 17.2432H12.8465M0.666992 4.49228H16.5003M8.58311 17.2421V8.9538M8.58311 8.9538L5.53911 12.1416M8.58311 8.9538L11.6288 12.1416"
                           stroke="#4158CF"
                           strokeWidth="1.25"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        />
                     </svg>
                  )}
               />
            </Flex>
            <Flex justify="space-between" className="custom-file-item-renderer_content">
               <div>
                  <Typography.Text
                     onClick={fn.preview}
                     className={`custom-file-item-renderer_content--filename ${
                        hasSubmissionId ? "filename_link" : ""
                     }`}
                  >
                     <span>{file.name}</span>
                     {hasSubmissionId && (
                        <span style={{ marginLeft: "4px" }}>
                           {loadingPreview ? <SyncOutlined spin /> : <ExportOutlined />}
                        </span>
                     )}
                  </Typography.Text>
                  <p className="custom-file-item-renderer_content--filesize">{readableFileSize}</p>
               </div>
               <Button
                  shape="circle"
                  type="text"
                  onClick={fn.remove}
                  disabled={hasSubmissionId}
                  title="Remove file"
                  icon={<CloseCircleOutlined style={{ fontSize: "22px", color: "#707073" }} />}
               />
            </Flex>
         </Flex>
         {file.status === "uploading" && (
            <div className="custom-file-item-renderer_progress">
               <Flex justify="space-between" align="center" gap={16}>
                  <span className="custom-file-item-renderer_progress-wrapper" style={{}}>
                     <span
                        className="custom-file-item-renderer_progress-wrapper_inner"
                        style={{ width: `${file.percent}%` }}
                     />
                  </span>
                  <span className="custom-file-item-renderer_progress-percentage">
                     {readableLoadPercentage}%
                  </span>
               </Flex>
            </div>
         )}
      </div>
   );
};
