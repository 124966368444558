import React, { Fragment, useEffect } from "react";
import { Radio, Select } from "antd";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";
import useEvaluateChoiceFilter from "../../question-preview/hooks/useEvaluateChoiceFilter";
import useEvaluateAppearance from "../../question-preview/hooks/useEvaluateAppearance";
import "./SelectOne.scss";

export const SelectOne = React.memo(
   ({ props, hasErrors, surveyId, userId, language, submissionId, label, hint }) => {
      const { id, selectQuestionChoices, questionType, isRequired, answers } = props;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const answer = answers?.find((answer) => answer?.submissionId === submissionId);
      const choices =
         answer?.answerDetail?.choices?.length > 0 && answer?.answerDetail?.choices[0].choiceId;
      const hasSubmissionId = Boolean(submissionId);
      const submissionAnswer = submissionId && choices ? choices : null;
      const passConstraint = useEvaluateConstraint(props.constraint, inputState?.skipLogicAnswer);
      const showErrors =
         hasErrors &&
         isRequired &&
         (!inputState || inputState?.answerDetail?.choices?.length === 0);
      const defaultValue = hasSubmissionId
         ? submissionAnswer
         : inputState?.answerDetail?.choices[0];
      const derivedOptions = useEvaluateChoiceFilter({
         choiceFilter: hasSubmissionId ? null : props.choiceFilter,
         name: props.name,
         selectQuestionChoices
      });

      const appearance = useEvaluateAppearance({
         appearance: props.appearance
      });

      const handleCheckBoxChange = (selectedValue) => {
         if (hasSubmissionId) {
            return;
         }

         if (selectedValue === defaultValue) {
            setInputs({ target: { name: id, value: "" } });
         } else {
         const selectedChoice = selectQuestionChoices.find((choice) =>
            [choice.id, choice.name].includes(selectedValue)
         );

         const numberValue = Number(selectedChoice.name);
         const parsedValue = isNaN(numberValue) ? selectedChoice.name : numberValue;

         setInputs({
            target: {
               name: id,
               value: {
                  surveyId: surveyId,
                  userId: userId,
                  questionId: id,
                  name: props.name,
                  skipLogicAnswer: parsedValue,
                  questionType: questionType,
                  answerDetail: {
                     choices: [props.default ? selectedChoice.id : selectedValue]
                  }
               }
            }
         });
         }
      };

      useEffect(() => {
         if (props.default) {
            handleCheckBoxChange(props.default);
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.default]);

      useEffect(() => {
         if (props.choiceFilter) {
            setInputs({ target: { name: id, value: "" } });
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [derivedOptions, id, props.choiceFilter]);

      return (
         <div className="select_one-submission-type">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />
            <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />

            <div
               className={`select_one-submission-type--options${
                  showErrors || (passConstraint !== null && !passConstraint) ? "_error" : ""
               }`}
            >
               {appearance.includes("minimal") ? (
                  <Select
                     listHeight={300}
                     allowClear
                     showSearch={false}
                     size="large"
                     placeholder="none selected"
                     value={defaultValue}
                     onChange={(value) => {
                        if (!value) {
                           handleCheckBoxChange(defaultValue);
                        }
                     }}
                     options={derivedOptions.map((option) => ({
                        value: option.choiceLabels[0]?.choiceId,
                        name: option.name,
                        label: language
                           ? option.choiceLabels.find((choice) => choice.languageId === language)
                                ?.text || option.choiceLabels[0]?.text
                           : option.choiceLabels[0]?.text
                     }))}
                     dropdownRender={(menu) => {
                        return derivedOptions.length ? (
                           <div
                              style={{
                                 maxHeight: "300px",
                                 overflowY: "auto"
                              }}
                           >
                              {derivedOptions.map((option) => {
                                 return (
                              <Radio
                                 key={option.choiceLabels[0]?.text}
                                       prefixCls="select_one-submission-type--options---radio"
                                 checked={option?.choiceLabels[0]?.choiceId === defaultValue}
                                 value={option?.choiceLabels[0]?.choiceId}
                                       className={`${
                                          hasSubmissionId ? "submissionStyle" : ""
                                       } mb-2`}
                                       onClick={() => {
                                          handleCheckBoxChange(option?.choiceLabels[0]?.choiceId);
                                       }}
                              >
                                 {language
                                    ? option.choiceLabels.find(
                                         (choice) => choice.languageId === language
                                      )?.text || option.choiceLabels[0]?.text
                                    : option.choiceLabels[0]?.text}
                              </Radio>
                                 );
                              })}
                        </div>
                        ) : (
                           menu
                        );
                     }}
                  />
               ) : (
                  <Fragment>
                     {derivedOptions.map((option) => (
                        <Radio
                           key={option.choiceLabels[0]?.text}
                              prefixCls="select_one-submission-type--options---radio"
                           checked={option?.choiceLabels[0]?.choiceId === defaultValue}
                           value={option?.choiceLabels[0]?.choiceId}
                           className={`${hasSubmissionId ? "submissionStyle" : ""}`}
                              onClick={() => {
                                 handleCheckBoxChange(option?.choiceLabels[0]?.choiceId);
                              }}
                        >
                           {language
                                 ? option.choiceLabels.find(
                                      (choice) => choice.languageId === language
                                   )?.text || option.choiceLabels[0]?.text
                              : option.choiceLabels[0]?.text}
                        </Radio>
                     ))}
                  </Fragment>
               )}
            </div>

            <span className="select_one-submission-type--error">
               {showErrors ? "You must select one" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
