import dayjs from "dayjs";

export const createQueryParams = (params) => {
   const queryParams = new URLSearchParams();

   if (params.searchInput) {
      queryParams.append("Search", params.searchInput);
   }

   if (params.tab || params.tab === 0) {
      queryParams.append("Status", params.tab);
   }

   if (params.roles && params.roles.length > 0) {
      params.roles.forEach((role) => {
         queryParams.append("RoleId", role);
      });
   }

   if (params.languages && params.languages.length > 0) {
      params.languages.forEach((language) => {
         queryParams.append(`LanguageId`, language);
      });
   }

   if (params.genderValues && params.genderValues.length > 0) {
      params.genderValues.forEach((gender) => {
         queryParams.append("Gender", gender);
      });
   }

   if (params.locations && params.locations.length > 0) {
      params.locations.forEach((location) => {
         queryParams.append("LocationId", location);
      });
   }

   if (params.workspaces && params.workspaces.length > 0) {
      params.workspaces.forEach((workspace) => {
         queryParams.append("WorkspaceId", workspace);
      });
   }

   if (params.projects && params.projects.length > 0) {
      params.projects.forEach((project) => {
         queryParams.append("ProjectId", project);
      });
   }

   if (params.month) {
      queryParams.append("month", params.month);
   }

   if (params.year) {
      queryParams.append("year", params.year);
   }

   if (params.workspace) {
      queryParams.append("workspaceId", params.workspace);
   }

   if (params.locationGroupId) {
      queryParams.append("LocationGroupId", params.locationGroupId);
   }

   if (params.dataCollectors) {
      queryParams.append("EnumeratorName", params.dataCollectors);
   }

   if (params.surveyId) {
      queryParams.append("SurveyId", params.surveyId);
   }

   if (params.surveyName) {
      queryParams.append("SurveyName", params.surveyName);
   }

   if (params.collectionType || params.collectionType === 0) {
      queryParams.append("CollectionType", params.collectionType);
   }

   if (params.taskStatus || params.taskStatus === 0 || params.tab || params.tab === 0) {
      queryParams.append("Status", params.taskStatus);
   }

   if (params.gender || params.gender === 0) {
      queryParams.append("Gender", params.gender);
   }

   if (params.tasksDeadline) {
      const formattedDeadline = dayjs(params.tasksDeadline).format("YYYY-MM-DDTHH:mm:ss.SSS");
      queryParams.append("Deadline", formattedDeadline);
   }

   if (params.sendingDate) {
      const formattedDeadline = dayjs(params.sendingDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
      queryParams.append("SendingDate", formattedDeadline);
   }

   if (params.receiver) {
      queryParams.append("Receiver", params.receiver);
   }
   return queryParams.toString();
};
