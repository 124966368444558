/**
 * Entry application component used to compose providers and render Routes.
 * */
import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { BrowserRouter } from "react-router-dom";
import { withErrorBoundary } from "./components/ErrorBoundary/withErrorBoundary";
import { Routess } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { ToastContainer } from "react-toastify";
import useOnlineStore from "./utils/useOnlineStore";

function App({ store, persistor }) {
   const [loading, setLoading] = useState(true);
   const initOnlineStatusListener = useOnlineStore((state) => state.initOnlineStatusListener);

   // Initialize online status listener on component mount
   useEffect(() => {
      const cleanup = initOnlineStatusListener();
      return cleanup;
   }, [initOnlineStatusListener]);

   useEffect(() => {
      const delay = setTimeout(() => {
         setLoading(false);
      }, 1000);

      return () => clearTimeout(delay);
   }, []);

   return (
      <React.Suspense fallback={<LayoutSplashScreen />}>
         <BrowserRouter>
            <MaterialThemeProvider>
               <I18nProvider>
                  {loading ? (
                     <Spin
                        size="large"
                        style={{
                           position: "fixed",
                           top: "50%",
                           left: "50%",
                           transform: "translate(-50%, -50%)"
                        }}
                     />
                  ) : (
                     <>
                        <Routess />
                        <ToastContainer
                           position="top-right"
                           autoClose={5000}
                           hideProgressBar={false}
                           newestOnTop={false}
                           closeOnClick
                           rtl={false}
                           pauseOnFocusLoss
                           draggable
                           pauseOnHover
                        />
                        <ToastContainer />
                     </>
                  )}
               </I18nProvider>
            </MaterialThemeProvider>
         </BrowserRouter>
      </React.Suspense>
   );
}

export default withErrorBoundary(App);
