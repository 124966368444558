import { Button, Form, Input, notification } from "antd";
import React, { useState } from "react";
import { httpService } from "../../../base/services/httpService.service";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ForgotPasswordModal from "../../../ui/ForgotPasswordModal";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
   const [api, contextHolder] = notification.useNotification();
   const navigate = useNavigate();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isPasswordValid, setIsPasswordValid] = useState(false);
   const [isCodeValid, setIsCodeValid] = useState(false);
   const [userId, setUserId] = useState("");
   const [loading, setLoading] = useState(false);
   const [passwordValidation, setPasswordValidation] = useState({
      minSix: false,
      oneLowerCase: false,
      oneUpperCase: false,
      oneNumber: false,
      oneSpecialChar: false
   });

   const openNotificationWithIcon = (type, apiCall) => {
      const messages = {
         apiCall1: {
            success: {
               message: "Email sent!",
               description: "Enter the code below to reset your password"
            },
            error: {
               message: "A valid email is required",
               description: "The Email field is not a valid e-mail address."
            }
         },
         apiCall2: {
            success: {
               message: "Code is valid",
               description: null
            },
            error: {
               message: "The value must contain only numbers between 0 and 9.",
               description: null
            }
         },
         apiCall3: {
            success: {
               message: null,
               description: null
            },
            error: {
               message: "Invalid password. Please try again ",
               description: null
            }
         }
      };

      const apiMessages = messages[apiCall][type];

      api[type]({
         message: apiMessages.message,
         description: apiMessages.description
      });
   };

   const onSendEmail = (values) => {
      setLoading(true);
      httpService
         .post(
            "/Account/forgotPassword",
            {
               email: values.email
            },
            (response) => {
               if (response?.isSuccess) {
                  openNotificationWithIcon("success", "apiCall1");
                  setUserId(response?.data?.userId);
                  setIsModalOpen(true);
               }
            },
            (error) => {
               openNotificationWithIcon("error", "apiCall1");
               setIsModalOpen(false);
            }
         )
         .finally(() => {
            setLoading(false);
         });
   };

   const onHandleCode = (values) => {
      httpService.post(
         "/Account/checkResetPasswordCode",
         {
            userId,
            code: values.verificationCode
         },
         (response) => {
            if (response?.isSuccess) {
               openNotificationWithIcon("success", "apiCall2");
               setIsCodeValid(true);
               setIsModalOpen(false);
            }
         },
         (error) => {
            openNotificationWithIcon("error", "apiCall2");
            setIsModalOpen(true);
         }
      );
   };

   const onResetPassword = (values) => {
      httpService.post(
         "/Account/resetPassword",
         {
            userId,
            newPassword: values.newPassword,
            confirmPassword: values.confirmNewPassword
         },
         (response) => {
            if (response?.isSuccess) {
               setIsPasswordValid(true);
            }
         },
         (error) => {
            openNotificationWithIcon("error", "apiCall3");
            setIsPasswordValid(false);
         }
      );
   };

   const validatePassword = (value) => {
      const newValidation = {
         minSix: value.length >= 8,
         oneLowerCase: /[a-z]/.test(value),
         oneUpperCase: /[A-Z]/.test(value),
         oneNumber: /\d/.test(value),
         oneSpecialChar: /[!@#$%^&*()_+]/.test(value)
      };
      setPasswordValidation(newValidation);
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      setIsPasswordValid(false);
   };

   return (
      <>
         {contextHolder}
         <div className="forgotPasswordLayout">
            <div className="logo">
               <img
                  alt="Logo"
                  className="max-h-25px logo-height"
                  src={toAbsoluteUrl("/media/logos/kapSurvey-logo.png")}
               />
            </div>
            <div className="forgotPasswordPage">
               <div className="child image">
                  <img src={toAbsoluteUrl("/media/bg/forget-password.png")} alt="" />
               </div>
               <div className="child infos">
                  {isCodeValid ? (
                     <div className="innerchild">
                        <p className="title">Reset your password</p>
                        <p className="description">Your new password must:</p>
                        <ul>
                           <li className={passwordValidation.minSix ? "" : "error"}>
                              Be at least 8 characters long
                           </li>
                           <li className={passwordValidation.oneLowerCase ? "" : "error"}>
                              Have one lowercase character
                           </li>
                           <li className={passwordValidation.oneUpperCase ? "" : "error"}>
                              Have one uppercase character
                           </li>
                           <li className={passwordValidation.oneNumber ? "" : "error"}>
                              Have one number
                           </li>
                           <li className={passwordValidation.oneSpecialChar ? "" : "error"}>
                              Have one special character
                           </li>
                        </ul>

                        <Form
                           className="forgetPasswordForm"
                           name="nest-messages"
                           onFinish={onResetPassword}
                           style={{
                              maxWidth: 900
                           }}
                        >
                           <p className="email">New password</p>

                           <Form.Item
                              name="newPassword"
                              className="password"
                              onChange={(e) => validatePassword(e.target.value)}
                           >
                              <Input.Password placeholder="Type your password" />
                           </Form.Item>

                           <p className="email">Confirm new password</p>
                           <Form.Item name="confirmNewPassword" className="password">
                              <Input.Password placeholder="Confirm your password" />
                           </Form.Item>

                           <div className="mobileButton">
                              <Button
                                 htmlType="submit"
                                 style={{
                                    width: "150px",
                                    height: "40px",
                                    // margin: "0 auto",
                                    background: "#5770CB",
                                    color: "#FFF"
                                 }}
                              >
                                 Reset Password
                              </Button>

                              <Button
                                 style={{
                                    width: "150px",
                                    height: "40px",
                                    color: "#5770CB",
                                    background: "none",
                                    border: "1px solid #D9E3FF"
                                 }}
                                 onClick={() => navigate("/auth/login")}
                              >
                                 Cancel
                              </Button>
                           </div>
                        </Form>
                     </div>
                  ) : (
                     <div className="innerchild">
                        <p className="title">Forgot your password?</p>
                        <p className="description">
                           We're here to help! Please enter the email address associated to your
                           account. We will send you a verification code to reset your password.
                        </p>

                        <Form
                           className="forgotPasswordForm"
                           name="nest-messages"
                           onFinish={onSendEmail}
                           style={{
                              maxWidth: 900
                           }}
                        >
                           <p className="email">Email</p>

                           <Form.Item name="email" className="emailInput">
                              <Input autoComplete="off" placeholder="Type your email" />
                           </Form.Item>

                           <div className="mobileButton">
                              <Button
                                 htmlType="submit"
                                 loading={loading}
                                 style={{
                                    width: "150px",
                                    height: "40px",
                                    // margin: "0 auto",
                                    background: "#5770CB",
                                    color: "#FFF"
                                 }}
                              >
                                 Send email
                              </Button>
                           </div>
                        </Form>
                     </div>
                  )}
               </div>
            </div>
            <div className="waveContainer">
               <svg
                  width="100%"
                  height="100%"
                  id="svg"
                  viewBox="0 0 1420 350"
                  xmlns="http://www.w3.org/2000/svg"
                  class="transition duration-300 ease-in-out delay-150"
               >
                  <path
                     d="M 0,600 L 0,225 C 70.52927098674523,232.25220913107512 141.05854197349046,239.5044182621502 192,238 C 242.94145802650954,236.4955817378498 274.29510309278345,226.23453608247425 329,238 C 383.70489690721655,249.76546391752575 461.7610456553756,283.5574374079529 527,306 C 592.2389543446244,328.4425625920471 644.6607142857143,339.5357142857143 712,313 C 779.3392857142857,286.4642857142857 861.5960972017673,222.29970544918996 929,180 C 996.4039027982327,137.70029455081004 1048.9548969072164,117.2654639175258 1099,159 C 1149.0451030927836,200.7345360824742 1196.5843151693668,304.63843888070693 1253,326 C 1309.4156848306332,347.36156111929307 1374.7078424153165,286.18078055964656 1440,225 L 1440,600 L 0,600 Z"
                     width="100%"
                     fill="#EEF2FF"
                     fillOpacity="1"
                     class="transition-all duration-300 ease-in-out delay-150 path-0 wave-container"
                  ></path>
               </svg>
            </div>
            <ForgotPasswordModal
               isModalOpen={isModalOpen}
               isPasswordValid={isPasswordValid}
               handleOk={onHandleCode}
               handleCancel={handleCancel}
            ></ForgotPasswordModal>
         </div>
      </>
   );
};

export default ForgotPassword;
