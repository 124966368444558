import React from "react";
import Icon from "@ant-design/icons";

export const SeeDraftsIcon = (props) => {
   return (
      <Icon
         component={() => (
            <svg
               width="17"
               height="16"
               viewBox="0 0 17 16"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M9.64272 0.57251H2.21415C1.58296 0.57251 1.07129 1.08418 1.07129 1.71537V14.2868C1.07129 14.918 1.58296 15.4297 2.21415 15.4297H12.4999C13.1311 15.4297 13.6427 14.918 13.6427 14.2868V9.14394M1.07129 12.0011H13.6427M4.49986 3.42965H6.78557M4.49986 6.2868H5.64272M10.7789 8.57914L7.35031 9.19628L7.92173 5.722L12.756 0.910565C12.8623 0.803447 12.9887 0.718425 13.128 0.660403C13.2672 0.602382 13.4165 0.57251 13.5674 0.57251C13.7184 0.57251 13.8677 0.602382 14.0069 0.660403C14.1463 0.718425 14.2727 0.803447 14.3788 0.910565L15.5903 2.122C15.6975 2.22824 15.7825 2.35464 15.8404 2.4939C15.8985 2.63317 15.9283 2.78256 15.9283 2.93342C15.9283 3.08429 15.8985 3.23368 15.8404 3.37293C15.7825 3.5122 15.6975 3.63861 15.5903 3.74485L10.7789 8.57914Z"
                  stroke="#4158CF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               />
            </svg>
         )}
         {...props}
      />
   );
};
