import { useState } from "react";
import { editDataStore } from "../../../surveyStore";
import { useEffect } from "react";

// TODO: refactor. wft is this?
const useGetOrderedSurveys = () => {
   // const { surveyData } = useSurveyStore();
   const surveyData = editDataStore((state) => state.surveyData);
   const [surveyOrdered, setSurveyOrdered] = useState([]);

   useEffect(() => {
      const getOrderArray = (items) => {
         let orderArr = [];
         items.forEach((item) => {
            if (item) {
               orderArr.push(item);
               if (item.innerItems?.length > 0) {
                  const subItemsOrder = getOrderArray(item.innerItems);
                  orderArr = orderArr.concat(subItemsOrder);
               }
            }
         });
         return orderArr;
      };

      if (surveyData.length > 0) {
         const orderArray = getOrderArray(surveyData);
         setSurveyOrdered(orderArray);
      }
   }, [surveyData]);

   return { surveyOrdered };
};

export default useGetOrderedSurveys;
