import React, { useEffect, useState } from "react";
import Organization from "./Organization";
import AuthService from "../../../../base/services/authentication.service";
import { httpService } from "../../../../base/services/httpService.service";
import { Spin } from "antd";
import { withErrorBoundary } from "../../../../components/ErrorBoundary/withErrorBoundary";

const Organizations = () => {
   const userId = AuthService.getUser()?.id;

   const [organizations, setOrganizations] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      setIsLoading(true);
      httpService
         .get(
            `/Account/getUserOrganizations?userId=${userId}`,
            (response) => {
               setOrganizations(response.data);
            },
            (error) => {
               console.log("error:", error);
            }
         )
         .finally(() => {
            setIsLoading(false);
         });
   }, [userId]);

   return (
      <>
         {isLoading ? (
            <Spin
               style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px"
               }}
            ></Spin>
         ) : (
            <div className="allOrganizations">
               {organizations?.map((item) => (
                  <Organization
                     organizationId={item.organizationId}
                     organizationName={item.organizationName}
                     organizationMemberId={item.organizationMemberId}
                     key={item.organizationId}
                     isDeactivatedUser={item.isDeletedUser}
                     isDeactivatedOrganization={item.isDeletedOrg}
                  />
               ))}
            </div>
         )}
      </>
   );
};

export default withErrorBoundary(Organizations);
