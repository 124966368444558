import { useState, useEffect } from "react";
import { httpService } from "../services/httpService.service";

const cities = [
   {
      name: "Prizren",
      iso2: "XPR"
   },
   {
      name: "Peja",
      iso2: "XPE"
   },
   {
      name: "Ferizaj",
      iso2: "XUF"
   },
   {
      name: "Gjakova",
      iso2: "XDG"
   },
   {
      name: "Gjilan",
      iso2: "XGJ"
   },
   {
      name: "Mitrovica",
      iso2: "XKM"
   },
   {
      name: "Pristina",
      iso2: "XPI"
   }
];

export const useCountriesAndData = (form) => {
   const [countries, setCountries] = useState([]);
   const [selectedCountry, setSelectedCountry] = useState(null);
   const [states, setStates] = useState([]);

   useEffect(() => {
      let isMounted = true;

      const fetchCountries = async () => {
         const url = `/Languages/getAllCountries`;
         try {
            const res = await httpService.get(url);
            if (isMounted) {
               setCountries(
                  res.data
                     .map((item) => ({
                        id: item.id,
                        name: item.name,
                        role: item.code
                     }))
                     .sort((a, b) => a.name.localeCompare(b.name))
               );
            }
         } catch (error) {
            console.log(error);
         }
      };

      fetchCountries();

      return () => {
         isMounted = false;
      };
   }, []);

   const handleCountryChange = async (selectedOption, formFields) => {
      setSelectedCountry(selectedOption.role);
      form.setFieldsValue(formFields);

      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", process.env.REACT_APP_COUNTRY_STATE_CITY_API_KEY);

      var requestOptions = {
         method: "GET",
         headers: headers,
         redirect: "follow"
      };

      try {
         const response = await fetch(
            `https://api.countrystatecity.in/v1/countries/${selectedOption.role}/states`,
            requestOptions
         );
         const parseData = await response.json();
         let filteredStates = parseData;

         if (selectedOption.role === "AL") {
            filteredStates = parseData.filter((item) => item.name.includes("District"));
         }

         if (selectedOption.role === "XK") {
            filteredStates = parseData.map((item) => {
               const city = cities.find((city) => city.iso2 === item.iso2);
               return {
                  ...item,
                  name: city ? city.name : item.name
               };
            });
         }

         setStates(
            filteredStates
               .map((item) => ({
                  id: item.id,
                  name: item.name,
                  role: item.iso2
               }))
               .sort((a, b) => a.name.localeCompare(b.name))
         );
      } catch (error) {
         console.log("error", error);
      }
   };

   return { countries, selectedCountry, states, handleCountryChange };
};
