import React, { useState } from "react";
import { Flex, Result, Table } from "antd";
import DeletedActionButton from "../DeletedActionButton/DeletedActionButton";
import Icon, { HistoryOutlined } from "@ant-design/icons";
import { DeleteComponent } from "../../../pages/admin/workspace/components/DeleteComponent";
import { FetchStatusEnum } from "../../../utils/enums";

import "./DataTable.scss";

const DataTable = ({
   rowKey,
   dataSource,
   columns,
   status,
   refetch,
   entityType,
   pagination,
   handleRestore,
   handleDelete
}) => {
   const loading = status === FetchStatusEnum.Loading;
   const isError = status === FetchStatusEnum.Error;

   const columnsWithActions = [
      ...columns,
      ...(!columns.find((column) => column.key === "actions")
         ? [
              {
                 key: "actions",
                 fixed: "right",
                 width: 100,
                 render: (_, entity) => (
                    <Actions
                       key={entity.id ?? entity[rowKey]}
                       refetch={refetch}
                       entity={entity}
                       entityType={entityType}
                       handleRestore={handleRestore}
                       handleDelete={handleDelete}
                    />
                 )
              }
           ]
         : [])
   ];
   return isError ? (
      <Result
         status="error"
         title="Error"
         subTitle="Something went wrong! Please try again later."
      />
   ) : (
      <Table
         prefixCls="deleted-data-table"
         rowKey={rowKey ?? "id"}
         style={{ overflowX: "auto" }}
         scroll={dataSource?.length ? { x: "max-content" } : {}}
         dataSource={dataSource}
         columns={columnsWithActions}
         loading={loading}
         pagination={pagination}
      />
   );
};

export default DataTable;

function Actions({ entity, handleDelete, handleRestore }) {
   const [deleteModal, setDeleteModal] = useState("");

   return (
      <div>
         <Flex gap={6}>
            <DeletedActionButton onClick={() => handleRestore(entity)}>
               <HistoryOutlined style={{ fontSize: 20, color: "#5770CB" }} />
            </DeletedActionButton>
            <DeletedActionButton onClick={() => setDeleteModal(entity.id)}>
               <Icon
                  style={{ fontSize: 20, color: "#5770CB" }}
                  component={() => (
                     <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.42859 5.00153H18.5714M6.42859 5.00153V4.28725C6.42859 3.34005 6.80486 2.43163 7.47463 1.76186C8.1444 1.09209 9.05282 0.71582 10 0.71582C10.9472 0.71582 11.8556 1.09209 12.5254 1.76186C13.1952 2.43163 13.5714 3.34005 13.5714 4.28725V5.00153M7.85716 9.28934V15.0058M12.1429 9.28934V15.0058M3.57145 5.00153H16.4286V17.8587C16.4286 18.2375 16.278 18.601 16.0102 18.8688C15.7423 19.1367 15.3789 19.2872 15 19.2872H5.00002C4.62113 19.2872 4.25777 19.1367 3.98986 18.8688C3.72196 18.601 3.57145 18.2375 3.57145 17.8587V5.00153Z"
                           stroke="#5770CB"
                           strokeWidth="1.25"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        />
                     </svg>
                  )}
               />
            </DeletedActionButton>
         </Flex>
         {deleteModal !== "" && (
            <DeleteComponent
               open={deleteModal.length > 0}
               setOpen={() => setDeleteModal("")}
               handleDelete={() => handleDelete(entity)}
               id={deleteModal}
            />
         )}
      </div>
   );
}
