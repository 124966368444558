import React, { useEffect, useMemo } from "react";
import { Typography, Input } from "antd";
import { useSurveyInputStore } from "../../../../surveyStore";

import "./Note.scss";

export function Note({ props, submissionId, surveyId, userId, label, hint, defaultValue }) {
   const { answers } = props;

   const setInputs = useSurveyInputStore((state) => state.setInputs);

   const hasSubmissionId = Boolean(submissionId);

   const submissionAnswer = useMemo(
      () => answers?.find((answer) => answer?.submissionId === submissionId)?.answerDetail?.text,
      [answers, submissionId]
   );
   const value = hasSubmissionId ? submissionAnswer : props.default ?? defaultValue;

   useEffect(() => {
      setInputs({
         target: {
            name: props.id,
            value: {
               surveyId: surveyId,
               userId: userId,
               questionId: props.id,
               questionType: props.questionType,
               name: props.name,
               skipLogicAnswer: value,
               answerDetail: {
                  text: value
               }
            }
         }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [value]);

   return (
      <div className="note-submission-type-wrapper">
         <Typography.Paragraph className="note-submission-type-wrapper--note_text">
            {label}
         </Typography.Paragraph>
         {hint && <span className="note-submission-type-wrapper--hint">{hint}</span>}
         {(props.calculation || props.default) && (
            <Input
               prefixCls="note-submission-type-wrapper--read_only_input"
               size="large"
               value={value}
               readOnly
            />
         )}
      </div>
   );
}
