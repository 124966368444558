import React from "react";
import OrganizationsBanner from "./components/OrganizationsBanner";
import Organizations from "./components/Organizations";
import { SubHeader } from "../../../../_metronic/layout/components/subheader/SubHeader";
import { withErrorBoundary } from "../../../components/ErrorBoundary/withErrorBoundary";

import "./style.scss";

const OrganizationsPage = () => {
   return (
      <div className="organizations">
         <SubHeader></SubHeader>
         <OrganizationsBanner />
         <div className="organizationsBox">
            <h2>My organizations</h2>
            <p>Select an organization to access their workspaces </p>
            <Organizations></Organizations>
         </div>
      </div>
   );
};

export default withErrorBoundary(OrganizationsPage);
