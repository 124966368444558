import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { editDataStore, useSurveyInputStore, useTranslateStore } from "../../surveyStore";

import Section from "../../components/section/Section";
import ItemSelector from "../../components/question/question-preview/ItemSelector";
import AuthService from "../../../../../base/services/authentication.service";
import { LocalStorageService } from "../../../../../base/services/local-storage.service";
import { Button, Input, Modal, message, Spin, Empty, notification, Alert } from "antd";
import { answerPayload, debounce, getGeolocation } from "../../helpers";
import dayjs from "dayjs";
import https from "../../../../../base/utils/http";

import { SendOutlined } from "@ant-design/icons";
import DraftList, { START_NEW_DRAFT } from "./PreviewComponents/DraftList";
import { Constants } from "../../../../../utils/constants";
import useOnlineStore from "../../../../../utils/useOnlineStore";
import PreviewBanner from "./PreviewComponents/PreviewBanner";
import PreviewLanguage from "./PreviewComponents/PreviewLanguage";
import { useFormik } from "formik";
import PreviewModal from "./PreviewComponents/PreviewModal";
import PreviewActions from "./PreviewComponents/PreviewActions";
import { serializeFile } from "../../../../../utils/serializeFile";
import { httpService } from "../../../../../base/services/httpService.service";
import { SurveyStatusEnum } from "../../../../../utils/enums";
import { withErrorBoundary } from "../../../../../components/ErrorBoundary/withErrorBoundary";

import "./preview.scss";
import SurveyConfirmation from "../SurveySubmissions/SurveyConfirmation";

const SurveyDataItems = React.memo(({ surveyData, hasErrors, submissionId }) => {
   return (
      <div className="preview-survey-section">
         {surveyData?.map((item, i) => {
            if (item.itemLabel === "Section") {
               return (
                  <Section
                     key={i}
                     hasErrors={hasErrors}
                     submissionId={submissionId}
                     className="survey-section"
                     surveyItem={item}
                  />
               );
            } else {
               return (
                  <div className={"preview-survey-section--questions_wrapper"} key={i}>
                     <ItemSelector
                        hasErrors={hasErrors}
                        submissionId={submissionId}
                        props={item.surveyItem}
                     />
                  </div>
               );
            }
         })}
      </div>
   );
});

const getInputsState = () => {
   const { inputs } = useSurveyInputStore.getState();
   return inputs;
};

const isPublicSurveyRoute = Constants.IS_PUB_SURVEY_ROUTE;

function Preview() {
   const { id, uuid } = useParams();
   const location = useLocation();
   const navigate = useNavigate();
   const submissionId = location.state;

   const componentRef = useRef();
   const [messageApi, messageContextHolder] = message.useMessage();
   const [modalApi, modalContextHolder] = Modal.useModal();

   const isOnline = useOnlineStore((state) => state.isOnline);

   const getData = editDataStore((s) => s.fetchSurveyById);

   const surveyData = editDataStore((state) => state.surveyData);
   const loading = editDataStore((state) => state.isSurveyLoading);
   const surveyAlldata = editDataStore((state) => state.surveyAllData);

   const setDraftSurvey = useSurveyInputStore((state) => state.setDraftSurvey);
   const setInputs = useSurveyInputStore((state) => state.setInputs);

   const [isModalOpen, setIsModalOpen] = useState(
      LocalStorageService?.getItem(`draftedSurveys-${id}`)?.length > 0
   );
   const [draftedSurveys, setDraftedSurveys] = useState(
      LocalStorageService?.getItem(`draftedSurveys-${id}`) || []
   );
   const [isSaveAsDraft, setIsSaveAsDraft] = useState(false);
   const [selectedDraft, setSelectedDraft] = useState(null);
   const [load, setLoad] = useState(false);
   const [submittingAllDrafts, setSubmittingAllDrafts] = useState(false);

   const [hasErrors, setHasErrors] = useState(false);
   const { toLanguage } = useTranslateStore();
   const [startTimeEnd, setStartTimeEnd] = useState(null);
   const surveyBaseLanguageName = editDataStore((state) => state.surveyBaseLanguageName);

   const draftFormik = useFormik({
      initialValues: {
         draftTitle: "",
         draftDesc: ""
      }
   });
   const { draftTitle, draftDesc } = draftFormik.values;
   const isEditingSurvey = surveyAlldata?.surveyStatusId === SurveyStatusEnum.Editing;

   function clearLocalStorageKeys(keysToClear) {
      keysToClear.forEach((key) => {
         LocalStorageService.removeItem(key); // Remove only the specified keys
      });
   }

   // Explanation: this clears up localStorage keys, which means will log out the user that is logged in in that browser, in order to not submit the Public Survey with the account that is logged in currently
   if (isPublicSurveyRoute)
      clearLocalStorageKeys([
         "user",
         "tokenExpire",
         "atoken",
         "organizationId",
         "organizationName",
         "roles"
      ]);
   // TODO: add navigation blocking for unsaved changes
   useEffect(() => {
      return () => {
         setInputs();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      getData({
         surveyId: id,
         languageId: toLanguage === "" ? "ecfb845a-86f0-476c-a4df-f101d35a96f9" : toLanguage
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id, toLanguage]);

   useEffect(() => {
      LocalStorageService.setItem("startTime", dayjs());
      setStartTimeEnd(dayjs());

      getGeolocation()
         .then((geolocation) => {
            LocalStorageService.setItem(`geolocation`, {
               lat: geolocation.lat,
               long: geolocation.long
            });
         })
         .catch((error) => {
            console.error("Error getting geolocation:", error);
            LocalStorageService.removeItem("geolocation");
         });
   }, []);

   const handleOpenDraftsModal = () => {
      setIsModalOpen(true);
   };

   const discardDraft = (draftTitle) => {
      setDraftedSurveys((prevDraftedSurveys) => {
         const newDraftedSurveys = prevDraftedSurveys.filter((title) => title !== draftTitle);
         LocalStorageService.setItem(`draftedSurveys-${id}`, newDraftedSurveys);

         return newDraftedSurveys;
      });

      LocalStorageService.removeItem(draftTitle);
      LocalStorageService.removeItem(`geolocation==${draftTitle}`);
      LocalStorageService.removeItem(`startTime==${draftTitle}`);
   };

   const onSaveAsDraft = debounce(() => {
      const inputs = getInputsState();
      setHasErrors(false);
      if (Object.values(inputs)?.length === 0) {
         messageApi.open({
            type: "error",
            content:
               "Error: Please provide at least one piece of information before submitting the form."
         });

         return;
      }

      const draftsKey = `draftedSurveys-${id}`;
      const existingDrafts = LocalStorageService.getItem(draftsKey) || [];

      const draftSurveyTitle = selectedDraft ? selectedDraft : `${draftTitle}==${id}==${draftDesc}`;

      const draftAlreadyExists = existingDrafts.some((item) => item === draftSurveyTitle);

      if (!draftAlreadyExists) {
         LocalStorageService.setItem(draftsKey, [...existingDrafts, draftSurveyTitle]);
         setDraftedSurveys((prevState) => [...prevState, draftSurveyTitle]);
      }

      getGeolocation()
         .then((geolocation) => {
            LocalStorageService.setItem(`geolocation==${draftSurveyTitle}`, {
               lat: geolocation.lat,
               long: geolocation.long
            });
         })
         .catch((error) => {
            console.error("Error getting geolocation:", error);
            LocalStorageService.removeItem("geolocation");
         });

      if (!LocalStorageService.getItem(`startTime==${draftSurveyTitle}`)) {
         LocalStorageService.setItem(
            `startTime==${draftSurveyTitle}`,
            LocalStorageService.getItem("startTime")
         );
      }
      let alteredInputs = { ...inputs };
      const promises = Object.keys(alteredInputs).map(async (inputId) => {
         const input = alteredInputs[inputId];
         if (input.questionType === 106) {
            const answerFile = input.answerDetail.answerFiles;

            try {
               const dataUrl = await serializeFile(answerFile);

               alteredInputs[inputId] = {
                  ...input,
                  answerDetail: {
                     ...input.answerDetail,
                     answerFileBase64: {
                        name: answerFile.name,
                        data: dataUrl
                     }
                  }
               };
            } catch (error) {
               console.error("Error serializing file:", error);
            }
         }
      });

      Promise.all(promises).then(() => {
         LocalStorageService.setItem(draftSurveyTitle, alteredInputs);
      });
      messageApi.open({
         type: "success",
         content: "Your answers have been saved in your local device"
      });
      LocalStorageService.setItem("startTime", dayjs());

      draftFormik.resetForm();
      setSelectedDraft(null);
      setDraftSurvey({});

      setIsSaveAsDraft(false);
   }, 1000);

   const countDown = () => {
      let secondsToGo = 10;

      const handleDone = () => {
         clearInterval(timer);
         instance.destroy();
         window.location.reload();
      };

      const instance = modalApi.success({
         width: 600,
         onOk: handleDone,
         title: "Survey queued for submission.",
         content: `Queued surveys, except those marked as draft, are uploaded automatically, 
         in the background when Internet connection is available. 
         Surveys are kept in queue for 30 days from the time they were submitted.`,
         footer: (_, { OkBtn }) => (
            <>
               <Button type="text">{secondsToGo}</Button>
               <OkBtn />
            </>
         )
      });

      const timer = setInterval(() => {
         secondsToGo -= 1;
         instance.update({
            footer: (_, { OkBtn }) => (
               <>
                  <Button type="text">{secondsToGo}</Button>
                  <OkBtn />
               </>
            )
         });
      }, 1000);

      setTimeout(handleDone, secondsToGo * 1000);
   };
   const checkIfUUIDExists = (uuid) => {
      const submittedUUIDs = LocalStorageService.getItem("submittedUUIDs") || [];
      return submittedUUIDs.includes(uuid);
   };

   // Function to save UUID to localStorage
   const saveUUIDToLocalStorage = (uuid) => {
      const submittedUUIDs = LocalStorageService.getItem("submittedUUIDs") || [];
      LocalStorageService.setItem("submittedUUIDs", JSON.stringify([...submittedUUIDs, uuid]));
   };

   // Handle submission with UUID existence check
   const onSubmit = async (selectedDraft, inputs) => {
      // Check if UUID exists in localStorage
      if (checkIfUUIDExists(uuid)) {
         messageApi.open({
            type: "error",
            content: "Survey has already been submitted. You can't submit more than once."
         });
         setLoad(false);
         return;
      }

      setHasErrors(false);
      const renderedElementIds = [...document.querySelectorAll("[data-id]")].map(
         (item) => item.attributes[0].value
      );
      const renderedConstraintMessages = document.querySelectorAll(".preview-question-constraint");

      // const startTime = selectedDraft
      //    ? LocalStorageService.getItem(`startTime==${selectedDraft}`)
      //    : LocalStorageService.getItem("startTime");
      const geolocation = selectedDraft
         ? LocalStorageService.getItem(`geolocation==${selectedDraft}`)
         : LocalStorageService.getItem("geolocation");

      const formData = new FormData();

      const taskId = LocalStorageService.getItem("taskId");
      const searchParams = new URLSearchParams(location.search);
      const receiverId = searchParams.get("receiverId");

      const submissionData = {
         "submission.dataCollectionType": 0,
         "submission.SubmissionStartTime": startTimeEnd,
         "submission.isAnonymous": !AuthService.getUser()?.id,
         userId: AuthService.getUser()?.id || "",
         surveyId: id,
         submissionUuid: uuid
      };

      if (geolocation) {
         submissionData["submission.geolocation.latitude"] = geolocation.lat;
         submissionData["submission.geolocation.longitude"] = geolocation.long;
      }

      if (taskId) {
         submissionData["TaskId"] = taskId;
      }

      if (receiverId) {
         submissionData["MessageReceiverId"] = receiverId;
      }

      for (const [key, value] of Object.entries(submissionData)) {
         formData.append(key, value);
      }

      const inputValues = Object.values(inputs);

      if (inputValues.length === 0) {
         setTimeout(function() {
            setLoad(false);
         }, 500);
         return messageApi.open({
            type: "error",
            content:
               "Error: Please provide at least one piece of information before submitting the form."
         });
      }
      if (renderedConstraintMessages && renderedConstraintMessages.length > 0) {
         setTimeout(function() {
            setLoad(false);
         }, 500);
         renderedConstraintMessages[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
         return messageApi.open({
            type: "error",
            content:
               "Error: Please check the constraints of the questions and provide the correct answers."
         });
      }

      for (let index = 0; index < inputValues.length; index++) {
         const input = inputValues[index];
         formData.append(`answers[${index}].surveyId`, input.surveyId);
         formData.append(`answers[${index}].userId`, input.userId);
         formData.append(`answers[${index}].questionId`, input.questionId);
         formData.append(`answers[${index}].answerDetail.questionType`, input.questionType);
         await answerPayload(input.questionType, formData, index, inputs);
      }

      for (const [index, el] of renderedElementIds.entries()) {
         formData.append(`questionIds[${index}]`, el);
      }

      return https
         .post("/DataCollection/PublishSurvey", formData, {
            headers: {
               "Content-Type": "multipart/form-data"
            }
         })
         .then((response) => {
            if (response.data.isSuccess) {
               messageApi.open({
                  type: "success",
                  content: response.data.message
               });

               // Save UUID to localStorage upon successful submission
               saveUUIDToLocalStorage(uuid);

               if (taskId) {
                  httpService.post(
                     "/Task/updateTaskStatus",
                     {
                        id: taskId,
                        newStatus: "Finished"
                     },
                     (res) => {
                        navigate("/mytasks");
                        LocalStorageService.removeItem("taskId");
                     },
                     (err) => {
                        console.log("error", err);
                     }
                  );
               }

               if (receiverId) {
                  try {
                     const url = `/Task/markSurveySubmissionForReceiverAsCompleted?messageReceiverId=${receiverId}`;
                     httpService.post(url);

                     notification.success({
                        message: "Success",
                        description: "Survey submission marked as completed!"
                     });
                  } catch (error) {
                     notification.error({
                        message: "Error",
                        description: "Failed to mark survey submission as completed."
                     });
                  }
               }
               if (selectedDraft) {
                  discardDraft(selectedDraft);
               }

               LocalStorageService.removeItem(`startTime`);
               LocalStorageService.removeItem(`geolocation`);

               // TODO: this resets the form after submission. remove in the future
               setTimeout(function() {
                  window.location.reload();
               }, 1500);
            } else {
               setHasErrors(true);
            }
            setLoad(false);

            return response;
         })
         .catch((err) => {
            if (isPublicSurveyRoute && !isOnline) {
               if (selectedDraft) {
                  discardDraft(selectedDraft);
               }

               LocalStorageService.removeItem(`startTime`);
               LocalStorageService.removeItem(`geolocation`);
               countDown();
            }
            if (isOnline) {
               const res = err.response.data;
               notification.error({
                  message: (
                     <div>
                        <div>{res.message || "Something went wrong! Please try again later."}</div>
                        <br />
                        <ul>
                           {res?.errors?.map((err) => (
                              <li key={err}>{err}</li>
                           ))}
                        </ul>
                     </div>
                  )
               });
            }
            setLoad(false);

            return err;
         });
   };

   const shouldAddArabicClass = !toLanguage
      ? surveyBaseLanguageName === Constants.ARABIC_LANGUAGE_ID
      : toLanguage === Constants.ARABIC_LANGUAGE_ID;

   return (
      <>
         {loading ? (
            <div className="loading-container">
               <Spin />
            </div>
         ) : (
            <>
               <div
                  ref={componentRef}
                  style={{
                     backgroundColor: "#EEF2FF"
                  }}
               >
                  <PreviewBanner
                     surveyTitle={surveyAlldata?.name}
                     surveyDescription={surveyAlldata?.description}
                     surveyEndDate={surveyAlldata?.endDate}
                  />
                  {checkIfUUIDExists(uuid) ? (
                     <SurveyConfirmation />
                  ) : (
                     <div className="survey-previewer">
                        {isEditingSurvey ? (
                           <Alert
                              prefixCls="survey-previewer--alert"
                              type="warning"
                              message="This is a preview of your survey draft."
                              description="Once you finish editing your survey, publish it and start collecting data"
                           />
                        ) : null}

                        <div
                           className={`${shouldAddArabicClass ? "arabic-survey" : ""}`}
                           style={{
                              border: "1px solid #9BACEB",
                              borderRadius: "8px",
                              overflow: "hidden"
                           }}
                        >
                           <PreviewLanguage ref={componentRef} surveyAlldata={surveyAlldata} />

                           {messageContextHolder}
                           {surveyData?.length ? (
                              <SurveyDataItems
                                 hasErrors={hasErrors}
                                 submissionId={submissionId}
                                 surveyData={surveyData}
                                 shouldAddArabicClass={shouldAddArabicClass}
                              />
                           ) : (
                              <Empty
                                 style={{
                                    padding: "50px 0"
                                 }}
                              />
                           )}
                        </div>

                        {!submissionId && !isEditingSurvey && (
                           <PreviewActions
                              seeDraftsProps={{
                                 onClick: handleOpenDraftsModal
                              }}
                              saveDraftProps={{
                                 onClick: () => {
                                    if (selectedDraft) {
                                       onSaveAsDraft();
                                       return;
                                    }
                                    setIsSaveAsDraft(true);
                                 }
                              }}
                              submitProps={{
                                 onClick: () => {
                                    const inputs = getInputsState();
                                    setLoad(true);
                                    onSubmit(selectedDraft, inputs);
                                 },
                                 loading: load
                              }}
                           />
                        )}
                     </div>
                  )}
               </div>

               {/* Drafts modal - START */}
               <PreviewModal
                  width={922}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  title="Saved drafts"
                  titleStyle={{
                     textAlign: "center",
                     fontSize: "21px",
                     fontWeight: 600
                  }}
                  footer={null}
               >
                  <div className="show-drafts-modal">
                     <p className="show-drafts-modal--description">
                        Below is the list of all your saved drafts. They will be deleted after 30
                        days if not submitted.
                        {/* TODO: write better description. 30 days bit is incorrect */}
                     </p>
                     <div>
                        <DraftList
                           dataSource={[START_NEW_DRAFT, ...draftedSurveys]}
                           selectedDraft={selectedDraft}
                           onDraftSelect={(draftTitle) => {
                              setSelectedDraft(draftTitle === START_NEW_DRAFT ? null : draftTitle);
                              setDraftSurvey(draftTitle === START_NEW_DRAFT ? null : draftTitle);
                              setIsModalOpen(false);
                           }}
                           onDraftDelete={discardDraft}
                           onDraftSubmit={async (draftTitle) => {
                              const inputs = LocalStorageService.getItem(draftTitle);

                              await onSubmit(draftTitle, inputs);
                           }}
                        />
                     </div>

                     <div className="show-drafts-modal--form---actions">
                        <Button
                           prefixCls="show-drafts-modal--form---actions_cancel"
                           type="default"
                           onClick={() => {
                              setIsModalOpen(false);
                           }}
                        >
                           Cancel
                        </Button>
                        <Button
                           prefixCls="show-drafts-modal--form---actions_save"
                           htmlType="submit"
                           type="primary"
                           loading={submittingAllDrafts}
                           disabled={draftedSurveys.length === 0}
                           onClick={async () => {
                              try {
                                 setSubmittingAllDrafts(true);
                                 const draftTitleList = LocalStorageService.getItem(
                                    `draftedSurveys-${id}`
                                 );
                                 const inputsMap = draftTitleList.map((draftTitle) => [
                                    draftTitle,
                                    LocalStorageService.getItem(draftTitle)
                                 ]);

                                 await Promise.all(
                                    inputsMap.map(async (args) => await onSubmit(...args))
                                 );

                                 // TODO: add this case for the future
                                 // if (responseAll.every((response) => response.data.isSuccess)) {
                                 //    setDraftedSurveys([]);
                                 //    LocalStorageService.removeItem(`draftedSurveys-${id}`);
                                 //    messageApi.open({
                                 //       type: "success",
                                 //       content: "All drafts have been submitted successfully."
                                 //    });
                                 // }

                                 setSubmittingAllDrafts(false);
                              } catch (err) {
                                 console.error(err);
                                 setSubmittingAllDrafts(false);
                              }
                           }}
                        >
                           <SendOutlined
                              style={{
                                 translate: "0 -5px"
                              }}
                              rotate={-45}
                           />
                           <span>Submit all drafts</span>
                        </Button>
                     </div>
                  </div>
               </PreviewModal>
               {/* Drafts modal - END */}

               {/* Save draft modal - START */}
               <PreviewModal
                  isModalOpen={isSaveAsDraft}
                  setIsModalOpen={setIsSaveAsDraft}
                  title="Save as a draft"
                  titleStyle={{
                     textAlign: "center"
                  }}
                  footer={null}
               >
                  <div className="save-draft-modal">
                     <p className="save-draft-modal--description">
                        To keep a draft of this survey, assign a name to it to review it later.{" "}
                        <br />
                        If they are not submitted within <b>30 days</b> they will be permanently
                        deleted.
                        {/* TODO: write better description. 30 days bit is incorrect */}
                     </p>
                     <form
                        onSubmit={(e) => {
                           e.preventDefault();
                           onSaveAsDraft();
                        }}
                        className="save-draft-modal--form"
                     >
                        <div>
                           <div className="save-draft-modal--form---control">
                              <label htmlFor="draftTitle">
                                 <span
                                    style={{
                                       color: "#FF4D4F"
                                    }}
                                 >
                                    *
                                 </span>{" "}
                                 Draft name
                              </label>
                              <Input
                                 id="draftTitle"
                                 required
                                 type="text"
                                 placeholder="Type something here..."
                                 value={draftFormik.values.draftTitle}
                                 onChange={draftFormik.handleChange}
                              />
                           </div>
                           <div className="save-draft-modal--form---control">
                              <label
                                 htmlFor="draftDesc"
                                 style={{
                                    marginTop: "32px"
                                 }}
                              >
                                 Note{" "}
                                 <span
                                    style={{
                                       color: "#979797",
                                       fontSize: "12px",
                                       fontWeight: 400
                                    }}
                                 >
                                    (optional)
                                 </span>
                              </label>
                              <Input.TextArea
                                 id="draftDesc"
                                 showCount
                                 maxLength={100}
                                 autoSize={{ minRows: 2 }}
                                 placeholder="Autosize height based on content lines"
                                 value={draftFormik.values.draftDesc}
                                 onChange={draftFormik.handleChange}
                              />
                           </div>
                        </div>
                        <div className="save-draft-modal--form---actions">
                           <Button
                              prefixCls="save-draft-modal--form---actions_cancel"
                              type="default"
                              onClick={() => {
                                 setIsSaveAsDraft(false);
                                 draftFormik.resetForm();
                              }}
                           >
                              Cancel
                           </Button>
                           <Button
                              prefixCls="save-draft-modal--form---actions_save"
                              htmlType="submit"
                              type="primary"
                           >
                              Save and close
                           </Button>
                        </div>
                     </form>
                  </div>
               </PreviewModal>
               {/* Save draft modal - END */}

               <>{modalContextHolder}</>
            </>
         )}
      </>
   );
}

export default withErrorBoundary(Preview);
