import { useState, useEffect } from 'react';

const useResponsive = () => {
    const [viewport, setViewport] = useState('desktop');

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 768) {
                setViewport('mobile');
            } else if (width >= 768 && width < 1024) {
                setViewport('tablet');
            } else {
                setViewport('desktop');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { mobile: viewport === 'mobile', tablet: viewport === 'tablet', desktop: viewport === 'desktop' };
};

export default useResponsive;