import React, { useMemo } from "react";
import { AdminAsideMenuList } from "./AdminAsideMenuList";
import { menu } from "../../../../../app/Menu";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import AuthService from "../../../../../app/base/services/authentication.service";
import { UserRolesEnum } from "../../../../../app/pages/auth/userRoles.enum";
import { LocalStorageService } from "../../../../../app/base/services/local-storage.service";

export function AdminAsideMenu({ disableScroll }) {
   const uiService = useHtmlClassService();
   const layoutProps = useMemo(() => {
      return {
         layoutConfig: uiService.config,
         asideMenuAttr: uiService.getAttributes("aside_menu"),
         ulClasses: uiService.getClasses("aside_menu_nav", true),
         asideClassesFromConfig: uiService.getClasses("aside_menu", true)
      };
   }, [uiService]);

   return (
      <>
         {/* begin::Menu Container */}
         <div
            id="kt_aside_menu"
            data-menu-vertical="1"
            className={`aside-menu aside-menu-left my-4 ${layoutProps.asideClassesFromConfig}`}
            {...layoutProps.asideMenuAttr}
         >
            {AuthService.isAuthorized([
               UserRolesEnum.SuperAdmin,
               UserRolesEnum.Organization,
               UserRolesEnum.AdminOfOrganization,
               UserRolesEnum.SeniorProjectManager,
               UserRolesEnum.JuniorProjectManager,
               UserRolesEnum.ProjectManager
            ]) &&
            (!LocalStorageService.getItem("currentWorkspaceId") ||
               !LocalStorageService.getItem("currentProjectID")) ? (
               <AdminAsideMenuList data={menu.adminLayout} layoutProps={layoutProps} />
            ) : (
               <AdminAsideMenuList data={menu.layout} layoutProps={layoutProps} />
            )}
         </div>
         {/* end::Menu Container */}
      </>
   );
}
