import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { LocalStorageService } from "../../../../base/services/local-storage.service";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../../base/services/authentication.service";
import { UserRolesEnum } from "../../../auth/userRoles.enum";
import { httpService } from "../../../../base/services/httpService.service";
import { Tooltip, notification } from "antd";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { EntityTypeEnum } from "../../../../utils/enums";

const Organization = ({
   organizationId,
   organizationName,
   organizationMemberId,
   isDeactivatedUser,
   isDeactivatedOrganization
}) => {
   const [api, contextHolder] = notification.useNotification();
   const navigate = useNavigate();

   const openNotificationWithIcon = (type, message, description) => {
      if (type === "error") {
         api[type]({
            message,
            description
         });
      }
   };

   const navigateToRoute = () => {
      if (
         AuthService.isAuthorized([
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager
         ])
      ) {
         navigate("/admin-workspaces");
      } else if (AuthService.isAuthorized([UserRolesEnum.DataCollector])) {
         navigate("/mytasks");
      } else {
         navigate("/workspace");
      }
   };

   const seeWorkspace = async () => {
      if (isDeactivatedOrganization) return;
      const userId = LocalStorageService.getItem("user")?.id;
      try {
         const organizationParams = new URLSearchParams({
            userId: userId,
            organizationId: organizationId
         });
         await httpService.get(
            `/Account/selectOrganization?${organizationParams.toString()}`,
            (response) => {
               if (!response) return;
               LocalStorageService.setItem("atoken", response.data.token);
               LocalStorageService.setItem("tokenExpire", response.data.tokenExpire);
               LocalStorageService.setItem("organizationId", organizationId);
               LocalStorageService.setItem("organizationName", organizationName);
               LocalStorageService.setItem("roles", response.data.userRole);

               if (isDeactivatedUser) {
                  const params = new URLSearchParams({
                     entityType: EntityTypeEnum.OrganizationMember,
                     isDeleted: false
                  });
                  httpService
                     .put(
                        `/DataCollection/${organizationMemberId}/toggleDeleteOrRestore?${params.toString()}`,
                        {},
                        (response) => {
                           if (!response) return;
                           LocalStorageService.setItem("reactivatedAccount", true);
                           navigateToRoute();
                        }
                     )
                     .catch((error) => {
                        openNotificationWithIcon(
                           "error",
                           "Unable to restore the user. Please try again later."
                        );
                     });
               } else {
                  navigateToRoute();
               }
            }
         );
      } catch (error) {
         openNotificationWithIcon("error", "Something went wrong. Please try again.");
      }
   };

   const content = (
      <div
         className={`organization ${
            isDeactivatedUser
               ? "deactivatedUser"
               : isDeactivatedOrganization
               ? "deactivatedOrganization"
               : ""
         }`}
      >
         <img
            alt="workspace-svg"
            src={toAbsoluteUrl("/media/bg/workspace-bg.png")}
            className="workspaceBackground"
         />
         <h4>{organizationName}</h4>
         {isDeactivatedOrganization ? null : (
            <div className="seeWorkspaces" onClick={seeWorkspace}>
               {isDeactivatedUser ? "Activate account" : "See workspaces"} <ArrowRightOutlined />
            </div>
         )}
      </div>
   );

   return (
      <>
         {contextHolder}
         {isDeactivatedUser ? (
            <Tooltip title='Your account in this organization has been deactivated. Click "activate account" if you wish to access it again.'>
               {content}
            </Tooltip>
         ) : isDeactivatedOrganization ? (
            <Tooltip title="This organization has been deactivated.">{content}</Tooltip>
         ) : (
            content
         )}
      </>
   );
};

export default Organization;
