import React, { useMemo, useState } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import TermsConditionsModal from "../../../../app/components/TermsConditionsModal";
import { Link } from "react-router-dom";

export function Footer() {
   const today = new Date().getFullYear();
   const uiService = useHtmlClassService();

   const layoutProps = useMemo(() => {
      return {
         footerLayout: objectPath.get(uiService.config, "footer.layout"),
         footerClasses: uiService.getClasses("footer", true),
         footerContainerClasses: uiService.getClasses("footer_container", true)
      };
   }, [uiService]);

   const [modalVisible, setModalVisible] = useState(false);
   const openTermsModal = () => {
      setModalVisible(!modalVisible);
   };
   
   return (
      <>
         {/* begin::Footer */}
         <div
            className={`footer py-4 mx-3 d-flex flex-lg-column ${layoutProps.footerClasses}`}
            id="kt_footer"
         >
            <div
               className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
            >
               <div className="text-dark order-2 order-md-1">
                  <span className="text-muted font-weight-bold mr-">{today} &copy;</span>
                  <span>KAPsurvey</span>
               </div>
               <div className="nav nav-dark order-1 order-md-2">
                  <Link
                     to="#"
                     onClick={openTermsModal}
                     rel="noopener noreferrer"
                     style={{
                        color: "#999",
                        textDecoration: "none",
                        margin: "0 4px"
                     }}
                  >
                     Terms of Service
                  </Link>
                  <Link
                     to="#"
                     onClick={openTermsModal}
                     rel="noopener noreferrer"
                     style={{
                        color: "#999",
                        textDecoration: "none",
                        margin: "0 4px"
                     }}
                  >
                     Privacy Policy
                  </Link>

                  <Link
                     to="#"
                     onClick={openTermsModal}
                     rel="noopener noreferrer"
                     style={{
                        color: "#999",
                        textDecoration: "none",
                        margin: "0 4px"
                     }}
                  >
                     Cookie Policy
                  </Link>
               </div>
            </div>
         </div>
         {/* end::Footer */}

         {/* Modal */}
         <TermsConditionsModal visible={modalVisible} onCancel={openTermsModal} />
      </>
   );
}
