import { ErrorBoundary } from "react-error-boundary";
import Fallback from "./ErrorBoundary";

export const withErrorBoundary = (Component) => {
   const logError = (error, info) => {
      // Do something with the error, e.g. log to an external API
      console.log("withErrorBoundary - logError fn", {
         error,
         info,
         catchComponentName: Component.name
      });
   };
   return (props) => (
      <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
         <Component {...props} />
      </ErrorBoundary>
   );
};
