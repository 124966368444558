import React from "react";
import { Button } from "antd";
import "./SurveyConfirmation.scss";
import logo from "../../../../../../app/assets/images/SurveyConfirmation.svg";

function SurveyConfirmation() {
   return (
      <div className="survey-confirmation">
         <div className="confirmation-content">
            <div className="left-part">
               <img src={logo} alt="Girl working on laptop" className="illustration" />
            </div>
            <div className="right-part">
               <h1>Thank you for filling out this survey!</h1>
               <p>Your responses have been submitted successfully.</p>
               <p>
                  If you think there has been a mistake and you have not completed this survey yet,
                  please email us at{" "}
                  <a href="mailto:info@kaptechgroup.com">info@kaptechgroup.com</a> to get
                  assistance.
               </p>
            </div>
         </div>
      </div>
   );
}

export default SurveyConfirmation;
