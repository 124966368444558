import React from "react";
import { getStatusClassName } from "../helpers/statusClassNames";
import { ReactComponent as SuggestIcon } from "../pages/workspace/task-management/task-collection/icons/suggested.svg";

export const columns = [
   {
      title: "Beneficiary name",
      dataIndex: "beneficiaryName"
   },
   {
      title: "Deadline",
      dataIndex: "deadline",
      width: "200px"
   },
   {
      title: "Country",
      dataIndex: "country"
   },
   {
      title: "City",
      dataIndex: "city"
   },
   {
      title: "Collection type",
      dataIndex: "collectionType",
      // width: "230px",
      render: (text, record) => {
         return <p className="collectionType">{text}</p>;
      }
   },
   {
      title: "Status",
      dataIndex: "status",
      // width: "230px",
      render: (text, record) => {
         return <p className={`status ${getStatusClassName(text)}`}>{text}</p>;
      }
   }
];

export const taskColumns = [
   // {
   //    title: "ID",
   //    dataIndex: "id"
   // },
   {
      title: "Collection round",
      dataIndex: "collectionRound"
   },
   {
      title: "Survey name",
      dataIndex: "surveyName"
   },
   {
      title: "Deadline",
      dataIndex: "tasksDeadline"
   },
   {
      title: "Location",
      dataIndex: "locationName"
   },
   {
      title: "Collection type",
      dataIndex: "collectionType",
      render: (text, record) => {
         return <p className="collectionType">{text}</p>;
      }
   },
   {
      title: "Status",
      dataIndex: "status",
      // width: "230px",
      render: (text, record) => {
         return <p className={`status ${getStatusClassName(text)}`}>{text}</p>;
      }
   },
   {
      title: "Note",
      dataIndex: "note"
   }
];

export const locationColumns = [
   {
      title: "Location group name",
      dataIndex: "name"
   },
   {
      title: "Country",
      dataIndex: "countryName"
   },
   {
      title: "State",
      dataIndex: "state"
   },
   {
      title: "City",
      dataIndex: "city"
   },
   {
      title: "District",
      dataIndex: "district"
   },
   {
      title: "Subdistrict",
      dataIndex: "subdistrict"
   },
   {
      title: "Village/neighborhood",
      dataIndex: "village_Neighborhood"
   },
   {
      title: "Address Line",
      dataIndex: "addressLine1"
   },
   {
      title: "Location type",
      dataIndex: "locationType"
   }
];

export const dataCollectorColumns = [
   {
      title: "Enumerator's name",
      dataIndex: "enumeratorName",
      render: (text, record) => {
         return (
            <div className="enumeratorName">
               {record.isSuggested && <SuggestIcon />}
               <p>{text}</p>
            </div>
         );
      }
   },
   {
      title: "Gender",
      dataIndex: "gender"
   },
   {
      title: "Location",
      dataIndex: "location"
   }
];

export const dataSource = [
   {
      key: 1,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "12",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "Text SMS",
      status: "In Progress",
      note: "Text goes here"
   },
   {
      key: 2,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "18",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "In person",
      status: "Completed",
      note: "Text goes here"
   },
   {
      key: 3,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "52",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "Text SMS",
      status: "Assigned",
      note: "Text goes here"
   },
   {
      key: 4,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "97",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "Text SMS",
      status: "Completed",
      note: "Text goes here"
   },
   {
      key: 5,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "5",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "In person",
      status: "Pending",
      note: "Text goes here"
   },
   {
      key: 6,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "77",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "Text SMS",
      status: "Rejected",
      note: "Text goes here"
   },
   {
      key: 7,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "29",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "Text SMS",
      status: "Assigned",
      note: "Text goes here"
   },
   {
      key: 8,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "81",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "Text SMS",
      status: "Accepted",
      note: "Text goes here"
   },
   {
      key: 9,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "22",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "Text SMS",
      status: "Rejected",
      note: "Text goes here"
   },
   {
      key: 10,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "58",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "In person",
      status: "Pending",
      note: "Text goes here"
   },
   {
      key: 11,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "89",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "Phone call",
      status: "Completed",
      note: "Text goes here"
   },
   {
      key: 12,
      collectionRoundId: "ID4378484895",
      collectionRound: "Sampling method",
      interviews: "17",
      deadline: "17/04/2024",
      location: "Location",
      location2: "Location",
      collectionType: "Phone call",
      status: "Accepted",
      note: "Text goes here"
   }
];

export const items = [
   {
      key: "All",
      label: "All"
   },
   {
      key: "Assigned",
      label: "Assigned"
   },
   // {
   //    key: "In Progress",
   //    label: "In Progress"
   // },
   {
      key: "Completed",
      label: "Completed"
   },
   {
      key: "Rejected",
      label: "Rejected"
   }
];

export const taskItems = [
   {
      key: "All",
      label: "All"
   },
   {
      key: "Assigned",
      label: "Assigned"
   },
   {
      key: "Completed",
      label: "Completed"
   },
   {
      key: "Rejected",
      label: "Rejected"
   }
];
