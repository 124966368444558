export const formatDate = (dateString) => {
   const originalDate = new Date(dateString);

   const day = originalDate.getDate();
   const month = originalDate.getMonth() + 1;
   const year = originalDate.getFullYear();

   const formattedDay = day < 10 ? `0${day}` : day;
   const formattedMonth = month < 10 ? `0${month}` : month;

   return `${formattedDay}/${formattedMonth}/${year}`;
};
