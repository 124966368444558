import { Radio, Grid } from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";

import "./Rating.scss";

const { useBreakpoint } = Grid;

export const Rating = React.memo(
   ({ props, surveyId, hasErrors, userId, submissionId, label, hint }) => {
      const {
         id,
         selectQuestionChoices,
         secondChoices,

         questionType,
         isRequired
      } = props;

      const hasSubmissionId = Boolean(submissionId);
      const [value, setValue] = useState(
         hasSubmissionId
            ? props.answers.find(({ submissionId: subId }) => subId === submissionId)?.answerDetail
                 ?.answerRatings || []
            : []
      );

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const screens = useBreakpoint();

      const showErrors =
         hasErrors &&
         isRequired &&
         (!inputState || inputState?.answerDetail?.answerRatings?.length === 0);

      const isBigScreen = useMemo(() => screens.md || screens.lg || screens.xl || screens.xxl, [
         screens
      ]);

      const rowQuestions = useMemo(
         () =>
            selectQuestionChoices.map((element) => {
               return {
                  choice: element.choiceLabels[0]?.text,
                  id: element.choiceLabels[0]?.choiceId
               };
            }),
         [selectQuestionChoices]
      );

      const columnQuestions = useMemo(
         () =>
            secondChoices.map((element) => {
               return { choice: element.name, id: element.id };
            }),
         [secondChoices]
      );

      const onChange = (e) => {
         const splitString = e.target.value.split("===");
         const newRow = splitString[0];
         const newColumn = splitString[1];

         const existingIndex = value.findIndex((el) => el.choiceId === newRow);
         const existingItem = value.find(
            (el) => el.choiceId === newRow && el.secondChoiceId === newColumn
         );

         if (existingItem) {
            return setValue((prev) => prev.filter((el) => el !== existingItem));
         }

         if (existingIndex !== -1) {
            setValue((prev) => {
               const updatedValue = [...prev];
               updatedValue[existingIndex] = { choiceId: newRow, secondChoiceId: newColumn };
               return updatedValue;
            });
         } else {
            setValue((prev) => [...prev, { choiceId: newRow, secondChoiceId: newColumn }]);
         }
      };

      useEffect(() => {
         if (hasSubmissionId) {
            return;
         }

         setValue(inputState?.answerDetail?.answerRatings ?? []);
      }, [inputState, id, hasSubmissionId]);

      useEffect(() => {
         if (hasSubmissionId) {
            return;
         }
         if (value.length === 0) {
            return;
         }
         setInputs({
            target: {
               name: id,
               value: {
                  surveyId: surveyId,
                  userId: userId,
                  questionId: id,
                  questionType: questionType,
                  name: props.name,
                  skipLogicAnswer: value,
                  answerDetail: {
                     answerRatings: value
                  }
               }
            }
         });
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [value, hasSubmissionId]);

      return (
         <div className="rating-submission-type">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />
            <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />

            <div className="rating-submission-type--wrapper">
               <table className={"rating-submission-type--wrapper-table"}>
                  {isBigScreen ? (
                     <thead>
                        <tr>
                           {[{}, ...columnQuestions].map((column, index) => (
                              <th
                                 key={`column-${index}`}
                                 style={{
                                    width: `${100 / (columnQuestions.length + 1)}%`,
                                    minWidth: "100px"
                                 }}
                              >
                                 {column.choice}
                              </th>
                           ))}
                        </tr>
                     </thead>
                  ) : null}
                  <tbody className={showErrors ? "error" : ""}>
                     {isBigScreen
                        ? rowQuestions.map((row, index) => (
                             <tr key={index} className={showErrors ? "error" : ""}>
                                <td style={{ minWidth: "236px" }}>{row.choice}</td>

                                {columnQuestions.map((column, columnIndex) => (
                                   <td key={`row-${index}-column-${columnIndex}`}>
                                      <Radio
                                         onClick={onChange}
                                         value={`${row.id}===${column.id}`}
                                         checked={value.find(
                                            (el) =>
                                               el.choiceId === row.id &&
                                               el.secondChoiceId === column.id
                                         )}
                                         disabled={hasSubmissionId}
                                         prefixCls="rating-submission-type--wrapper-table_radio"
                                         style={{
                                            display: "flex",
                                            justifyContent: "center"
                                         }}
                                      />
                                   </td>
                                ))}
                             </tr>
                          ))
                        : rowQuestions.map((row, index) => (
                             <tr key={index} className={showErrors ? "error" : ""}>
                                <Fragment>
                                   <td style={{ display: "block" }}>{row.choice}</td>

                                   {columnQuestions.map((column, columnIndex) => (
                                      <td
                                         key={`row-${index}-column-${columnIndex}`}
                                         style={{ display: "block" }}
                                      >
                                         <Radio
                                            onClick={onChange}
                                            value={`${row.id}===${column.id}`}
                                            checked={value.find(
                                               (el) =>
                                                  el.choiceId === row.id &&
                                                  el.secondChoiceId === column.id
                                            )}
                                            disabled={hasSubmissionId}
                                            prefixCls="rating-submission-type--wrapper-table_radio"
                                            className={
                                               submissionId ? "submissionStyle w-full" : "w-full"
                                            }
                                         >
                                            {column.choice}
                                         </Radio>
                                      </td>
                                   ))}
                                </Fragment>
                             </tr>
                          ))}
                  </tbody>
               </table>
            </div>

            <span className="rating-submission-type--error">
               {showErrors ? "This field is required" : " "}
            </span>
         </div>
      );
   }
);
