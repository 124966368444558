import React, { lazy, Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AdminLayout } from "../_metronic/layout/components/AdminLayout";
import ProtectedRoute from "./base/components/ProtectedRoute";
import AuthService from "./base/services/authentication.service";
import { AuthPage } from "./pages/auth/routes";
import { UserRolesEnum } from "./pages/auth/userRoles.enum";
import { Error500 } from "./pages/errorsExamples/Error500";
import { Error404 } from "./pages/errorsExamples/Error404";
import { Error401 } from "./pages/errorsExamples/Error401";
import Workspace from "./pages/workspace/index";
import Test from "./pages/admin/workspace/Test";
import EditPage from "./pages/workspace/components/newWorkSpace/EditPage";
import { NewWorkspace } from "./pages/workspace/components/newWorkSpace/NewWorkspace";
import { WorkspaceLayout } from "../_metronic/layout/components/WorkspaceLayout";
import SpaceSelectionPage from "./pages/workspace/space-selection";
import ProjectSelection from "./pages/workspace/projects/project-selection";
import { CreateNewProject } from "./pages/workspace/components/projects/CreateNewProject";
import ProjectTypes from "./pages/workspace/components/projects/createNewProject/ProjectTypes";
import AdminWorkspace from "./pages/admin/workspace/AdminWorkspace";
import UserManagement from "./pages/admin/user-management/UserManagement";
import PaymentPlans from "./pages/admin/payment-plans/PaymentPlans";
import { ComingSoon } from "./pages/comingSoon/ComingSoon";
import Preview from "./pages/surveyBuilder/v2/viewer/Preview/Preview";
import MyTasks from "./pages/workspace/my-tasks";
import { Spin } from "antd";
import OrganizationsPage from "./pages/admin/organizations/OrganizationsPage";
import ProfilePage from "./pages/auth/profile/ProfilePage";
import SubscriptionPage from "./pages/auth/subscription-plans/SubscriptionPage";
import LoginInformationPage from "./pages/auth/login-information/LoginInformationPage";
import AccountSummaryPage from "./pages/auth/account-summary/AccountSummaryPage";
import AdminDeletedItems from "./pages/admin/deleted-items/AdminDeletedItems";
import { withErrorBoundary } from "./components/ErrorBoundary/withErrorBoundary";

const AdminPages = lazy(() => import("./pages/admin/routes"));
const WorkspacePages = lazy(() => import("./pages/workspace/routes"));

function Routess() {
   const [isAuthenticated, setisAuth] = useState(() => AuthService.isAuthenticated());

   const location = useLocation();

   useEffect(() => {
      setisAuth(AuthService.isAuthenticated());
   }, [location, setisAuth]);

   return (
      <Suspense
         fallback={
            <Spin
               size="large"
               style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
               }}
            />
         }
      >
         <Routes>
            <Route path="/public-survey/:uuid/:id" element={<Preview />} />

            {!isAuthenticated ? (
               /*Render auth page when user at `/auth` and not authorized.*/
               <Route path="/*" element={<AuthPage />} />
            ) : (
               /*Otherwise Navigate to root page (`/`)*/
               <>
                  {!AuthService.getOrganizationId() ||
                  !JSON.parse(localStorage.getItem("roles")) ? (
                     <Route path="/" element={<Navigate to="/organizations" />} />
                  ) : AuthService.isAuthorized([
                       UserRolesEnum.SuperAdmin,
                       UserRolesEnum.Organization,
                       UserRolesEnum.AdminOfOrganization,
                       UserRolesEnum.SeniorProjectManager
                    ]) ? (
                     <Route path="/" element={<Navigate to="/admin-workspaces" />} />
                  ) : AuthService.isAuthorized([UserRolesEnum.DataCollector]) ? (
                     <Route path="/" element={<Navigate to="/mytasks" />} />
                  ) : (
                     <Route path="/" element={<Navigate to="/workspace" />} />
                  )}

                  <Route path="/organizations" element={<OrganizationsPage />} />
                  <Route element={<AdminLayout />} /* Main Content with Layout */>
                     <Route path="/auth/*" element={<Navigate to="/" />} />
                     {/* module */}
                     <Route
                        element={
                           <ProtectedRoute
                              allowedRoles={[
                                 UserRolesEnum.SuperAdmin,
                                 UserRolesEnum.Organization,
                                 UserRolesEnum.JuniorProjectManager,
                                 UserRolesEnum.AdminOfOrganization,
                                 UserRolesEnum.SeniorProjectManager,
                                 UserRolesEnum.ProjectManager,
                                 UserRolesEnum.Guest
                              ]}
                           />
                        }
                     >
                        <Route path="/*" element={<WorkspacePages />} />
                     </Route>
                     {/* module */}
                  </Route>
                  <Route element={<AdminLayout />}>
                     <Route
                        element={
                           <ProtectedRoute
                              allowedRoles={[
                                 UserRolesEnum.SuperAdmin,
                                 UserRolesEnum.Organization,
                                 UserRolesEnum.AdminOfOrganization,
                                 UserRolesEnum.SeniorProjectManager
                              ]}
                           />
                        }
                     >
                        <Route path="/admin-workspaces" element={<AdminWorkspace />} />
                        <Route path="/admin-user-management" element={<UserManagement />} />
                        <Route path="/admin-deleted-items" element={<AdminDeletedItems />} />
                        {/* TODO: useless route */}
                        <Route path="/admin-payment-plans" element={<PaymentPlans />} />
                        <Route path="/workspace/new-work-space" element={<NewWorkspace />} />
                        <Route path="/workspace/edit/:id" element={<EditPage />} />{" "}
                        <Route path="/admin/*" element={<AdminPages />} />
                     </Route>
                  </Route>
                  <Route element={<AdminLayout />}>
                     <Route
                        element={<ProtectedRoute allowedRoles={[UserRolesEnum.Organization]} />}
                     >
                        <Route path="/subscription-plans" element={<SubscriptionPage />} />
                        <Route path="/account-summary" element={<AccountSummaryPage />} />
                     </Route>
                     <Route
                        element={
                           <ProtectedRoute
                              allowedRoles={[
                                 UserRolesEnum.SuperAdmin,
                                 UserRolesEnum.Organization,
                                 UserRolesEnum.JuniorProjectManager,
                                 UserRolesEnum.AdminOfOrganization,
                                 UserRolesEnum.SeniorProjectManager,
                                 UserRolesEnum.ProjectManager
                                 // UserRolesEnum.Guest
                              ]}
                           />
                        }
                     >
                        <Route path="/profile-information" element={<ProfilePage />} />
                        <Route path="/login-information" element={<LoginInformationPage />} />
                     </Route>
                  </Route>
                  {/* module */}
                  <Route element={<ProtectedRoute allowedRoles={[UserRolesEnum.DataCollector]} />}>
                     <Route path="/mytasks" element={<MyTasks />} />
                  </Route>
                  <Route element={<WorkspaceLayout />}>
                     <Route
                        element={
                           <ProtectedRoute
                              allowedRoles={[
                                 UserRolesEnum.SuperAdmin,
                                 UserRolesEnum.Organization,
                                 UserRolesEnum.JuniorProjectManager,
                                 UserRolesEnum.AdminOfOrganization,
                                 UserRolesEnum.SeniorProjectManager,
                                 UserRolesEnum.ProjectManager,
                                 UserRolesEnum.Guest
                              ]}
                           />
                        }
                     >
                        <Route path="/workspace" element={<Workspace />} />
                     </Route>
                  </Route>
                  <Route element={<WorkspaceLayout />}>
                     <Route
                        element={
                           <ProtectedRoute
                              allowedRoles={[
                                 UserRolesEnum.SuperAdmin,
                                 UserRolesEnum.Organization,
                                 UserRolesEnum.JuniorProjectManager,
                                 UserRolesEnum.AdminOfOrganization,
                                 UserRolesEnum.SeniorProjectManager,
                                 UserRolesEnum.ProjectManager,
                                 UserRolesEnum.Guest
                              ]}
                           />
                        }
                     >
                        <Route path="/workspace" element={<Workspace />} />
                        <Route path="/space-selection" element={<SpaceSelectionPage />} />
                        <Route path="/project-selection" element={<ProjectSelection />} />
                        <Route
                           path="/project-selection/create-project"
                           element={<CreateNewProject />}
                        />
                        <Route
                           element={
                              <ProtectedRoute
                                 allowedRoles={[
                                    UserRolesEnum.SuperAdmin,
                                    UserRolesEnum.Organization,
                                    UserRolesEnum.AdminOfOrganization,
                                    UserRolesEnum.SeniorProjectManager,
                                    UserRolesEnum.ProjectManager
                                 ]}
                              />
                           }
                           redirectPath="/"
                        >
                           {/* TODO: this route is not used because we don't have an edit button for projects in projects list */}
                           <Route
                              path="/project-selection/create-project/:id"
                              element={<ProjectTypes />}
                           />
                        </Route>
                        {/* TODO: useless route */}
                        <Route path="/workspace/test" element={<Test />} />
                        {/* TODO: useless route */}
                        <Route path="/workspace/add" element={<NewWorkspace />} />
                        {/* will add :id  */}
                     </Route>
                  </Route>
                  {/* module */}
               </>
            )}
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/unauthorized" element={<Error401 />} />
            <Route path="/404" element={<Error404 />} />
            <Route path="/500" element={<Error500 />} />
            <Route path="*" element={<Error500 /> /* Catch All */} />
         </Routes>
      </Suspense>
   );
}
const RoutessWithErrorBoundary = withErrorBoundary(Routess);
export { RoutessWithErrorBoundary as Routess };
