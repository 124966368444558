import { notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { httpService } from "../../../../base/services/httpService.service";
import { useSearchParams } from "react-router-dom";
import { FetchStatusEnum, StatusCodesEnum } from "../../../../utils/enums";
import https from "../../../../base/utils/http";
import TabContainer from "../../../../components/DeletedItemsComponents/TabContainer/TabContainer";
import DataTable from "../../../../components/DeletedItemsComponents/DataTable/DataTable";

const PAGE_SIZE = 10;

function WorkspacesTab() {
   const [data, setData] = useState([]);
   const [status, setStatus] = useState(FetchStatusEnum.Idle);
   const [totalSize, setTotalSize] = useState(0);
   const [searchValue, setSearchValue] = useState("");

   const [searchParams, setSearchParams] = useSearchParams();

   const entityType = searchParams.get("entityType");
   const page = searchParams.get("page") || 1;

   const handleRestore = async (entity) => {
      try {
         const params = new URLSearchParams({
            entityType: entityType,
            isDeleted: false
         });
         const res = await https.put(
            `/DataCollection/${entity.id}/toggleDeleteOrRestore?${params.toString()}`
         );

         notification.open({
            type: res.data.statusCode === StatusCodesEnum.OK ? "success" : "error",
            message: res.data.message || "Something went wrong! Please try again later."
         });
         fetchData();
      } catch (_err) {
         notification.error({
            message: "Error restoring beneficiary database, please try again later."
         });
      }
   };

   const handleDelete = async (entity) => {
      try {
         const res = await https.post(`/Workspaces/delete`, { id: entity.id });
         notification.open({
            type: res.data.statusCode === StatusCodesEnum.OK ? "success" : "error",
            message: res.data.message || "Something went wrong! Please try again later."
         });
         fetchData();
      } catch (error) {
         if (
            error.response.status === 400 &&
            Array.isArray(error.response.data.errors) &&
            error.response.data.errors.includes &&
            error.response.data.errors.includes(
               "One or more of the projects in this workspace have surveys and can't be deleted!"
            )
         ) {
            notification.error({
               message:
                  "One or more of the projects in this workspace have surveys and can't be deleted!"
            });
         } else {
            notification.error({
               message: "Error deleting workspace, please try again later."
            });
         }
      }
   };

   const fetchData = useCallback(async () => {
      setStatus(FetchStatusEnum.Loading);

      const params = new URLSearchParams({
         isDeleted: "true",
         search: searchValue,
         page: page,
         pageSize: PAGE_SIZE
      });

      httpService.get(
         `/Workspaces/getMyWorkspaces?${params.toString()}`,
         (res) => {
            setStatus(FetchStatusEnum.Success);
            setData(res.data);
            setTotalSize(res.data?.totalSize || res.data?.length || 0);

            // TODO: This endpoint doesn't have pagination
            // if (res.data?.length === 0 && res.data?.totalSize !== 0) {
            //    setSearchParams({ entityType, page: page > 1 ? page - 1 : 1 });
            // }
         },
         (_err) => {
            setStatus(FetchStatusEnum.Error);
         }
      );
   }, [page, searchValue]);

   useEffect(() => {
      if (!entityType) {
         return;
      }
      fetchData();
   }, [entityType, fetchData]);

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name"
      },
      {
         title: "Description",
         dataIndex: "description",
         key: "description"
      }
   ];

   return (
      <TabContainer
         searchValue={searchValue}
         setSearchValue={setSearchValue}
         title={"Deleted Workspaces"}
         searchPlaceholder={"Search by name"}
      >
         <DataTable
            dataSource={data}
            columns={columns}
            status={status}
            refetch={fetchData}
            entityType={entityType}
            handleRestore={handleRestore}
            handleDelete={handleDelete}
            pagination={{
               total: totalSize,
               pageSize: PAGE_SIZE,
               current: page,
               onChange: (page) => setSearchParams({ entityType, page })
            }}
         />
      </TabContainer>
   );
}
export default WorkspacesTab;
