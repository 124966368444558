import { Button, Form, Input, Flex, notification, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { httpService } from "../../../base/services/httpService.service";
import { useAuthSore } from "../authStore";
import { RegisterHeader } from "./components/createProfile/RegisterHeader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { SessionService } from "../../../base/services/session.service";
import "../style.scss";
import FormItem from "antd/es/form/FormItem";
function ConfirmationCodeModal(props) {
  const [codeValue, setCodeValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const verifyCode = () => {
    console.log(codeValue);
    setIsLoading(true);
    httpService.post('/Account/confirmEmail', {
      userId: SessionService.getItem('currentUserId'),
      code: codeValue
    }, (res) => {
      if (res?.isSuccess) {
        SessionService.setItem('isAccountConfirmed', true);
        props.confirmCode()
        props.handleClose()
      }
      setIsLoading(false);
    }, () => {
      setIsLoading(false);
      api['error']({
        message: "Error",
        description: "Wrong code"
      });
    })
  }

  return (
    <div className="conf-modal-wrapper">
      {contextHolder}
      <Modal
        style={{ width: '450px', top: 'calc(50% - 230px)' }}
        open={props?.isModaOpen}
        closable={false}
        mask={true}
        maskClosable={false}
        getContainer={document.querySelector('.conf-modal-wrapper')}>
        <Form>
          <div className="conf-inner">
            <h1>Verification code</h1>
            <p>Please confirm your email address to proceed with the payment. Check your inbox for the verification code, and once confirmed, you can seamlessly move on to the next step. Thank you!</p>
          </div>
          <div className="code-input">
            <Form.Item
              name="codeValue"
              rules={[
                {
                  pattern: /^(?:\d*)$/,
                  message: "Value should contain just number",
                },
                {
                  pattern: /^[\d]{6,6}$/,
                  message: "Value should have 6 character",
                },
              ]}
            >
              <Input
                // type="number"
                maxLength={6}
                style={{ maxWidth: '210px', height: '70px', fontSize: '50px', fontWeight: 'bold', color: '#003DAA', textAlign: 'center' }}
                value={codeValue}
                onChange={(e) => setCodeValue(e.target.value)}
                autoComplete="off"
              />
            </Form.Item>
          </div>
          <div className="button-split">
            <Flex gap="medium" wrap="wrap" style={{ justifyContent: 'space-evenly', marginTop: '40px' }}>
              <Button style={{ color: '#4158CF', borderColor: '#4158CF', height: 'auto', padding: '10px 15px' }} size="large" onClick={props?.handleClose}>Cancel</Button>
              <Button style={{ backgroundColor: '#4158CF', height: 'auto', padding: '10px 15px' }} type="primary" size="large" loading={isLoading} onClick={verifyCode}>Verify code</Button>
            </Flex>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default ConfirmationCodeModal;
