import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { httpService } from "../../../../../base/services/httpService.service";
import { SessionService } from "../../../../../base/services/session.service";

function PlanInformation() {
   const [selectedPlan, setSelectedPlan] = useState([]);
   const [isYearly, setIsYearly] = useState(true);

   useEffect(() => {
      const onProgressPlan = SessionService.getItem("registeringPlan");
      setSelectedPlan(onProgressPlan);
   }, []);
   useEffect(() => {
      setIsYearly(localStorage.getItem("isYearly"));
   }, []);

   return (
      <section className="plan-information-review">
         <>
            <div className="left-block">
               <h2  style={{color:"#5770cb"}}>Plan type</h2>
               <div>
                  <p>Plan’s name</p>
                  {<span>{selectedPlan?.name}</span>}
               </div>
               <div>
                  <p>Price</p>
                  {/* <span>USD ${selectedPlan?.monthlyPrice}/mo. </span> */}
                  <span>USD $</span>${/* i made this true to convert to string haha */}
                  {isYearly === "true" ? selectedPlan.annuallyPrice : selectedPlan.monthlyPrice} /
                  mo.
               </div>
            </div>
            <div className="right-block">
               <h2  style={{color:"#5770cb"}}>Plan details</h2>
               <ul>
                  <li>
                     <span>
                        <AiOutlineCheckCircle />{" "}
                        {selectedPlan?.workspaceLimit != 0
                           ? selectedPlan?.workspaceLimit
                           : "Unlimited"}{" "}
                        workspace
                     </span>
                  </li>
                  <li>
                     <span>
                        <AiOutlineCheckCircle />{" "}
                        {selectedPlan?.membersLimit != 0 ? selectedPlan?.membersLimit : "Unlimited"}{" "}
                        users/workspace
                     </span>
                  </li>
                  <li>
                     <span>
                        <AiOutlineCheckCircle />{" "}
                        {selectedPlan?.databaseLimit != 0 ? (
                           <>{selectedPlan?.databaseLimit} GB data storage limit</>
                        ) : (
                           <>Unlimited data storage</>
                        )}
                     </span>
                  </li>
               </ul>
            </div>
         </>
      </section>
   );
}

export { PlanInformation };
