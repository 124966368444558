import { Tag, notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { httpService } from "../../../../base/services/httpService.service";
import { useSearchParams } from "react-router-dom";
import { FetchStatusEnum, StatusCodesEnum } from "../../../../utils/enums";
import https from "../../../../base/utils/http";
import TabContainer from "../../../../components/DeletedItemsComponents/TabContainer/TabContainer";
import DataTable from "../../../../components/DeletedItemsComponents/DataTable/DataTable";

const PAGE_SIZE = 10;

function UsersTab() {
   const [data, setData] = useState([]);
   const [status, setStatus] = useState(FetchStatusEnum.Idle);
   const [totalSize, setTotalSize] = useState(0);
   const [searchValue, setSearchValue] = useState("");

   const [searchParams, setSearchParams] = useSearchParams();

   const entityType = searchParams.get("entityType");
   const page = searchParams.get("page") || 1;

   const handleRestore = async (entity) => {
      try {
         const params = new URLSearchParams({
            entityType: entityType,
            isDeleted: false
         });
         const res = await https.put(
            `/DataCollection/${
               entity.organizationMemberId
            }/toggleDeleteOrRestore?${params.toString()}`
         );

         notification.open({
            type: res.data.statusCode === StatusCodesEnum.OK ? "success" : "error",
            message: res.data.message || "Something went wrong! Please try again later."
         });
         fetchData();
      } catch (_err) {
         notification.error({
            message: "Error restoring organization member, please try again later."
         });
      }
   };

   const handleDelete = async (entity) => {
      try {
         const res = await https.post(`/Members/delete`, { id: entity.id });
         notification.open({
            type: res.data.statusCode === StatusCodesEnum.OK ? "success" : "error",
            message: res.data.message || "Something went wrong! Please try again later."
         });
         fetchData();
      } catch (_err) {
         notification.error({
            message: "Error deleting organization member, please try again later."
         });
      }
   };

   const fetchData = useCallback(async () => {
      setStatus(FetchStatusEnum.Loading);

      const params = new URLSearchParams({
         isDeleted: "true",
         search: searchValue,
         page: page,
         pageSize: PAGE_SIZE
      });

      httpService.get(
         `/Members/getAllMembersOfOrganization?${params.toString()}`,
         (res) => {
            setStatus(FetchStatusEnum.Success);
            setData(res.data?.members);
            setTotalSize(res.data?.totalSize || 0);
            if (res.data?.members.length === 0 && res.data?.totalSize !== 0) {
               setSearchParams({ entityType, page: page > 1 ? page - 1 : 1 });
            }
         },
         (_err) => {
            setStatus(FetchStatusEnum.Error);
         }
      );
   }, [entityType, page, searchValue, setSearchParams]);

   useEffect(() => {
      if (!entityType) {
         return;
      }
      fetchData();
   }, [entityType, fetchData]);

   const columns = [
      {
         title: "Full Name",
         dataIndex: "fullName",
         key: "fullName"
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email"
      },
      {
         title: "Roles",
         dataIndex: "roles",
         key: "roles",
         render: (roles) => roles.map((role) => <Tag key={role.name}>{role.name}</Tag>)
      },
      {
         title: "Workspaces",
         dataIndex: "workspaces",
         key: "workspaces",
         render: (workspaces) =>
            workspaces.map((workspace) => <Tag key={workspace.name}>{workspace.name}</Tag>)
      }
   ];

   return (
      <TabContainer
         searchValue={searchValue}
         setSearchValue={setSearchValue}
         title={"Deleted Users"}
         searchPlaceholder={"Search by name or email"}
      >
         <DataTable
            dataSource={data}
            columns={columns}
            status={status}
            refetch={fetchData}
            entityType={entityType}
            handleRestore={handleRestore}
            handleDelete={handleDelete}
            pagination={{
               total: totalSize,
               pageSize: PAGE_SIZE,
               current: page,
               onChange: (page) => setSearchParams({ entityType, page })
            }}
         />
      </TabContainer>
   );
}
export default UsersTab;
