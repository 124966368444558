import React, { useCallback, useEffect, useState } from "react";
import Table from "../../ui/Table/Table";
import "./index.scss";
import { Col, Input, Row } from "antd";
import { CloudDownloadOutlined, FilePdfOutlined, SearchOutlined } from "@ant-design/icons";
import { httpService } from "../../base/services/httpService.service";
import AuthService from "../../base/services/authentication.service";
import { formatDate } from "../../helpers/formatDate";
import { getStatusClassName } from "../../helpers/statusClassNames";

const BillingHistory = React.memo(() => {
   const organizationId = AuthService.getOrganizationId();
   const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
   const [searchText, setSearchText] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [billingHistory, setBillingHistory] = useState([]);

   useEffect(() => {
      setIsLoading(true);
      httpService
         .get(
            `/Payment/getStripeInvoicesDetailsByOrganizationUser?organizationId=${organizationId}`,
            (response) => {
               const formattedData = response.data.map((item) => ({
                  ...item,
                  id: item.invoiceId,
                  key: item.invoiceId,
                  start: formatDate(item.billingPeriodStart),
                  end: formatDate(item.billingPeriodEnd)
               }));
               setBillingHistory(formattedData);
            }
         )
         .finally(() => {
            setIsLoading(false);
         });
   }, []);

   const handlePagination = (page, pageSize) => {
      setPagination({ ...pagination, current: page, pageSize });
   };

   const handleSearch = useCallback(
      (e) => {
         setSearchText(e.target.value);

         setPagination({ ...pagination, current: 1 });
      },
      [searchText]
   );

   const filteredDataSource = billingHistory?.filter((item) => {
      if (!searchText) return item;
      return Object.values(item).some(
         (val) =>
            val &&
            val
               .toString()
               .toLowerCase()
               .includes(searchText.toLowerCase())
      );
   });

   const columns = [
      {
         title: "File name",
         dataIndex: "fileName",
         key: "fileName",
         width: 300,
         render: (text, render) => (
            <div
               style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  cursor: "pointer"
               }}
            >
               <div
                  style={{
                     fontSize: "18px"
                  }}
               >
                  <FilePdfOutlined />
               </div>
               {text}
            </div>
         )
      },
      {
         title: "Start",
         dataIndex: "start",
         key: "start",
         width: 150
      },
      {
         title: "End",
         dataIndex: "end",
         key: "end",
         width: 150
      },
      {
         title: "Amount",
         dataIndex: "amountDue",
         key: "amountDue",
         width: 80
      },
      {
         title: "Status",
         dataIndex: "paymentStatus",
         key: "paymentStatus",
         width: 100,
         render: (text, record) => {
            return <p className={`status ${getStatusClassName(text)}`}>{text}</p>;
         }
      }
   ];

   const downloadFileColumn = {
      key: "previewDownload",
      fixed: "right",
      width: "80px",
      render: (text, render) => (
         <div className="stickyIconsWrapper" onClick={(e) => e.stopPropagation()}>
            <a
               style={{
                  fontSize: "18px",
                  color: "#797979"
               }}
               onClick={(e) => e.stopPropagation()}
               href={render.pdf}
               target="_blank"
               rel="noopener noreferrer"
            >
               <CloudDownloadOutlined />
            </a>
         </div>
      )
   };

   return (
      <>
         {isLoading ? null : (
            <div className="billingHistory accountSettings">
               <Row gutter={16} className="mb-8">
                  <Col xs={24} sm={18} lg={12} xl={12}>
                     <p className="title">Billing history</p>
                  </Col>
                  {/* <Col xs={24} sm={6} lg={12} xl={12}>
            <Button type="default" className="primaryButton" onClick={handleDownloadAll}>
               Download all
            </Button>
         </Col> */}
               </Row>
               <Row className="search mt-8 mb-8">
                  <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                     <Input
                        placeholder="Search"
                        onChange={handleSearch}
                        suffix={<SearchOutlined />}
                     />
                  </Col>
               </Row>
               <Table
                  dataSource={filteredDataSource}
                  columns={[...columns, downloadFileColumn]}
                  pagination={pagination}
                  handlePagination={handlePagination}
                  loadData={isLoading}
                  fixedWidth
               ></Table>
            </div>
         )}
      </>
   );
});

export default BillingHistory;
