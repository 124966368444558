import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Tabs } from "antd";
import { items, taskColumns } from "../../utils/tasksMockData";
import TableData from "../../ui/TableData/TableData";
import { httpService } from "../../base/services/httpService.service";
import Buttons from "../../ui/Buttons/Buttons";
import { formatDate } from "../../helpers/formatDate";
import BasicModal from "../../ui/BasicModal/BasicModal";
import { LocalStorageService } from "../../base/services/local-storage.service";
import useHandleTasksData from "../../pages/workspace/task-management/hooks/useHandleTasksData";
import { withErrorBoundary } from "../ErrorBoundary/withErrorBoundary";

import "./index.scss";

export const formatMyTaskData = (data) => {
   return {
      tasks: data.tasks.map((item) => {
         const status = item.status === "Finished" ? "Completed" : item.status;
         const endDate = formatDate(item.endDate);
         return {
            key: item?.id,
            id: item?.id,
            tasksDeadline: endDate,
            locationName: item.locationName,
            collectionRound: item.collectionRound,
            collectionType: item?.collectionType,
            status: status,
            note: item?.note,
            surveyId: item?.surveyId,
            surveyName: item?.surveyName
         };
      }),
      totalSize: data.totalSize
   };
};

const Tasks = () => {
   const navigate = useNavigate();
   const [form] = Form.useForm();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [recordId, setRecordId] = useState(null);
   const {
      data: dataSource,
      setData: setDataSource,
      isLoading: loadData,
      setIsLoading: setLoadData,
      pagination,
      filterValue,
      activeTab,
      showFilters,
      handleSearch,
      handlePagination,
      onSelect,
      handleClearFilters,
      handleTabChange,
      handleShowFilters
   } = useHandleTasksData("/Task/GetTasksForDataCollector?", formatMyTaskData);

   const handleUpdateData = (id, statusName, status, reason = "") => {
      handleLoad(true);
      httpService.post(
         "/Task/updateTaskStatus",
         {
            id,
            newStatus: statusName,
            rejectionReason: reason
         },
         (res) => {
            if (res) {
               const updatedDataSource = dataSource.map((item) => {
                  const renameStatus = status === "Finished" ? "Completed" : status;
                  if (item.id === id) {
                     return { ...item, status: renameStatus };
                  }
                  return item;
               });
               handleDataSource(updatedDataSource);
               setTimeout(() => {
                  handleLoad(false);
               }, 1000);
            }
         },
         (err) => {
            handleLoad(false);
            console.log("error", err);
         }
      );
   };

   const onReject = (record) => {
      setIsModalOpen(true);
      setRecordId(record.id);
   };

   const onComplete = (record) => {
      handleUpdateData(record.id, "Finished", "Finished");
   };

   const goToSurvey = (record) => {
      LocalStorageService.setItem("taskId", record.id);
      navigate(`/public-survey/${record.id}/${record.surveyId}`);
   };

   const reviewColumn = {
      key: "acceptOrReject",
      fixed: "right",
      width: "150px",
      render: (text, record) => (
         <Buttons
            text={text}
            record={record}
            // onComplete={onComplete}
            onReject={onReject}
            goToSurvey={goToSurvey}
         />
      )
   };
   const updatedColumns =
      activeTab === "Completed" || activeTab === "Rejected"
         ? [...taskColumns]
         : [...taskColumns, reviewColumn];

   const handleLoad = (boolean) => {
      setLoadData(boolean);
   };

   const handleDataSource = (data) => {
      setDataSource(data);
   };

   return (
      <div className="tasksSection">
         <Tabs activeKey={activeTab} onChange={handleTabChange} items={items} />
         <div className="my-tasks-page">
            <TableData
               dataSource={dataSource}
               handleSearch={handleSearch}
               loadData={loadData}
               updatedColumns={updatedColumns}
               onComplete={onComplete}
               onReject={onReject}
               goToSurvey={goToSurvey}
               handlePagination={handlePagination}
               pagination={pagination}
               showFilters={showFilters}
               filterValue={filterValue}
               onSelect={onSelect}
               handleShowFilters={handleShowFilters}
               handleClearFilters={handleClearFilters}
            />
         </div>
         <BasicModal isModalOpen={isModalOpen} handleCancel={() => setIsModalOpen(false)}>
            <div className="rejectTask">
               <h4>Are you sure you want to reject this task?</h4>
               <span>
                  If you wish to reject this task, please explain the reason in the space below.
               </span>
               <br />
               <span>
                  <b>This action cannot be undone</b>
               </span>

               <Form
                  form={form}
                  className="createTaskForm"
                  onFinish={(values) => {
                     handleUpdateData(recordId, "Rejected", "Rejected", values.reason);
                     setIsModalOpen(false);
                     form.resetFields();
                  }}
                  autoComplete="off"
               >
                  <div className="formFields mt-8">
                     <div>
                        <span>Explain why you are rejecting this task</span>

                        <Form.Item
                           name="reason"
                           rules={[{ required: true, message: "Please type something here" }]}
                        >
                           <Input.TextArea placeholder="Type something here" />
                        </Form.Item>
                     </div>
                  </div>

                  <div className="modalButtons" style={{ marginTop: "30px" }}>
                     <Button key="cancel" className="cancel" onClick={() => setIsModalOpen(false)}>
                        Cancel
                     </Button>
                     <Button htmlType="submit" className="primaryButton">
                        Reject task
                     </Button>
                  </div>
               </Form>
            </div>
         </BasicModal>
      </div>
   );
};

const TasksWithErrorBoundary = withErrorBoundary(Tasks);

export { TasksWithErrorBoundary as Tasks };
