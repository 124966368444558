import React, { Fragment, useMemo } from "react";
import Section from "../editor-types/section/Section";
import {
   Note,
   Number,
   SelectMultiple,
   SelectOne,
   Text,
   Photograph,
   Date,
   DateTime,
   Decimal,
   Time,
   PhoneNumber,
   Acknowledge,
   RangeQuestion,
   Ranking,
   Rating,
   Matrix
} from "../submisson-types";
import { useParams } from "react-router-dom";
import { LocalStorageService } from "../../../../../../base/services/local-storage.service";
import { useTranslateStore } from "../../../surveyStore";
import GPS from "../editor-types/gps/GPS";
import CalculateQuestion from "../editor-types/calculate/CalculateQuestion";
import { getForeignInputNames } from "./helpers/getForeignInputNames";
import useInputValuesByName from "./hooks/useInputValueByName";
import { REGEX } from "../../../../../../helpers/constraintParsers";
import useEvaluateConstraint from "./hooks/useEvaluateConstraint";
import { InputType } from "../../../../enums/itemTypes.enum";

import "./ItemSelector.scss";

const components = {
   0: Section, // TODO: is this needed?
   34: Text,
   32: SelectOne,
   33: SelectMultiple,
   35: Number,
   36: Decimal,
   37: Date,
   38: Time,
   40: Note,
   100: PhoneNumber,
   104: DateTime,
   105: GPS, // not supported yet
   106: Photograph,
   112: Acknowledge,
   275: RangeQuestion,
   277: Matrix,
   278: Ranking,
   276: Rating,
   113: CalculateQuestion // not supported yet
};

function ItemSelector({ props, hasErrors, submissionId }) {
   const { questionType, relevant: hasSkipLogic } = props;
   const userId = LocalStorageService?.getItem("user")?.id || "";
   const { toLanguage } = useTranslateStore();
   const isComponentAvailable = components.hasOwnProperty(questionType);
   const { id: surveyId } = useParams();
   const DynamicComponent = components[questionType];

   const inputNames = getForeignInputNames(
      `${props.questionLabels?.[0]?.text ?? ""} ${props?.surveyQuestionHints?.[0]?.text ?? ""}`
   );
   const hasInputs = inputNames.length > 0;
   const inputValuesObject = useInputValuesByName(inputNames);

   const label = useMemo(() => {
      const label = props.questionLabels
         ? props.questionLabels.find((question) => question.languageId === toLanguage)?.text ??
           props.questionLabels[0]?.text
         : "( No label )";

      return hasInputs
         ? label.replace(REGEX.foreignInputValue, (match, inputName) => {
              const value = inputValuesObject[inputName];
              return `${value ?? "[No value]"}`;
           })
         : label;
   }, [hasInputs, inputValuesObject, props.questionLabels, toLanguage]);

   const hint = useMemo(() => {
      const hint =
         props?.surveyQuestionHints.find(({ languageId }) => languageId === toLanguage)?.text ??
         props?.surveyQuestionHints[0]?.text;

      return hasInputs
         ? hint?.replace(REGEX.foreignInputValue, (match, inputName) => {
              const value = inputValuesObject[inputName];
              return `${value ?? "[No value]"}`;
           })
         : hint;
   }, [hasInputs, inputValuesObject, props?.surveyQuestionHints, toLanguage]);

   // defaultValue not being used in: Text, SelectOne, SelectMultiple
   const defaultValue = useEvaluateConstraint(
      [InputType.TEXT, InputType.SELECT_ONE, InputType.SELECT_MULTIPLE, InputType.NOTE].includes(
         questionType
      )
         ? null
         : props?.default,
      true
   );

   const calculatedValue = useEvaluateConstraint(props?.calculation, true);
   const isQuestionSubmitted = useEvaluateConstraint(props?.relevant, true);

   return (
      <Fragment>
         {!hasSkipLogic || isQuestionSubmitted ? (
            <div
               key={"parent-item" + props?.id}
               data-id={props?.id}
               className="preview-item-selector"
            >
               {isComponentAvailable && (
                  <DynamicComponent
                     hasErrors={hasErrors}
                     submissionId={submissionId}
                     props={props}
                     surveyId={surveyId}
                     userId={userId}
                     language={toLanguage}
                     label={label}
                     hint={hint}
                     defaultValue={calculatedValue ?? defaultValue}
                  />
               )}
            </div>
         ) : null}
      </Fragment>
   );
}

export default ItemSelector;
