import React, { Fragment, useState } from "react";
import { Modal } from "antd";
import Icon, { CloseOutlined } from "@ant-design/icons";
import DataTable from "../DataTable/DataTable";
import { DeleteComponent } from "../../../pages/admin/workspace/components/DeleteComponent";

import "./DeletedItemsModal.scss";

const PAGE_SIZE = 10;

const DeletedItemsModal = ({
   entityType,
   label,
   columns,
   data,
   status,
   deletingAll,
   isModalOpen,
   handleCancel,
   fetchData,
   handleDelete,
   handleRestore,
   handleDeleteAll
}) => {
   const [pagination, setPagination] = useState({
      page: 1,
      totalSize: 0
   });
   const [deleteModal, setDeleteModal] = useState("");

   const handleOk = () => {
      setDeleteModal("open");
   };

   return (
      <Fragment>
         <Modal
            prefixCls="deleted-items-modal"
            width={1046}
            closable
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Delete all"
            cancelText="Close"
            okButtonProps={{
               icon: (
                  <Icon
                     component={() => (
                        <svg
                           width="15"
                           height="16"
                           viewBox="0 0 15 16"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M0.542267 4.00133H14.2566M4.54227 4.00133V3.4299C4.54227 2.67214 4.84328 1.94541 5.3791 1.40959C5.91492 0.873773 6.64165 0.572754 7.39941 0.572754C8.15717 0.572754 8.8839 0.873773 9.41972 1.40959C9.95554 1.94541 10.2566 2.67214 10.2566 3.4299V4.00133M5.68512 7.43157V12.0048M9.1137 7.43157V12.0048M2.25655 4.00133H12.5423V14.287C12.5423 14.5901 12.4218 14.8809 12.2075 15.0952C11.9932 15.3094 11.7025 15.4299 11.3994 15.4299H3.39941C3.0963 15.4299 2.80562 15.3094 2.59128 15.0952C2.37696 14.8809 2.25655 14.5901 2.25655 14.287V4.00133Z"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                           />
                        </svg>
                     )}
                  />
               ),
               disabled: data?.length === 0 || deletingAll,
               loading: deletingAll,
               className: "ok-button"
            }}
            cancelButtonProps={{
               className: "cancel-button"
            }}
            closeIcon={
               <CloseOutlined
                  style={{
                     color: "#5770CB",
                     fontSize: "20px"
                  }}
               />
            }
         >
            <header className="deleted-items-modal--title">
               <h3>Deleted {label}</h3>
               <div>
                  Here you can restore or permanently delete {label}. Any {label} that have not been
                  restored within <b>60 days</b> will be permanently deleted.
               </div>
            </header>
            <main className="deleted-items-modal--content">
               <DataTable
                  dataSource={data}
                  columns={columns}
                  status={status}
                  refetch={fetchData}
                  entityType={entityType}
                  handleRestore={handleRestore}
                  handleDelete={handleDelete}
                  pagination={{
                     total: pagination.totalSize,
                     pageSize: PAGE_SIZE,
                     current: pagination.page,
                     onChange: (page) => setPagination({ ...pagination, page })
                  }}
               />
            </main>
         </Modal>

         {deleteModal !== "" && (
            <DeleteComponent
               open={deleteModal.length > 0}
               setOpen={() => setDeleteModal("")}
               handleDelete={handleDeleteAll}
               id={deleteModal}
            />
         )}
      </Fragment>
   );
};

export default DeletedItemsModal;
