import React from "react";
import { Input, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useResponsive from "../../utils/useResponsive";
import Table from "../Table/Table";
import MobileData from "../MobileData/MobileData";
import Pagination from "../Pagination/Pagination";
import TaskManagementFilters from "../../pages/workspace/task-management/task-collection/CollectionEnumerators/TaskManagementFilters";

import "./TableData.scss";

const TableData = React.memo(
   ({
      dataSource,
      updatedColumns,
      loadData,
      handleSearch,
      onComplete,
      onReject,
      goToSurvey,
      pagination,
      handlePagination = () => {},
      showFilters,
      filterValue,
      onSelect,
      handleShowFilters,
      handleClearFilters
   }) => {
      const { mobile } = useResponsive();

      return (
         <div className="table-data tasksManagementPage">
            <Row justify="space-between" gutter={[8, 16]} className="searchTasks">
               <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <Input
                     placeholder="Search Task"
                     onChange={handleSearch}
                     suffix={<SearchOutlined />}
                  />
               </Col>
               {showFilters && (
                  <Col xs={24} sm={6} md={6} lg={6} xl={3} className="showFilters">
                     <span onClick={handleClearFilters}>Clear filters</span>
                  </Col>
               )}
               <Col xs={24} sm={6} md={6} lg={6} xl={3} className="showFilters">
                  <span onClick={handleShowFilters}>
                     {showFilters ? "Hide filters" : "Show filters"}
                  </span>
               </Col>
               {showFilters && (
                  <TaskManagementFilters
                     enumeratorsPage
                     onSelectChange={onSelect}
                     filterValue={filterValue}
                  />
               )}
            </Row>
            <Row justify="space-between" gutter={[8, 16]}>
               <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <h3>Total size: {pagination.total}</h3>
               </Col>
            </Row>
            <>
               {mobile ? (
                  <>
                     <MobileData
                        filteredDataSource={dataSource}
                        onComplete={onComplete}
                        onReject={onReject}
                        goToSurvey={goToSurvey}
                     ></MobileData>
                     <Pagination
                        pagination={pagination}
                        handlePagination={handlePagination}
                     ></Pagination>
                  </>
               ) : (
                  <Table
                     dataSource={dataSource}
                     columns={updatedColumns}
                     pagination={pagination}
                     handlePagination={handlePagination}
                     loadData={loadData}
                     isTasksMainPage
                  ></Table>
               )}
            </>
         </div>
      );
   }
);

export default TableData;
