import React, {useState} from "react";
import { Modal, Form, Input, Button } from "antd";
import "./OrganizationManagement.scss";
import { useSelector } from "react-redux"; 
import { Row, Col, notification } from "antd";
import { httpService } from "../../../base/services/httpService.service";
import AuthService from "../../../base/services/authentication.service";

const getUser = (state) => state.user;


const LoginInfoModal = ({ visible, handleOk, handleCancel }) => {
    const useremail = AuthService.getUser()?.email;
    const [api, contextHolder] = notification.useNotification();
    const [load, setLoad] = useState(false);


    const openNotificationWithIcon = (type) => {
      const messages = {
         success: {
            message: "Password Update Successful",
            description: "Your password has been updated successfully.",
         },
         error: {
            message: "Password Update Failed",
            description: "There was an error updating the password. Please try again.",
         },
      };

      api[type]({
         message: messages[type].message,
         description: messages[type].description,
      });
   };


    const { user } = useSelector((state) => {
      return {
          user: getUser(state),
      };
  });


  const onFinish = async (values) => {
   setLoad(true);
   httpService.post("/account/changePassword", {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
   }, (res) => {
      if (res?.isSuccess) {
         openNotificationWithIcon("success");
         setTimeout(() => {
            setLoad(false);
            handleOk();
         }, 1500);
      } else if (res?.errors) {
         openNotificationWithIcon("error");
         setLoad(false); 
      }
   });
};

   return (
      <Modal visible={visible} width={800} closable={false} onOk={handleOk} onCancel={handleCancel}>
          {contextHolder}
         <Row gutter={16}>
            <Col span={12}>
               {" "}
               <h1 className="modal-heading">Login Information</h1>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
               {" "}
               <Button type="text" onClick={handleCancel}>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="19"
                     height="18"
                     viewBox="0 0 19 18"
                     fill="none"
                  >
                     <path
                        d="M17.1855 1.16675L1.18555 16.9644"
                        stroke="#4158CF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                     <path
                        d="M1.18555 1.16675L17.1855 16.9644"
                        stroke="#4158CF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                     />
                  </svg>
               </Button>
            </Col>
         </Row>
         <Form
            name="loginInfoForm"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
         >
            <Row gutter={16}>
               <Col span={24} style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <span className="label-text">Username</span>
                  <br />
                  <span className="login-information-email">{useremail}</span>
               </Col>
            </Row>
            <Row gutter={16}>
            <Col span={24}>
                  <Form.Item
                     label={<span className="label-text">Old Password</span>}
                     name="oldPassword"
                     rules={[{ required: true, message: "Please enter your old password!" }]}
                  >
                     <Input.Password placeholder="Type your old password" />
                  </Form.Item>
               </Col>
               <Col span={24}>
                  <Form.Item
                     label={<span className="label-text">New Password</span>}
                     name="newPassword"
                     rules={[{ required: true, message: "Please enter your new password!" }]}
                  >
                     <Input.Password placeholder="Type your new password" />
                  </Form.Item>
               </Col>
               <Col span={24}>
                  <Form.Item
                     label={<span className="label-text">Confirm Password</span>}
                     name="confirmPassword"
                     dependencies={["newPassword"]}
                     rules={[
                        {
                           required: true,
                           message: "Please confirm your password!"
                        },
                        ({ getFieldValue }) => ({
                           validator(_, value) {
                              if (!value || getFieldValue("newPassword") === value) {
                                 return Promise.resolve();
                              }
                              return Promise.reject(new Error("The two passwords do not match!"));
                           }
                        })
                     ]}
                  >
                     <Input.Password placeholder="Confirm your new password" />
                  </Form.Item>

                  <span className="password-list-text">
                     Your new password must contain at least:
                  </span>
                  <br />
                  <span className="password-list-text">One uppercase character</span>
                  <br />
                  <span className="password-list-text">One lowercase character</span>
                  <br />
                  <span className="password-list-text">One number</span>
               </Col>
            </Row>
            <hr />
            <Form.Item>
               <Row gutter={16}>
                  <Col xs={24} sm={5}>
                     {" "}
                     <Button type="primary" htmlType="submit" className="kapSurveyPrimaryButton">
                        Update info
                     </Button>
                  </Col>
                  <Col xs={24} sm={19}>
                     {" "}
                     <Button
                        type="default"
                        className="org-mng-cancel-button"
                        onClick={handleCancel}
                        style={{ height: "44px" }}
                     >
                        Cancel
                     </Button>
                  </Col>
               </Row>
            </Form.Item>
         </Form>
      </Modal>
   );
};

export default LoginInfoModal;
