import React from "react";
import { Button, Col, Row } from "antd";
import BasicModal from "../../../../ui/BasicModal/BasicModal";
import useReactivatedAccountModal from "../../../../base/hooks/useReactivatedAccountModal";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check-icon.svg";

const ReactivatedModal = () => {
   const { reactivatedAccountModal, removeLocalStorageKey } = useReactivatedAccountModal();

   return (
      <>
         {reactivatedAccountModal && (
            <BasicModal
               isModalOpen={reactivatedAccountModal}
               handleCancel={removeLocalStorageKey}
               width={400}
               footerButtonCustomStyle
            >
               <div className="reactivated-modal">
                  <CheckIcon style={{ width: "100%" }} />
                  <h2>Your account has been activated!</h2>
                  <span>You're now ready to explore your projects.</span>
               </div>
               <Row gutter={16} className="mt-8" justify="center" align="middle">
                  <Col>
                     <Button className="primaryButton" onClick={removeLocalStorageKey}>
                        Go to the main dashboard
                     </Button>
                  </Col>
               </Row>
            </BasicModal>
         )}
      </>
   );
};

export default ReactivatedModal;
