import React from "react";
const CookiePolicy = () => {
   return (
      <div className="d-flex flex-column" style={{ gap: "16px" }}>
         <p style={{ fontSize: "22px", color: "#003DAA", fontWeight: 600 }}>Cookie Policy</p>
         <div className="d-flex flex-column" style={{ gap: "30px" }}>
            <div className="d-flex flex-column">
               <p className="mb-6">These Terms of Service were last updated on March 4, 2024.</p>
               <p>
                  We use cookies to help improve your experience of our websites at{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.i-aps.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://www.i-aps.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kaptechgroup.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://kaptechgroup.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.kapsurvey.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://www.kapsurvey.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.kaporg.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://www.kaporg.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kapinsights.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://kapinsights.com
                  </a>{" "}
                  and{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kapcourse.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://kapcourse.com
                  </a>
                  . This cookie policy is part of International Advisory, Products and Systems Ltd's
                  privacy policy. It covers the use of cookies between your device and our sites.
               </p>
               <p>
                  We also provide basic information on third-party services we may use, who may also
                  use cookies as part of their service. This policy does not cover their cookies.
               </p>
               <p>
                  If you don’t wish to accept cookies from us, you should instruct your browser to
                  refuse cookies from
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.i-aps.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://www.i-aps.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kaptechgroup.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://kaptechgroup.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.kapsurvey.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://www.kapsurvey.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.kaporg.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://www.kaporg.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kapinsights.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://kapinsights.com
                  </a>{" "}
                  and{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kapcourse.com"}
                     style={{ textDecoration: "none", color: "#F58F45" }}
                  >
                     https://kapcourse.com
                  </a>
                  . In such a case, we may be unable to provide you with some of your desired
                  content and services.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>What is a cookie?</h4>
               <p>
                  A cookie is a small piece of data that a website stores on your device when you
                  visit. It typically contains information about the website itself, a unique
                  identifier that allows the site to recognise your web browser when you return,
                  additional data that serves the cookie’s purpose, and the lifespan of the cookie
                  itself.
               </p>
               <p>
                  Cookies are used to enable certain features (e.g. logging in), track site usage
                  (e.g. analytics), store your user settings (e.g. time zone, notification
                  preferences), and to personalize your content (e.g. advertising, language).
               </p>
               <p>
                  Cookies set by the website you are visiting are usually referred to as first-party
                  cookies. They typically only track your activity on that particular site.
               </p>
               <p>
                  Cookies set by other sites and companies (i.e. third parties) are called
                  third-party cookies They can be used to track you on other websites that use the
                  same third-party service.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>
                  Types of cookies and how we use them
               </h4>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Essential cookies</h4>
               <p>
                  Essential cookies are crucial to your experience of a website, enabling core
                  features like user logins, account management, shopping carts, and payment
                  processing.
               </p>
               <p>We use essential cookies to enable certain functions on our websites.</p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Performance cookies</h4>
               <p>
                  Performance cookies track how you use a website during your visit. Typically, this
                  information is anonymous and aggregated, with information tracked across all site
                  users. They help companies understand visitor usage patterns, identify and
                  diagnose problems or errors their users may encounter, and make better strategic
                  decisions in improving their audience’s overall website experience. These cookies
                  may be set by the website you’re visiting (first-party) or by third-party
                  services. They do not collect personal information about you.
               </p>
               <p>We use performance cookies on our sites.</p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Functionality cookies</h4>
               <p>
                  Functionality cookies are used to collect information about your device and any
                  settings you may configure on the website you’re visiting (like language and time
                  zone settings). With this information, websites can provide you with customized,
                  enhanced, or optimized content and services. These cookies may be set by the
                  website you’re visiting (first-party) or by third-party services.
               </p>
               <p>We use functionality cookies for selected features on our sites.</p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>
                  Targeting/advertising cookies
               </h4>
               <p>
                  Targeting/advertising cookies help determine what promotional content is most
                  relevant and appropriate to you and your interests. Websites may use them to
                  deliver targeted advertising or limit the number of times you see an
                  advertisement. This helps companies improve the effectiveness of their campaigns
                  and the quality of content presented to you. These cookies may be set by the
                  website you’re visiting (first-party) or by third-party services.
                  Targeting/advertising cookies set by third-parties may be used to track you on
                  other websites that use the same third-party service.
               </p>
               <p>We use targeting/advertising cookies on our sites.</p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>
                  How Can You Control Our Website's Use of Cookies?
               </h4>
               <p>
                  You have the right to decide whether to accept or reject cookies on our websites.
                  You can manage your cookie preferences in our Cookie Consent Manager. The Cookie
                  Consent Manager allows you to select which categories of cookies you accept or
                  reject. Essential cookies cannot be rejected as they are strictly necessary to
                  provide you with the services on our websites.
               </p>
               <p>
                  You may also be able to set or amend your cookie preferences by managing your web
                  browser settings. As each web browser is different, please consult the
                  instructions provided by your web browser (typically in the "help" section). If
                  you choose to refuse or disable cookies you may still use our websites, though
                  some of their functionality may not be available to you.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>
                  How Often Will We Update This Cookie Policy?
               </h4>
               <p>
                  We may update this Cookie Policy from time to time in order to reflect any changes
                  to the cookies and related technologies we use, or for other operational, legal or
                  regulatory reasons.
               </p>
               <p>
                  Each time you use our websites, the current version of the Cookie Policy will
                  apply. When you use our websites, you should check the date of this Cookie Policy
                  (which appears at the top of this document) and review any changes since the last
                  version.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>
                  Where Can You Obtain Further Information?
               </h4>
               <p>
                  For any questions or concerns regarding our Cookie Policy, you may contact us
                  using the following details:
               </p>
               <p className="m-0" style={{ fontSize: "14px", fontWeight: 600, color: "#F58F45" }}>
                  Customer Service
               </p>
               <a href="mailto:info@kaptechgroup.com" style={{ color: "#F58F45", padding:"0px 51px" }}>info@kaptechgroup.com</a>
            </div>
         </div>
      </div>
   );
};

export default CookiePolicy;
