import React from "react";
import style from "./Section.module.scss";

function Section() {
   return (
      <div className={style.section}>
         <span style={{ color: "#ccc" }}>Section</span>
      </div>
   );
}

export default Section;
