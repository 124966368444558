import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { DeleteModal } from "./DeleteModal";

function DropDownComp({ handleDelete, id }) {
   const [open, setOpen] = useState(false);
   const showModal = () => {
      setOpen(true);
   };

   const items = [
      {
         key: "1",
         label: (
            <Link to={"/workspace/edit/" + id}>
               <AiOutlineEdit /> Edit workspace
            </Link>
         )
      },

      {
         key: "2",
         danger: true,
         label: (
            <a target="_blank" rel="noopener noreferrer" onClick={showModal}>
               <BsTrash /> Remove workspace
            </a>
         )
      }
   ];
   return (
      <>
         <Dropdown
            trigger={["click"]}
            menu={{
               items
            }}
         >
            <a onClick={(e) => e.preventDefault()}>
               ...
               <DownOutlined />
            </a>
         </Dropdown>

         <DeleteModal
            open={open}
            setOpen={setOpen}
            showModal={showModal}
            handleDelete={handleDelete}
            id={id}
         />
      </>
   );
}

export { DropDownComp };
