import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Toast from "../../../base/components/Toast";
import { httpService } from "../../../base/services/httpService.service";
import AuthService from "../../../base/services/authentication.service";
import { SessionService } from "../../../base/services/session.service";

export default function CheckoutForm({ handleOk = () => {}, handleCardDetails = () => {} }) {
   const stripe = useStripe();
   const elements = useElements();
   const [message, setMessage] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const organizationId = AuthService.getOrganizationId();

   // useEffect(() => {
   //    if (!stripe) {
   //       return;
   //    }

   //    // const clientSecret = new URLSearchParams(window.location.search).get(
   //    //    "payment_intent_client_secret"
   //    // );

   //    if (!clientSecret) {
   //       return;
   //    }

   //    // stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
   //    // stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
   //    //    console.log("setupIntent ::::::", paymentIntent);
   //    //    switch (paymentIntent.status) {
   //    //       case "succeeded":
   //    //          setMessage("Payment succeeded!");
   //    //          break;
   //    //       case "processing":
   //    //          setMessage("Your payment is processing.");
   //    //          break;
   //    //       case "requires_payment_method":
   //    //          // setMessage("Your payment was not successful, please try again.");
   //    //          break;
   //    //       default:
   //    //          setMessage("Something went wrong.");
   //    //          break;
   //    //    }
   //    // });
   // }, [stripe, clientSecret]);

   const handleSubmit = async (e) => {
      e.preventDefault();

      if (!stripe || !elements) {
         // Stripe.js hasn't yet loaded.
         // Make sure to disable form submission until Stripe.js has loaded.
         return;
      }

      setIsLoading(true);

      try {
         const paymentMethod = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
         });
         if (paymentMethod.paymentMethod.id) {
            const params = new URLSearchParams({
               paymentMethodId: paymentMethod.paymentMethod.id,
               organizationId: organizationId ?? SessionService.getItem("currentOrgId")
            });
            httpService
               .get(`/Payment/addPaymentMethodToOrganization?${params.toString()}`, (response) => {
                  if (!response) return;
                  handleCardDetails();
                  handleOk();
                  setIsLoading(false);
               })
               .catch((error) => {
                  Toast.warning(error.response.data.message);
                  setIsLoading(false);
               });
         } else {
            Toast.warning(message);
         }
      } catch (error) {
         if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
            Toast.warning(message);
         } else {
            setMessage("An unexpected error occurred.");
            Toast.warning(message);
         }
         setIsLoading(false);
      }
   };

   const paymentElementOptions = {
      layout: "tabs"
   };

   return (
      <div className="stripe-form">
         <form id="payment-form" onSubmit={handleSubmit}>
            {/* <PaymentElement id="payment-element" options={paymentElementOptions} /> */}
            <CardElement
               id="payment-element"
               options={(paymentElementOptions, { hidePostalCode: true })}
            />
            <button disabled={isLoading || !stripe || !elements} id="submit">
               <span id="button-text">
                  {isLoading ? <div className="spinner" id="spinner"></div> : "Add payment method"}
               </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
         </form>
      </div>
   );
}
