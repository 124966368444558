import React from "react";
import { Modal } from "antd";
import "./BasicModal.scss";

const BasicModal = ({
   isModalOpen,
   handleOk,
   handleCancel,
   children,
   width = 600,
   footerButtonCustomStyle = false
}) => {
   return (
      <Modal
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         prefixCls="basic-modal"
         className={`${footerButtonCustomStyle ? "basic-modal-custom-style" : ""}`}
         width={width}
         centered
      >
         {children}
      </Modal>
   );
};

export default BasicModal;
