import { Button, Form, Input, Select, notification, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { httpService } from "../../../base/services/httpService.service";
import { useAuthSore } from "../authStore";
import { RegisterHeader } from "./components/createProfile/RegisterHeader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { SessionService } from "../../../base/services/session.service";
import "../style.scss";
import RegisterStep2 from "./RegisterStep2";
import RegisterStep3 from "./RegisterStep3";
import { EyeOutlined } from "@ant-design/icons";
import { Field } from "formik";
import TermsConditionsModal from "../../../components/TermsConditionsModal";
import ConfirmationCodeModal from "./Confirmation";

function Registration() {
   //zustand
   const setRegistStep = useAuthSore((state) => state.setRegistStep);
   const setcurrentOrgId = useAuthSore((state) => state.setcurrentOrgId);
   const [load, setLoad] = useState(false);

   const navigate = useNavigate();
   //zustand
   const { Option } = Select;
   const [form] = Form.useForm();
   const [step, setStep] = useState(0);
   const [formData, setFormData] = useState({});

   const [userInformation, setUserInformation] = useState(null);
   const [countries, setCountries] = useState();
   const [organizationType, setOrganizationType] = useState();
   const [shouldDisable, setShouldDisable] = useState(false);

   const [isConfirmed, setIsConfirmed] = useState(false);
   const [isModaOpen, setIsModaOpen] = useState(false);
   const [acceptTerms, setAcceptTerms] = useState(false);
   const [modalVisible, setModalVisible] = useState(false);
   const openTermsModal = () => {
      setModalVisible(!modalVisible);
   };

   const handleCheckboxChange = (e) => {
      setAcceptTerms(e.target.checked);
   };

   const onFinish = (values) => {
      setUserInformation(values);
      setLoad(true);

      httpService.post(
         "/Account/register",
         {
            adminFullName: values.fullname,
            roleWithinOrganization: values.organizationRole,
            phoneNumber: values.phone,
            email: values.email,
            password: values.password,
            confirmPassword: values.confirm,
            organizationName: values.organizationName,
            typeId: values.typeOrganization,
            countryId: values.country
         },
         (res) => {
            // if (!res?.isSuccess && res.statusCode == 204) {
            saveSessions(values, res);
            openNotificationWithIcon("warning", "Warning", "Please confirm the code in your email");
            setIsModaOpen(true);
            setLoad(false);
            // }
            // else if (res?.isSuccess) {
            //  openNotificationWithIcon("success", "Success", "Account created successfully");
            //  openNotificationWithIcon("success", "Success", "Confirmation code sent successfully");
            //   saveSessions(values, res);
            // } else {
            //    setLoad(false);
            // }
         },
         (errorData) => {
            // openNotificationWithIcon("error", "Error", "");

            setLoad(false);
         }
      );
   };

   const openConfirmation = () => {
      setIsModaOpen(true);
   };

   const handleCloseModal = () => {
      setIsModaOpen(false);
   };

   const confirmCode = () => {
      openNotificationWithIcon("success", "Success", "Account created successfully");
      setStep(1);
   };

   const [api, contextHolder] = notification.useNotification();

   const openNotificationWithIcon = (type, msg, desc) => {
      api[type]({
         message: msg,
         description: desc
      });
   };
   const cancelForm = (e) => {
      e.preventDefault();
      setFormData({});
      SessionService.clear();
      navigate("/auth/login");
   };

   const goToPayment = () => {
      setStep(2);
   };

   const goToStep = (step) => {
      setStep(step);
   };

   const saveSessions = (values, res) => {
      setLoad(false);
      setcurrentOrgId(res.data.organizationId);
      SessionService.setItem("currentOrgId", res.data.organizationId);
      SessionService.setItem("currentUserId", res.data.userId);
      SessionService.setItem("registeringAccount", {
         fullname: values.fullname,
         organizationRole: values.organizationRole,
         phone: values.phone,
         email: values.email,
         password: values.password,
         confirm: values.confirm,
         organizationName: values.organizationName,
         typeOrganization: values.typeOrganization,
         country: values.country
      });
   };

   useEffect(() => {
      if (window.Stripe) {
         const stripe = window.Stripe("SİZİN_YAYINLANABİLİR_ANAHTARINIZ");
         console.log(stripe);
      }
      // if (SessionService.getItem("currentOrgId") && step === 0) {
      //    setStep(1);
      // }
      setRegistStep(step);

      if (SessionService.getItem("registeringAccount")) {
         const data = SessionService.getItem("registeringAccount");
         setFormData(data);
         console.log(data);
         form.setFieldsValue(data);
         setShouldDisable(true);
      }
   }, [step]);

   useEffect(() => {
      httpService.get(`/Languages/getAllCountries`, (res) => {
         try {
            const sortedCountries = res.data.sort((a, b) => {
               return a.name.localeCompare(b.name);
            });
            setCountries(
               sortedCountries.map((country) => ({
                  value: country.id,
                  label: country.name
               }))
            );
         } catch (error) {
            console.log(error);
         }
      });

      httpService.get(`/Account/getOrganizationTypes`, (res) => {
         try {
            setOrganizationType(res.data);
         } catch (error) {
            console.log(error);
         }
      });

      if (SessionService.getItem("isOnSecondStep")) {
         SessionService.setItem("isAccountConfirmed", false);
         setStep(1);
      }
   }, []);

   return (
      <>
         {contextHolder}
         <div className="create-header">
            <RegisterHeader step={step} />
         </div>

         <div className="register-page">
            <ConfirmationCodeModal
               isModaOpen={isModaOpen}
               handleClose={handleCloseModal}
               confirmCode={confirmCode}
            />
            <Form
               onFinish={onFinish}
               className="register-form"
               form={form}
               name="basic"
               autoComplete="off"
            >
               {step === 0 && (
                  <>
                     <div className="create-section">
                        <div className="register-adminstrator-information">
                           <div className="left-block">
                              {" "}
                              <h1 className="heading-create-account">Create an account</h1>
                              <h3 className="paragraph-create-account">Login details</h3>
                              <Form.Item
                                 label="Email*"
                                 name="email"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input your Email!"
                                    },
                                    {
                                       type: "email",
                                       message: "The Email field is not a valid e-mail address."
                                    }
                                 ]}
                              >
                                 <Input
                                    disabled={shouldDisable}
                                    placeholder="Type your email address..."
                                 />
                              </Form.Item>
                              <Form.Item
                                 label="Password*"
                                 name="password"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input your password!"
                                    },
                                    {
                                       pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{6,}$/,
                                       message:
                                          "Password must include uppercase, lowercase, number, and special character and minimum length of password - 6"
                                    }
                                 ]}
                              >
                                 <Input.Password
                                    disabled={shouldDisable}
                                    placeholder="Set up your account's password..."
                                    suffix={<EyeOutlined />}
                                 />
                              </Form.Item>
                              <Form.Item
                                 label="Confirm Password*"
                                 name="confirm"
                                 dependencies={["password"]}
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please confirm your password!"
                                    },
                                    ({ getFieldValue }) => ({
                                       validator(_, value) {
                                          if (!value || getFieldValue("password") === value) {
                                             return Promise.resolve();
                                          }
                                          return Promise.reject(
                                             new Error(
                                                "Oops! It seems like there's a mismatch between the passwords you entered."
                                             )
                                          );
                                       }
                                    })
                                 ]}
                              >
                                 <Input.Password
                                    disabled={shouldDisable}
                                    placeholder="Confirm your password..."
                                 />
                              </Form.Item>
                              <h3 className="paragraph-create-account">Organization details</h3>
                              <Form.Item
                                 label="Full name*"
                                 name="fullname"
                                 rules={[
                                    {
                                       required: true,
                                       message: "Please input your Full name!"
                                    }
                                 ]}
                              >
                                 <Input
                                    disabled={shouldDisable}
                                    placeholder="Enter your full name..."
                                 />
                              </Form.Item>
                              <Form.Item
                                 label="Phone Number*"
                                 name="phone"
                                 rules={[
                                    {
                                       pattern: /^\d+$/,
                                       message: "Phone number can only contain numbers"
                                    }
                                 ]}
                              >
                                 {/* <Input disabled={shouldDisable} /> */}
                                 <PhoneInput
                                    country={"us"}
                                    rules={[
                                       {
                                          required: true,
                                          message:
                                             "Oops! It seems like there's an issue with the phone number you entered. Please ensure it follows the correct format, such as e.x(555) 123-4567, and try again."
                                       }
                                    ]}
                                 />
                              </Form.Item>
                              <Form.Item label="Role within organization" name="organizationRole">
                                 <Input
                                    disabled={shouldDisable}
                                    placeholder="Specify your role within the organization..."
                                 />
                              </Form.Item>
                              <h3 className="paragraph-create-account">Company Information</h3>
                              <div className="company-form-section" style={{ gap: "6%" }}>
                                 <div className="mb-3">
                                    <Form.Item
                                       name="typeOrganization"
                                       label="Type of organization*"
                                       rules={[
                                          {
                                             required: true,
                                             message: "Please input your Type of organization!"
                                          }
                                       ]}
                                    >
                                       <Select
                                          disabled={shouldDisable}
                                          placeholder="--Select an option--"
                                       >
                                          {organizationType?.map((item, i) => (
                                             <Option key={i} value={item.id}>
                                                {item.name}
                                             </Option>
                                          ))}
                                       </Select>
                                    </Form.Item>
                                    <Form.Item
                                       name="country"
                                       label="Country*"
                                       rules={[
                                          {
                                             required: true,
                                             message: "Please input your Country!"
                                          }
                                       ]}
                                    >
                                       <Select
                                          disabled={shouldDisable}
                                          showSearch
                                          placeholder="--Select an option--"
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                             option.label
                                                .toLowerCase()
                                                .startsWith(input.toLowerCase())
                                          }
                                          filterSort={(optionA, optionB) =>
                                             optionA.label
                                                .toLowerCase()
                                                .localeCompare(optionB.label.toLowerCase())
                                          }
                                          options={countries}
                                       />
                                    </Form.Item>
                                 </div>
                              </div>
                              <div>
                                 <Form.Item
                                    name="organizationName"
                                    label="Organization's name*"
                                    rules={[
                                       {
                                          required: true,
                                          message: "Please input your Organization name!"
                                       }
                                    ]}
                                 >
                                    <Input
                                       disabled={shouldDisable}
                                       placeholder="Enter your organization's name..."
                                    />
                                 </Form.Item>
                              </div>
                           </div>
                        </div>{" "}
                        <div>
                           <div style={{ display: "flex", alignItems: "flex-start" }}>
                              <Checkbox
                                 checked={acceptTerms}
                                 onChange={handleCheckboxChange}
                                 className="m-1"
                                 required
                              />
                              <div style={{ marginTop: "2px" }}>
                                 <p>
                                    I have read and agreed with the{" "}
                                    <Link
                                       to="#"
                                       onClick={openTermsModal}
                                       rel="noopener noreferrer"
                                       style={{
                                          color: "#5492FF",
                                          textDecoration: "underline",
                                          margin: "0 4px"
                                       }}
                                    >
                                       Terms of Service, Privacy Policy
                                    </Link>
                                    and{" "}
                                    <Link
                                       to="#"
                                       onClick={openTermsModal}
                                       rel="noopener noreferrer"
                                       style={{
                                          color: "#5492FF",
                                          textDecoration: "underline",
                                          margin: "0 4px"
                                       }}
                                    >
                                       Cookie Policy.
                                    </Link>
                                 </p>
                              </div>
                           </div>
                           {/* <Checkbox /> */}
                        </div>{" "}
                        <div className="register-button-group" style={{ marginTop: "5%" }}>
                           {/* <button type="button" onClick={(e) => cancelForm(e)}>
                           Cancel
                        </button> */}
                           <TermsConditionsModal visible={modalVisible} onCancel={openTermsModal} />

                           {!SessionService.getItem("registeringAccount") &&
                           !SessionService.getItem("isAccountConfirmed") ? (
                              <Form.Item>
                                 <Button
                                    htmlType="submit"
                                    className="kapSurveyPrimaryButton"
                                    disabled={load}
                                    loading={load}
                                 >
                                    Continue
                                 </Button>
                              </Form.Item>
                           ) : SessionService.getItem("isAccountConfirmed") ? (
                              <Form.Item>
                                 <Button
                                    onClick={() => setStep(1)}
                                    className="kapSurveyPrimaryButton"
                                 >
                                    Select a plan
                                 </Button>
                              </Form.Item>
                           ) : (
                              <Form.Item>
                                 <Button
                                    onClick={() => openConfirmation()}
                                    className="kapSurveyPrimaryButton"
                                 >
                                    Continue
                                 </Button>
                              </Form.Item>
                           )}
                        </div>
                     </div>
                  </>
               )}
            </Form>

            {step === 1 && <RegisterStep2 goToStep={goToStep} goToPayment={goToPayment} />}
            {step === 2 && <RegisterStep3 goToStep={goToStep} />}
         </div>
      </>
   );
}

export default injectIntl(Registration);
