import React from "react";
import AuthService from "../../../base/services/authentication.service";
import { Button } from "antd";
import Icon from "@ant-design/icons";

import "./DeletedModalButton.scss";

const DeletedModalButton = ({ allowedRoles, onClick, style, children, icon }) => {
   const checkAuthorization = allowedRoles ? AuthService.isAuthorized(allowedRoles) : true;
   return checkAuthorization ? (
      <Button
         prefixCls="deleted-modal-button"
         style={{
            ...style
         }}
         onClick={onClick}
         icon={
            icon ? (
               icon
            ) : (
               <Icon
                  component={() => (
                     <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M0.884033 4.04796H14.5983M4.88403 4.04796V3.47653C4.88403 2.71877 5.18505 1.99204 5.72087 1.45622C6.25668 0.920404 6.98342 0.619385 7.74118 0.619385C8.49894 0.619385 9.22567 0.920404 9.76149 1.45622C10.2973 1.99204 10.5983 2.71877 10.5983 3.47653V4.04796M6.02689 7.4782V12.0514M9.45546 7.4782V12.0514M2.59832 4.04796H12.884V14.3337C12.884 14.6368 12.7636 14.9275 12.5493 15.1418C12.335 15.3561 12.0443 15.4765 11.7412 15.4765H3.74118C3.43807 15.4765 3.14738 15.3561 2.93305 15.1418C2.71873 14.9275 2.59832 14.6368 2.59832 14.3337V4.04796Z"
                           stroke="#4158CF"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        />
                     </svg>
                  )}
               />
            )
         }
      >
         <span className="deleted-modal-button_label">{children}</span>
      </Button>
   ) : null;
};

export default DeletedModalButton;
