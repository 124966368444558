import React, { useCallback, useEffect, useState } from "react";
import PersonalInformation from "./PersonalInformation";
import { Button, Col, Row, notification } from "antd";
import DeactivateAccountModal from "./DeactivateAccountModal";
import { usePersonalDataStore, useProfileStore } from "../../pages/auth/authStore";
import BasicModal from "../../ui/BasicModal/BasicModal";
import FileUpload from "../../ui/Uploaders/FileUpload";
import { httpService } from "../../base/services/httpService.service";
import { StatusCodesEnum } from "../../utils/enums";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import AuthService from "../../base/services/authentication.service";
import { withErrorBoundary } from "../ErrorBoundary/withErrorBoundary";

import "./index.scss";

const ProfileInformation = () => {
   const user = AuthService.getUser();
   const organizationId = AuthService.getOrganizationId();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [openPhotoModal, setOpenPhotoModal] = useState(false);
   const [fileUrl, setFileUrl] = useState("");
   const { userLetter } = useProfileStore();
   const { formData, getUserAndOrganizationInfo } = useProfileStore();
   const personalData = usePersonalDataStore((state) => state.personalData);
   const setPersonalData = usePersonalDataStore((state) => state.setPersonalData);
   const [userData, setUserData] = useState(false);

   const fetchData = async () => {
      await getUserAndOrganizationInfo(organizationId, user.id);
   };

   const handleUserData = () => {
      setUserData((prev) => !prev);
      if (openPhotoModal) {
         setOpenPhotoModal(false);
      }
   };

   const getPreviewPhoto = useCallback(() => {
      if (!formData || !formData.userProfilePictureUrl) return;
      let correctedFilePath = formData.userProfilePictureUrl;
      correctedFilePath = correctedFilePath.replace(/\\202/g, "\\x82");
      const payload = [
         {
            fileKey: correctedFilePath
         }
      ];
      httpService
         .post(
            `/FileData/getFileUrlByFileKeyAndEntityId?platformId=006d1c1e-81b6-4d87-b4b0-f1850543f2cc`,
            payload,
            (response) => {
               const isSuccess = response.statusCode === StatusCodesEnum.OK;
               if (isSuccess && response && response.data[0].fileUrl) {
                  setFileUrl(response.data[0].fileUrl);
                  AuthService.setUser({
                     ...user,
                     profilePictureUrl: response.data[0].fileUrl
                  });
                  setPersonalData({
                     ...personalData,
                     profilePictureUrl: response.data[0].fileUrl
                  });
               } else {
                  notification.warning({
                     type: "warning",
                     message:
                        response.data.message || "Something went wrong! Please try again later."
                  });
               }
            }
         )
         .catch((error) => {
            notification.error({
               message: error.response?.data?.message || "Unable to display a preview of the image"
            });
         });
   }, [formData]);

   useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userData, organizationId]);

   useEffect(() => {
      getPreviewPhoto();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [formData]);

   const handleAccountModal = () => {
      setIsModalOpen((prev) => !prev);
   };

   return (
      <div className="profileInformation accountSettings">
         <div className="userLogo">
            <div
               className="user-profile-logo user-profile-position"
               onClick={() => setOpenPhotoModal(true)}
            >
               {formData.userProfilePictureUrl ? (
                  <>
                     <img
                        width={120}
                        height={120}
                        src={fileUrl}
                        alt=""
                        style={{
                           borderRadius: "100px",
                           border: "4px solid white"
                        }}
                        onClick={() => setOpenPhotoModal(true)}
                     />
                  </>
               ) : (
                  <>
                     <span>{userLetter}</span>
                  </>
               )}
               <span className="user-profile-logo--icon">
                  <EditIcon />
               </span>
            </div>
         </div>
         <div className="profileContent">
            <Row gutter={16} align="middle">
               <Col xs={24} sm={12} lg={12} xl={12}>
                  <p className="title">Profile information</p>
               </Col>
               <Col xs={24} sm={12} lg={12} xl={12} justify="flex-end">
                  <Button type="default" className="defaultButton" onClick={handleAccountModal}>
                     Delete or deactivate account
                  </Button>
               </Col>
            </Row>
            <PersonalInformation handleUserData={handleUserData} />
         </div>
         {isModalOpen && (
            <DeactivateAccountModal
               isModalOpen={isModalOpen}
               handleAccountModal={handleAccountModal}
            ></DeactivateAccountModal>
         )}
         {openPhotoModal && (
            <BasicModal
               isModalOpen={openPhotoModal}
               handleCancel={() => setOpenPhotoModal(false)}
               footerButtonCustomStyle
            >
               <FileUpload
                  fileUrl={fileUrl}
                  handleFileUrl={handleUserData}
                  handleCancel={() => setOpenPhotoModal(false)}
               ></FileUpload>
            </BasicModal>
         )}
      </div>
   );
};

export default withErrorBoundary(ProfileInformation);
