import { LocalStorageService } from "./local-storage.service";
import { SessionService } from "./session.service";
import { jwtDecode } from "jwt-decode";

//will be removed after test
// const testToken = "";

const AuthService = {
   getAccessToken() {
      return LocalStorageService.getItem("atoken") || null; // will be null after test
   },

   getRefreshToken() {
      return LocalStorageService.getItem("rtoken") || null; // will be null after test;
   },

   setTokens(aToken, rToken) {
      LocalStorageService.setItem("atoken", aToken);
      LocalStorageService.setItem("rtoken", rToken);
   },

   getTokenExpirationTime() {
      LocalStorageService.getItem("tokenExpire");
   },

   isAuthenticated() {
      const token = this.getAccessToken();
      let decodedToken = token && jwtDecode(token);
      let currentDate = new Date();
      return !(decodedToken?.exp * 1000 < currentDate.getTime() || !token);
   },

   isAuthorized(allowedRoles) {
      const user = this.getUser();
      const roles = JSON.parse(localStorage.getItem("roles"));

      if (!roles) return false;
      let userRoles;

      if (user !== null) {
         userRoles = roles?.map((role) => {
            return role.id;
         });
      } else {
         userRoles = ["-1"];
      }

      const contains = allowedRoles.some((element) => {
         return userRoles.indexOf(element) !== -1;
      });
      return contains;
   },

   setUser(model) {
      LocalStorageService.setItem("user", model);
   },

   getUser() {
      if (this.isAuthenticated()) {
         return LocalStorageService.getItem("user");
      } else {
         return null;
      }
   },
   getOrganizationId() {
      return LocalStorageService.getItem("organizationId");
   },

   logout() {
      LocalStorageService.removeItem("user");
      LocalStorageService.removeItem("atoken");
      LocalStorageService.removeItem("rtoken");
      LocalStorageService.removeItem("orgId");
      LocalStorageService.clear();
      SessionService.clear();
      window.location.href = "/auth/login";
   }
};

export default AuthService;
