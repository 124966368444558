import React, { useState } from "react";
import BasicModal from "../../ui/BasicModal/BasicModal";
import { Radio, Space, Input, Button, Row, Col, Form } from "antd";
import useNotification from "../../base/hooks/useNotification";
import { httpService } from "../../base/services/httpService.service";
const { TextArea } = Input;

const CancelSubscription = ({
   isModalOpen,
   handleAccountModal,
   subscriptionId,
   handleCancelPlan
}) => {
   const { openNotification } = useNotification();
   const [form] = Form.useForm();
   const [value, setValue] = useState(null);
   const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);

   const onChange = (e) => {
      setValue(e.target.value);
   };

   const cancelSubscription = (values) => {
      setPaymentMethodLoading(true);
      const params = new URLSearchParams({
         subscriptionId: subscriptionId,
         cancelSubscriptionAfterPeriodEnds: true,
         reasonOfCancellation: values.reasonOfCancellation,
         customReasonOfCancellation: values.customReasonOfCancellation
      });
      httpService
         .post(`/Payment/cancelStripeSubscription?${params.toString()}`, {}, (response) => {
            if (!response) return;
            handleCancelPlan();
            openNotification("success", "", "Subscription successfully cancelled.");
         })
         .catch((error) => {
            openNotification("error", "", "Something went wrong. Please try again.");
         })
         .finally(() => {
            setPaymentMethodLoading(false);
            handleAccountModal();
         });
   };

   return (
      <BasicModal
         isModalOpen={isModalOpen}
         handleCancel={handleAccountModal}
         footerButtonCustomStyle
      >
         <Form onFinish={cancelSubscription} form={form}>
            <div className="mt-5 mb-8">
               <h4>Cancel your subscription</h4>
            </div>

            <>
               <p className="label-text mt-8 mb-5">
                  We're sorry to see you go! To help us improve, would you mind telling us why
                  you're canceling your subscription?
               </p>
               <Form.Item
                  name="reasonOfCancellation"
                  rules={[{ required: true, message: "Please select an option" }]}
               >
                  <Radio.Group onChange={onChange} value={value}>
                     <Space direction="vertical">
                        <Radio value={1}>I no longer use this service.</Radio>
                        <Radio value={2}>I'm concerned about my privacy.</Radio>
                        <Radio value={3}>I'm not satisfied with the service..</Radio>
                        <Radio value={4}>Other (please specify)</Radio>
                     </Space>
                  </Radio.Group>
               </Form.Item>
               {value === 4 ? (
                  <Form.Item name="value4">
                     <TextArea
                        rows={2}
                        placeholder="Type something here"
                        rules={[{ required: true, message: "Please type something here" }]}
                     />
                  </Form.Item>
               ) : null}
               <>
                  <p className="label-text mt-8">
                     Is there anything we could have done to improve your experience? Please tell us
                     more about why you're canceling your subscription.
                  </p>
                  <Form.Item
                     name="customReasonOfCancellation"
                     rules={[{ required: true, message: "Please type something here" }]}
                  >
                     <TextArea rows={2} placeholder="Type something here" />
                  </Form.Item>
               </>
            </>
            <Row gutter={16} className="mt-8" justify="center" align="middle">
               <Col>
                  <Button
                     className="cancel"
                     onClick={handleAccountModal}
                     disabled={paymentMethodLoading}
                  >
                     Cancel
                  </Button>
               </Col>
               <Col>
                  <Button
                     htmlType="submit"
                     className="primaryButton"
                     loading={paymentMethodLoading}
                  >
                     Continue
                  </Button>
               </Col>
            </Row>
         </Form>
      </BasicModal>
   );
};

export default CancelSubscription;
