import { useEffect, useState } from "react";
import { httpService } from "../../../../base/services/httpService.service";
import { LocalStorageService } from "../../../../base/services/local-storage.service";

const useSurveysByStatus = (enumeratorsPage) => {
   const projectId = LocalStorageService?.getItem("currentProjectID") || "";
   const [responseData, setResponseData] = useState({
      isSuccess: false,
      data: [
         {
            surveys: []
         }
      ],
      errors: []
   });

   const fetchData = (enumeratorsPage, projectId) => {
      const enumeratorsUrl = `/Task/getDataCollectorTasksSurveys?isDeleted=false`;
      const projectSurveysUrl = `/DataCollection/getAllSurveysForProjectByStatus?ProjectId=${projectId}&surveyStatuses=1&surveyStatuses=2&surveyStatuses=3&surveyStatuses=4`;

      const url = enumeratorsPage ? enumeratorsUrl : projectSurveysUrl;

      httpService.get(url, (response) => {
         if (!response) return;
         setResponseData(response);
      });
   };

   useEffect(() => {
      fetchData(enumeratorsPage, projectId);
   }, [enumeratorsPage, projectId]);

   return {
      isSuccess: responseData.isSuccess,
      data: enumeratorsPage ? responseData.data : responseData.data.surveys,
      errors: responseData.errors
   };
};

export default useSurveysByStatus;
