import { useCallback, useEffect, useState } from "react";
import { httpService } from "../../../../base/services/httpService.service";
import { LocalStorageService } from "../../../../base/services/local-storage.service";

const useGetMessagingTaskReceivers = (collectionMessagesPage) => {
   const projectId = LocalStorageService?.getItem("currentProjectID") || "";
   const [responseData, setResponseData] = useState({
      isSuccess: false,
      data: [],
      errors: []
   });

   const fetchData = useCallback(() => {
      const url = `/Task/getMessagingTaskReceivers?ProjectId=${projectId}&isDeleted=false`;

      httpService.get(url, (response) => {
         if (!response) return;
         setResponseData(response);
      });
   }, [projectId]);

   useEffect(() => {
      if (!collectionMessagesPage) return;
      fetchData();
   }, [collectionMessagesPage, fetchData]);

   return {
      isSuccess: responseData.isSuccess,
      data: responseData.data,
      errors: responseData.errors
   };
};

export default useGetMessagingTaskReceivers;
