export const ItemTypesEnum = {
   section: 1,
   note: 43
};

export const ItemTypes = [
   {
      id: 43,
      label: "note"
   }
];

export const InputType = Object.freeze({
   SECTION: 0,
   TEXT: 34,
   SELECT_ONE: 32,
   SELECT_MULTIPLE: 33,
   NUMBER: 35,
   DECIMAL: 36,
   DATE: 37,
   TIME: 38,
   NOTE: 40,
   PHONE_NUMBER: 100,
   DATE_TIME: 104,
   GPS: 105, // not supported yet
   PHOTOGRAPH: 106,
   ACKNOWLEDGE: 112,
   RANGE_QUESTION: 275,
   MATRIX: 277,
   RANKING: 278,
   RATING: 276,
   CALCULATE_QUESTION: 113 // not supported yet
});
