import { Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import { httpService } from "../../../base/services/httpService.service";
import { SessionService } from "../../../base/services/session.service";
import { PlanInformation } from "./components/reviewPay/PlanInformation";
import { PaymentMethod } from "./components/reviewPay/PaymentMethod";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalStorageService } from "../../../base/services/local-storage.service";

function RegisterStep3({ goToStep }) {
   const nav = useNavigate();
   const location = useLocation();
   const [isLoading, setIsLoading] = useState(true);
   const [isPaymentLoading, setIsPaymentLoading] = useState(false);
   const [userInformation, setUserInformation] = useState();
   const [clientSecret, setClientSecret] = useState();
   const getIfIsYearly = LocalStorageService.getItem("isYearly");
   const [createSubscriptionError, setCreateSubscriptionError] = useState(false);

   // const selectedCountry = null;
   // const countryName = null;
   const [countries, setCountries] = useState([]);
   const [orgs, setOrgs] = useState([]);

   const emitStep = (step) => {
      goToStep(step);
   };

   useEffect(() => {
      if (location.pathname === `/auth/payment`) {
         setIsLoading(false);
         return;
      }
      setIsLoading(true);
      httpService
         .post(
            "/payment/stripe/createSubscription",
            {
               organizationId: SessionService.getItem("currentOrgId"),
               packetId: SessionService.getItem("registeringPlan")?.id,
               isMonthlySubscription: !getIfIsYearly
            },
            (res) => {
               SessionService.setItem("subscriptionId", res.data.subscriptionId);
               setClientSecret(res.data.clientSecret);
            }
         )
         .finally(() => {
            setIsLoading(false);
         });
   }, []);

   const handlePayment = () => {
      // nav("/subscription/stripe/");
      if (location.pathname === "/auth/registration") {
         nav("/auth/freeTrial/successful");
      } else {
         setIsPaymentLoading(true);
         httpService.post(
            "/payment/stripe/createSubscription",
            {
               organizationId: SessionService.getItem("currentOrgId"),
               packetId: SessionService.getItem("registeringPlan")?.id,
               isMonthlySubscription: !getIfIsYearly
            },
            (res) => {
               SessionService.setItem("subscriptionId", res.data.subscriptionId);
               setClientSecret(res.data.clientSecret);
               httpService.post(
                  "/payment/stripe/activateSubscription",
                  {
                     stripeSubscriptionId: res.data.subscriptionId,
                     organizationId: SessionService.getItem("currentOrgId")
                  },
                  (res) => {
                     if (res?.isSuccess) {
                        nav("/auth/login");
                        SessionService.clear();
                     } else {
                        notification.warning({
                           message: res?.data.message
                        });
                     }
                     setIsPaymentLoading(false);
                  }
               );
            },
            (error) => {
               if (
                  error.response.data &&
                  error.response.data.errors[0] &&
                  error.response.data.errors[0] ===
                     "Customer does not have a default payment method."
               ) {
                  notification.error({
                     message: `${error.response.data.errors[0]} Please add one to continue.`
                  });

                  setCreateSubscriptionError(true);
                  setIsPaymentLoading(false);
               } else {
                  notification.error({
                     message: `${error?.response?.data?.errors?.[0]}`
                  });
                  setIsPaymentLoading(false);
               }
            }
         );
      }
   };

   useEffect(() => {
      const registeringAccount = SessionService.getItem("registeringAccount");
      setUserInformation(registeringAccount);
   }, []);

   useEffect(() => {
      httpService.get(`/Languages/getAllCountries`, (res) => {
         try {
            setCountries(res.data);
         } catch (error) {
            console.log(error);
         }
      });

      httpService.get(`/Account/getOrganizationTypes`, (res) => {
         try {
            setOrgs(res.data);
         } catch (error) {
            console.log(error);
         }
      });
   }, []);

   return (
      <>
         <section className="review-pay-section">
            <div
               style={{
                  textAlign: "left",
                  marginBottom: "40px",
                  cursor: "pointer",
                  color: "#5770CB"
               }}
               onClick={() => emitStep(1)}
            >
               {" "}
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
               >
                  <path
                     d="M14.7358 8.2339L2.73584 8.2339M2.73584 8.2339L8.10255 2.86719M2.73584 8.2339L8.10255 13.6006"
                     stroke="#5770CB"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>{" "}
               Go back to profile information
            </div>
            <h1 style={{ margin: "40px", textAlign: "center" }} className="heading-select-plan">
               Review and confirm plan
            </h1>
            {SessionService.getItem("registeringAccount") && (
               <section className="create-information-review" style={{ alignContent: "center" }}>
                  <div className="left-block">
                     <h2 style={{ color: "#5770cb" }}>Representative information</h2>
                     <div>
                        <p>Full name</p>
                        <span>{userInformation?.fullname}</span>
                     </div>
                     <div>
                        <p>Role within organization</p>
                        <span>{userInformation?.organizationRole}</span>
                     </div>
                     <div>
                        <p>Phone number</p>
                        <span>{userInformation?.phone}</span>
                     </div>
                     <div>
                        <p>Email</p>
                        <span>{userInformation?.email}</span>
                     </div>
                  </div>
                  <div className="right-block">
                     <h2 style={{ color: "#5770cb" }}>Organization’s information</h2>
                     <div>
                        <p>Name</p>
                        <span>{userInformation?.organizationName}</span>
                     </div>
                     <div>
                        <p>Country</p>
                        <span>
                           {userInformation?.country?.name
                              ? countries?.find((c) => c.name === userInformation?.country?.name)
                                   ?.name
                              : countries?.find((c) => c.id === userInformation?.country)?.name}
                        </span>
                     </div>
                     <div>
                        <p>Type of organization</p>
                        <span>
                           {userInformation?.typeOrganization?.name
                              ? orgs?.find(
                                   (c) => c.name === userInformation?.typeOrganization?.name
                                )?.name
                              : orgs?.find((c) => c.id === userInformation?.typeOrganization)?.name}
                        </span>
                     </div>
                  </div>
               </section>
            )}
            <PlanInformation />
            <PaymentMethod />
            <div className="payment-prefer"></div>
         </section>
         <div
            className="register-button-group"
            style={{ marginTop: "5%", width: "100%", display: "flex", justifyContent: "center" }}
         >
            {/* <Button onClick={() => emitStep(1)}>Go Back</Button> */}
            {!createSubscriptionError && (
               <Button
                  onClick={() => handlePayment()}
                  className="kapSurveyPrimaryButton"
                  style={{
                     border: "none",
                     color: "white",
                     fontSize: "600",
                     textAlign: "center",
                     justifyContent: "center"
                  }}
                  loading={isLoading || isPaymentLoading}
               >
                  {/* Go to payment */}
                  {location.pathname === "/auth/registration" ? "Start your free trial" : "Pay now"}
               </Button>
            )}
         </div>
      </>
   );
}

export default RegisterStep3;
