import React, { useEffect } from "react";
import { Input } from "antd";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";

import "./Text.scss";

const { TextArea } = Input;

export const Text = React.memo(
   ({ props, hasErrors, surveyId, userId, submissionId, label, hint, defaultValue }) => {
      const { id, questionType, isRequired, answers } = props;
      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const hasSubmissionId = Boolean(submissionId);

      const submissionAnswer = hasSubmissionId
         ? answers?.find((answer) => answer?.submissionId === submissionId)?.answerDetail?.text
         : null;

      const value = hasSubmissionId ? submissionAnswer : inputState?.answerDetail?.text ?? null;
      const passConstraint = useEvaluateConstraint(props.constraint, value);

      const showErrors = hasErrors && isRequired && !inputState?.answerDetail?.text;

      const onChange = (e) => {
         if (hasSubmissionId) return;

         if (e.target.value.length === 0) {
            setInputs({
               target: {
                  name: id,
                  value: ""
               }
            });
         } else {
            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     questionType: questionType,
                     name: props.name,
                     skipLogicAnswer: e.target.value,
                     answerDetail: {
                        text: e.target.value
                     }
                  }
               }
            });
         }
      };

      useEffect(() => {
         if (props.default || defaultValue) {
            onChange({ target: { value: defaultValue ?? props.default } });
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.default, defaultValue]);

      return (
         <div className="textarea-submission-type-wrapper">
            <PreviewQuestionLabel isRequired={isRequired} hint={hint} label={label} />

            <TextArea
               rows={4}
               required={isRequired}
               status={showErrors || (passConstraint !== null && !passConstraint) ? "error" : ""}
               placeholder="Type your answer here"
               readOnly={hasSubmissionId}
               name={id}
               value={value}
               onChange={onChange}
               className="textarea-submission-type-wrapper--textarea"
            />

            <span className="textarea-submission-type-wrapper--error">
               {showErrors ? "This field is required" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
