import React, { useCallback, useEffect, useState } from "react";
import { Col, DatePicker, Row, Select } from "antd";
import useSurveysByStatus from "../../hooks/useSurveysByStatus";
import { httpService } from "../../../../../base/services/httpService.service";
import { LocalStorageService } from "../../../../../base/services/local-storage.service";
import {
   collectionTypeEnumeratorsOptions,
   collectionTypeMessagesOptions,
   genderOptions,
   taskStatusOptions
} from "../../../../../utils/options";
import useGetMessagingTaskReceivers from "../../hooks/useGetMessagingTaskReceivers";

import "./index.scss";

const { Option } = Select;

const TaskManagementFilters = React.memo(
   ({ enumeratorsPage = false, onSelectChange, filterValue, collectionMessagesPage = false }) => {
      const workspaceId = LocalStorageService.getItem("currentWorkspaceId") || "";
      const [enumerators, setEnumerators] = useState([]);
      const [locationGroups, setLocationGroups] = useState([]);
      const { data: surveys = [] } = useSurveysByStatus(enumeratorsPage);
      const { data: beneficiaries = [] } = useGetMessagingTaskReceivers(collectionMessagesPage);
      const projectID = LocalStorageService.getItem("currentProjectID");

      const fetchEnumerators = useCallback(() => {
         if (collectionMessagesPage || enumeratorsPage) return;

         const params = new URLSearchParams({
            ProjectId: projectID,
            isDeleted: false
         });

         const url = `/Task/getEnumeratorNamesByProject?${params.toString()}`;

         httpService.get(url, (response) => {
            if (!response?.data) return;
            setEnumerators(response.data);
         });
      }, [collectionMessagesPage, enumeratorsPage, projectID]);

      const fetchData = useCallback(() => {
         if (collectionMessagesPage) return;

         const enumeratorsUrl = `/Task/getDataCollectorTasksLocations?isDeleted=false`;
         const projectSurveysUrl = `/Task/GetLocationGroups?workspaceId=${workspaceId}`;
         const url = enumeratorsPage ? enumeratorsUrl : projectSurveysUrl;

         httpService.get(url, (response) => {
            if (!response?.data) return;
            setLocationGroups(enumeratorsPage ? response.data : response.data.locationGroups);
         });
      }, [collectionMessagesPage, enumeratorsPage, workspaceId]);

      useEffect(() => {
         fetchData();
      }, [fetchData]);

      useEffect(() => {
         fetchEnumerators();
      }, [fetchEnumerators]);

      return (
         <>
            <Row gutter={[16, 16]} className="taskManagementFilters">
               {!enumeratorsPage && !collectionMessagesPage && (
                  <>
                     <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <label className="label-text">Enumerator name</label>
                        <Select
                           allowClear
                           showSearch
                           placeholder="Select an option"
                           onChange={(value) => onSelectChange(value, "dataCollectors")}
                           filterOption={(input, option) => {
                              return (option?.children ?? "")
                                 .toLowerCase()
                                 .startsWith(input.toLowerCase());
                           }}
                           value={filterValue.dataCollectors}
                        >
                           {enumerators?.map((item) => (
                              <Option key={item} value={item}>
                                 {item}
                              </Option>
                           ))}
                        </Select>
                     </Col>
                     <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <label className="label-text">Enumerator gender</label>
                        <Select
                           allowClear
                           showSearch
                           placeholder="Select an option"
                           onChange={(value) => onSelectChange(value, "gender")}
                           filterOption={(input, option) => {
                              return option && option.label
                                 ? option.label.toLowerCase().includes(input.toLowerCase())
                                 : false;
                           }}
                           options={genderOptions}
                           value={filterValue.gender}
                        />
                     </Col>
                  </>
               )}
               {!collectionMessagesPage && (
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                     <label className="label-text">Location</label>
                     <Select
                        allowClear
                        showSearch
                        placeholder="Select an option"
                        onChange={(value) => onSelectChange(value, "locationGroupId")}
                        filterOption={(input, option) => {
                           return (option?.children ?? "")
                              .toLowerCase()
                              .startsWith(input.toLowerCase());
                        }}
                        options={(locationGroups || []).map((item) => ({
                           value: item.id,
                           label: item.name
                        }))}
                        value={filterValue.locationGroupId}
                     ></Select>
                  </Col>
               )}

               <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <label className="label-text">Survey tool</label>
                  <Select
                     allowClear
                     showSearch
                     placeholder="Select a survey tool"
                     onChange={(value) =>
                        onSelectChange(value, collectionMessagesPage ? "surveyName" : "surveyId")
                     }
                     filterOption={(input, option) => {
                        return option && option.label
                           ? option.label.toLowerCase().includes(input.toLowerCase())
                           : false;
                     }}
                     value={filterValue.surveyId ?? filterValue.surveyName}
                  >
                     {(surveys || []).map((item) => (
                        <Option key={item.id} value={collectionMessagesPage ? item.name : item.id}>
                           {item.name}
                        </Option>
                     ))}
                  </Select>
               </Col>
               <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <label className="label-text">Collection type</label>
                  <Select
                     allowClear
                     placeholder="Select an option"
                     onChange={(value) => onSelectChange(value, "collectionType")}
                     options={
                        collectionMessagesPage
                           ? collectionTypeMessagesOptions
                           : collectionTypeEnumeratorsOptions
                     }
                     value={filterValue.collectionType}
                  />
               </Col>
               <Col xs={24} sm={12} md={12} lg={8} xl={8} className="deadline">
                  <label className="label-text">
                     {collectionMessagesPage ? "Sending date" : "Deadline"}
                  </label>
                  <DatePicker
                     onChange={(value) =>
                        onSelectChange(
                           value,
                           `${collectionMessagesPage ? "sendingDate" : "tasksDeadline"}`
                        )
                     }
                     value={
                        collectionMessagesPage ? filterValue.sendingDate : filterValue.tasksDeadline
                     }
                  />
               </Col>
               {!collectionMessagesPage && (
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                     <label className="label-text">Status</label>
                     <Select
                        allowClear
                        placeholder="Select status"
                        onChange={(value) => onSelectChange(value, "taskStatus")}
                        filterOption={(input, option) => {
                           return option && option.children
                              ? option.label.toLowerCase().includes(input.toLowerCase())
                              : false;
                        }}
                        options={taskStatusOptions}
                        value={filterValue.taskStatus}
                     />
                  </Col>
               )}
               {collectionMessagesPage && (
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                     <label className="label-text">Receiver</label>
                     <Select
                        allowClear
                        showSearch
                        placeholder="Select receiver"
                        onChange={(value) => onSelectChange(value, "receiver")}
                        filterOption={(input, option) => {
                           return (option?.children ?? "")
                              .toLowerCase()
                              .startsWith(input.toLowerCase());
                        }}
                        value={filterValue.receiver}
                     >
                        {beneficiaries?.map((item, index) => (
                           <Option key={index} value={item}>
                              {item}
                           </Option>
                        ))}
                     </Select>
                  </Col>
               )}
            </Row>
         </>
      );
   }
);

export default TaskManagementFilters;
