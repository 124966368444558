import { DebounceInput } from "react-debounce-input";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import "./TabContainer.scss";

const CustomInput = (props) => {
   const suffix = (
      <SearchOutlined
         style={{
            fontSize: "14px",
            color: "#979797"
         }}
      />
   );
   return <Input style={{ maxWidth: "478px" }} {...props} suffix={suffix} />;
};

const TabContainer = ({ children, searchValue, setSearchValue, title, searchPlaceholder }) => {
   return (
      <div className="deleted-tab-container">
         <div className="deleted-tab-container--title">
            <h3>{title}</h3>
         </div>
         <div className="deleted-tab-container--filters">
            <label htmlFor="search">Search</label>
            <DebounceInput
               id="search"
               placeholder={searchPlaceholder ?? "Type something here"}
               debounceTimeout={800}
               value={searchValue}
               onChange={(e) => setSearchValue(e.target.value)}
               element={CustomInput}
            />
         </div>
         <div>{children}</div>
      </div>
   );
};

export default TabContainer;
