import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Flex, Tabs } from "antd";

import WorkspacesTab from "./Tabs/Workspaces";
import UsersTab from "./Tabs/Users";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/AssetsHelpers";
import { EntityTypeEnum } from "../../../utils/enums";
import { withErrorBoundary } from "../../../components/ErrorBoundary/withErrorBoundary";

import "./AdminDeletedItems.scss";

const MyTabs = withErrorBoundary(Tabs);

function AdminDeletedItems() {
   const [searchParams, setSearchParams] = useSearchParams();

   const items = useMemo(
      () => [
         {
            key: `${EntityTypeEnum.Workspace}`,
            label: "Workspaces",
            children: <WorkspacesTab />
         },
         {
            key: `${EntityTypeEnum.OrganizationMember}`,
            label: "Users",
            children: <UsersTab />
         }
      ],
      []
   );

   const onChange = (key) => {
      setSearchParams({ entityType: key });
   };

   useEffect(() => {
      const entityType = searchParams.get("entityType");
      if (!entityType) {
         setSearchParams({ entityType: items[0].key });
      }
   }, [items, searchParams, setSearchParams]);

   return (
      <div className="admin-deleted-items">
         <div
            className="admin-deleted-items--header"
            style={{
               background: `no-repeat right 40% / auto url(${toAbsoluteUrl(
                  "/media/svg/survey/PreviewBanner.svg"
               )}), #D9E3FF`
            }}
         >
            <Flex
               align="center"
               style={{
                  height: "100%"
               }}
            >
               <h3>Deleted items</h3>
            </Flex>
         </div>

         <div className="admin-deleted-items--container">
            <MyTabs
               prefixCls="admin-deleted-items-tabs"
               defaultActiveKey={searchParams.get("entityType")}
               items={items}
               onChange={onChange}
            />
         </div>
      </div>
   );
}

export default AdminDeletedItems;
