import React, { Fragment, useMemo } from "react";
import ItemSelector from "../question/question-preview/ItemSelector";
import { editDataStore, useTranslateStore } from "../../surveyStore";
import { Constants } from "../../../../../utils/constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { InputType } from "../../../enums/itemTypes.enum";
import useEvaluateConstraint from "../question/question-preview/hooks/useEvaluateConstraint";

import "./Section.scss";

function Section({ surveyItem, hasErrors, submissionId }) {
   const { toLanguage } = useTranslateStore();
   const surveyBaseLanguage = editDataStore((state) => state.surveyBaseLanguage);

   const hasSkipLogic = surveyItem.surveyItem.relevant;
   const isQuestionSubmitted = useEvaluateConstraint(hasSkipLogic, true);

   const isArabicLanguage = !toLanguage
      ? surveyBaseLanguage === Constants.ARABIC_LANGUAGE_ID
      : toLanguage === Constants.ARABIC_LANGUAGE_ID;

   const sortedSurveyItems = useMemo(() => {
      return [...surveyItem.innerItems]?.sort((a, b) => a.surveyItem.order - b.surveyItem.order);
   }, [surveyItem.innerItems]);

   return (
      <Fragment>
         {!hasSkipLogic || isQuestionSubmitted ? (
            <div key={surveyItem.surveyItem?.name} className="preview-survey-section">
               <div className="preview-survey-section--header">
                  <h5
                     style={{
                        textAlign: isArabicLanguage ? "right" : "left"
                     }}
                  >
                     <span>
                        {!surveyItem.sectionLabel || !toLanguage
                           ? surveyItem.surveyItem?.description
                           : surveyItem.sectionLabel?.description}
                     </span>
                     <span style={{ marginLeft: "13px", fontSize: "16px" }}>
                        <InfoCircleOutlined />
                     </span>
                  </h5>
               </div>
               <div
                  style={{
                     textAlign: isArabicLanguage ? "right" : "left"
                  }}
               >
                  {sortedSurveyItems.map((item, i) => {
                     if (item.itemLabel === "Section") {
                        return (
                           <Section
                              key={i}
                              hasErrors={hasErrors}
                              surveyItem={item}
                              submissionId={submissionId}
                           />
                        );
                     } else {
                        return (
                           <div
                              className={`preview-survey-section--questions_wrapper ${
                                 item?.surveyItem?.questionType === InputType.CALCULATE_QUESTION
                                    ? "p-0"
                                    : ""
                              }`}
                              key={i}
                           >
                              <ItemSelector
                                 props={item.surveyItem}
                                 hasErrors={hasErrors}
                                 submissionId={submissionId}
                              />
                           </div>
                        );
                     }
                  })}
               </div>
            </div>
         ) : null}
      </Fragment>
   );
}

export default Section;
