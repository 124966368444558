import { create } from "zustand";
import { message } from "antd";
import { WarningOutlined, WifiOutlined } from "@ant-design/icons";

const onlineMessageKey = "online-message";
const offlineMessageKey = "offline-message";
const destroyMessages = () => {
   [onlineMessageKey, offlineMessageKey].forEach((key) => message.destroy(key));
};

const useOnlineStore = create((set) => ({
   isOnline: navigator.onLine,

   setOnlineStatus: (online) => set({ isOnline: online }),

   initOnlineStatusListener: () => {
      let intervalId;

      const checkOnlineStatus = async () => {
         const online = await fetch(
            process.env.REACT_APP_BASE_URL + "/DataCollection/CheckOnlineStatus"
         )
            .then((response) => response.status === 200 || response.status === 0)
            .catch(() => false);

         set({ isOnline: online });
      };

      const handleOnline = () => {
         destroyMessages();
         message.open({
            content: (
               <div>
                  <WifiOutlined style={{ color: "green" }} /> We are back online.
               </div>
            ),
            key: onlineMessageKey
         });
         set({ isOnline: true });
      };

      const handleOffline = () => {
         destroyMessages();
         message.open({
            content: (
               <div>
                  <WarningOutlined style={{ color: "red" }} /> We are offline now.
               </div>
            ),
            key: offlineMessageKey
         });
         set({ isOnline: false });
      };

      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);

      // Check the online status immediately and then every 5 seconds
      checkOnlineStatus();
      intervalId = setInterval(checkOnlineStatus, 5000);

      return () => {
         clearInterval(intervalId);
         window.removeEventListener("online", handleOnline);
         window.removeEventListener("offline", handleOffline);
      };
   }
}));

export default useOnlineStore;
