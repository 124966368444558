import {
   CollectionTypeEnumeratorsEnum,
   CollectionTypeMessagesEnum,
   GenderEnum,
   SurveyStatusEnum,
   TaskStatusEnum,
   RecurrenceOptionsEnum,
   WeekDaysEnum
} from "./enums";

export const surveyStatusOptions = [
   { value: SurveyStatusEnum.Editing, label: "Editing" },
   { value: SurveyStatusEnum.Deployed, label: "Published" },
   { value: SurveyStatusEnum.Archived, label: "Archived" }
];

export const taskStatusOptions = [
   {
      value: TaskStatusEnum.Assigned,
      label: "Assigned"
   },
   {
      value: TaskStatusEnum.Rejected,
      label: "Rejected"
   },
   // {
   //    value: TaskStatusEnum.InProgress,
   //    label: "In progress"
   // },
   {
      value: TaskStatusEnum.Completed,
      label: "Completed"
   }
];

export const genderOptions = [
   {
      value: GenderEnum.Male,
      label: "Male"
   },
   {
      value: GenderEnum.Female,
      label: "Female"
   },
   {
      value: GenderEnum.Other,
      label: "Other"
   }
];

export const collectionTypeEnumeratorsOptions = [
   {
      value: CollectionTypeEnumeratorsEnum.SurveyLink,
      label: "Survey Link"
   },
   {
      value: CollectionTypeEnumeratorsEnum.InPerson,
      label: "In person"
   }
];

export const collectionTypeMessagesOptions = [
   {
      value: CollectionTypeMessagesEnum.Whatsapp,
      label: "Whatsapp"
   },
   {
      value: CollectionTypeMessagesEnum.Email,
      label: "Email"
   }
];

export const recurrenceOptionsOptions = [
   {
      value: RecurrenceOptionsEnum.Daily,
      label: "Daily"
   },
   {
      value: RecurrenceOptionsEnum.Weekly,
      label: "Weekly"
   },
   {
      value: RecurrenceOptionsEnum.Biweekly,
      label: "Biweekly"
   },
   {
      value: RecurrenceOptionsEnum.Monthly,
      label: "Monthly"
   },
   {
      value: RecurrenceOptionsEnum.Yearly,
      label: "Yearly"
   },
   {
      value: RecurrenceOptionsEnum.EveryXDays,
      label: "Every x days"
   },
   {
      value: RecurrenceOptionsEnum.NoRecurrence,
      label: "No Recurrence"
   }
];

export const weekDaysOptions = [
   {
      value: WeekDaysEnum.Monday,
      label: "Monday"
   },
   {
      value: WeekDaysEnum.Tuesday,
      label: "Tuesday"
   },
   {
      value: WeekDaysEnum.Wednesday,
      label: "Wednesday"
   },
   {
      value: WeekDaysEnum.Thursday,
      label: "Thursday"
   },
   {
      value: WeekDaysEnum.Friday,
      label: "Friday"
   },
   {
      value: WeekDaysEnum.Saturday,
      label: "Saturday"
   },
   {
      value: WeekDaysEnum.Sunday,
      label: "Sunday"
   }
];
