import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { SessionService } from "../../../base/services/session.service";
import CheckoutForm from "../payment/StripeForm";
import RegisterStep2 from "../register/RegisterStep2";
import RegisterStep3 from "../register/RegisterStep3";

const Payment = () => {
   const { REACT_APP_STRIPE_PUBLISHABLEKEY } = process.env;
   const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLEKEY);
   const addPaymentDetails = SessionService.getItem("addPaymentDetails");
   const [step, setStep] = useState(addPaymentDetails ? 0 : 1);

   const goToStep = (step) => {
      setStep(step);
   };

   const appearance = {
      theme: "stripe"
   };

   const options = {
      appearance
   };

   return (
      <>
         {step === 0 && (
            <Elements options={options} stripe={stripePromise}>
               <CheckoutForm
                  handleCardDetails={() => {
                     goToStep(1);
                  }}
               />
            </Elements>
         )}

         {step === 1 && (
            <RegisterStep2
               goToStep={goToStep}
               goToPayment={() => {
                  goToStep(2);
               }}
            />
         )}
         {step === 2 && <RegisterStep3 goToStep={goToStep} />}
      </>
   );
};

export default Payment;
