import React from "react";
import "animate.css";
import "./KAPsurveyLoader.scss";

function KAPsurveyLoader() {
   return (
      <div className="KAPsurveyLoader">
         <img
            className="animate__animated animate__pulse"
            alt="kapsurvey"
            src="/media/logos/KAPsurvey-logo.svg"
         />
      </div>
   );
}

export default KAPsurveyLoader;
