import React from "react";
import RegisterStep3 from "../../auth/register/RegisterStep3";
import { ComingSoon } from "../../comingSoon/ComingSoon";
import {OrganizationManagement} from "../organization-management/OrganizationManagment";

function PaymentPlans() {
   return <>
      <OrganizationManagement></OrganizationManagement>
   </>
}

export default PaymentPlans;
