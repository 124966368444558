const rolesOrder = {
   Guest: 1,
   DataCollector: 2,
   JuniorManager: 3,
   ProjectManager: 4,
   SeniorManager: 5,
   OrgSuperAdmin: 6
};

export const formatRoleName = (roleName) => {
   return roleName.replace(/([A-Z])/g, " $1").trim();
};

const splitNameOnUppercase = (data) => {
   const result = [];

   data.forEach((item) => {
      const nameParts = item.name.split(/(?=[A-Z])/);
      const modifiedName = nameParts.join(" ");

      result.push({
         id: item.id,
         name: modifiedName
      });
   });

   return result;
};

export const getOrderedRoles = (roles) => {
   const sorted = roles.sort((role1, role2) => {
      const index1 = rolesOrder[role1.name] || 0;
      const index2 = rolesOrder[role2.name] || 0;
      return index1 - index2 || role1.name.localeCompare(role2.name);
   });
   return splitNameOnUppercase(sorted);
};

export const getLocationIdByName = (countries, values) => {
   const selectedLocation = countries.find((country) => country.label === values.location);
   return selectedLocation ? selectedLocation.value : "";
};
export const getRoleIdByName = (rolesList, values) => {
   const selectedRole = rolesList.find((role) => {
      if (Array.isArray(values.role)) {
         return values.role.includes(role.name);
      } else {
         return role.name === values.role;
      }
   });
   return selectedRole ? selectedRole.id : "";
};
export const getLanguageIdByName = (languages, values) => {
   return values.language.map((language) => {
      const matchingLanguage = languages.find((lang) => lang.label === language);
      return matchingLanguage ? matchingLanguage.value : language;
   });
};
export const getWorkspaceIdByName = (workspaces, values) => {
   return values.workspace.map((wrkspace) => {
      const matchingWorkspace = workspaces.find((workspace) => workspace.name === wrkspace);
      return matchingWorkspace ? matchingWorkspace.id : wrkspace;
   });
};
