import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
   Button,
   Form,
   Input,
   Select,
   Tooltip,
   notification,
   message,
   Upload,
   DatePicker
} from "antd";
import { LocalStorageService } from "../../../../../base/services/local-storage.service";
import { httpService } from "../../../../../base/services/httpService.service";
import * as XLSX from "xlsx";
import BasicModal from "../../../../../ui/BasicModal/BasicModal";
import { withErrorBoundary } from "../../../../../components/ErrorBoundary/withErrorBoundary";

export default function ProjectTypes() {
   const { RangePicker } = DatePicker;

   const { id } = useParams();
   const navigate = useNavigate();
   const [collectionName, setCollectionName] = useState();
   const [api, contextHolder] = notification.useNotification();
   const [uploadedFile, setUploadedFile] = useState();
   const [isModalOpen, setIsModalOpen] = useState(false);

   const [load, setLoad] = useState(false);

   const [arrow, setArrow] = useState("Show");
   const [expectedFormat, setExpectedFormat] = useState(null);

   const openNotificationWithIcon = (type, description) => {
      if (type === "error") {
         api[type]({
            message: "Something went wrong",
            description
         });
      }
   };

   useEffect(() => {
      // Fetch the expected format file
      fetch("/files/beneficiaryTableTemplate.xlsx")
         .then((response) => response.blob())
         .then((blob) => {
            const reader = new FileReader();
            reader.onload = () => {
               const binaryData = reader.result;
               setExpectedFormat(binaryData);
            };
            reader.readAsArrayBuffer(blob);
         });
   }, []);

   const mergedArrow = useMemo(() => {
      if (arrow === "Hide") {
         return false;
      }

      if (arrow === "Show") {
         return true;
      }

      return {
         pointAtCenter: true
      };
   }, [arrow]);

   const UploadProps = {
      name: "file",
      accept: ".xlsx",
      maxCount: 1,

      beforeUpload: (file) => {
         const isPNG =
            file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
         if (!isPNG) {
            message.error(`${file.name} is not a xlsx file`);
         }
         return isPNG || Upload.LIST_IGNORE;
      }
   };

   ///Link Data
   const [dataList, setDataList] = useState([]);
   const [countries, setCountries] = useState([]);

   useEffect(() => {
      httpService.get("Projects/getAllProjectTypes", (res) => {
         const foundCollection = res.data.find((collection) => collection.id === id);
         if (foundCollection) {
            setCollectionName(foundCollection.name);
         }
      });
   }, [id]);

   const workspcId = LocalStorageService?.getItem("currentWorkspaceId") || "";

   useEffect(() => {
      httpService.get("/Members/getAllOrgMembersInWorkspace/?WorkSpaceId=" + workspcId, (res) => {
         setDataList(
            res.data.map((member) => ({
               id: member.id,
               fullName: member.fullName,
               role: member.roles.map((role) => role.name)
            }))
         );
      });
      httpService.get("/Languages/getAllCountries", (res) => {
         setCountries(
            res.data
               .map((country) => ({
                  id: country.id,
                  name: country.name,
                  role: country.code
               }))
               .sort((a, b) => a.name.localeCompare(b.name))
         );
      });
   }, [workspcId]);

   const { TextArea } = Input;

   // const appendBeneficiaryIdToProject = async (data) => {
   //    const formData = new FormData();
   //    formData.append("Beneficiaries", new Blob([uploadedFile.binaryData]), uploadedFile.file.name);
   //    formData.append("Name", data.Name);
   //    formData.append("Description", data.Description);
   //    formData.append("ProjectId", data.ProjectId);
   //    formData.append("ManagerId", data.ManagerId);
   //    // formData.append("SurveyId", "");

   //    const url = "/DataCollection/importBeneficiaries";
   //    await httpService.post(url, formData);
   // };

   const onFinish = (values) => {
      // if (!uploadedFile || !uploadedFile.binaryData || !uploadedFile.file) {
      //    message.error("Please upload a valid xlsx file before submitting.");
      //    return;
      // }
      setLoad(true);
      let formattedStartDate;
      let formattedEndDate;
      if (values.timeLine) {
         const [startDate, endDate] = values.timeLine;
         formattedStartDate = startDate.format();
         formattedEndDate = endDate.format();
      }
      const payload = {
         name: values.projectTitle,
         description: values.projectDescription,
         managerId: values.projectManager,
         members: values.dataCollectors,
         workspaceId: LocalStorageService?.getItem("currentWorkspaceId") || "",
         typeId: id,
         countryIds: values.country,
         startDate: formattedStartDate,
         endDate: formattedEndDate
      };
      httpService
         .post(
            "/Projects/add",
            payload,
            (res) => {
               const createdProjectId = res.data;
               // appendBeneficiaryIdToProject({
               //    Beneficiaries: uploadedFile,
               //    Name: payload.name,
               //    Description: payload.description,
               //    ProjectId: createdProjectId,
               //    ManagerId: payload.managerId
               // });
               LocalStorageService.setItem("currentProjectID", createdProjectId);
               navigate("/dashboard");
            },
            (error) => {
               if (
                  error.response.status === 400 &&
                  Array.isArray(error.response.data.errors) &&
                  error.response.data.errors.includes &&
                  error.response.data.errors.includes(
                     "You reached your packet limit for creating projects in workspace!!"
                  )
               ) {
                  setIsModalOpen(true);
               } else {
                  openNotificationWithIcon("error", "Please try again");
               }
               setLoad(false);
            }
         )
         .finally(() => {
            setLoad(false);
         });
   };

   // const onFinish = (values) => {
   //    setLoad(true);
   //    httpService
   //       .post(
   //          "/Workspaces/add",

   //          {
   //             name: values.workspaceName,
   //             description: values.description ? values.description : ""
   //          },
   //          (res) => {
   //             if (res?.isSuccess) {
   //                openNotificationWithIcon("success");
   //                setTimeout(() => {
   //                   setLoad(false);
   //                   navigate(-1);
   //                }, 1500);
   //             }
   //          },
   //          (error) => {
   //             if (
   //                error.response.status === 400 &&
   //                Array.isArray(error.response.data.errors) &&
   //                error.response.data.errors.includes &&
   //                error.response.data.errors.includes(
   //                   "You reached your packet limit for creating workspaces!!"
   //                )
   //             ) {
   //                setIsModalOpen(true);
   //             } else {
   //                if (values.workspaceName.trim() === "") {
   //                   openNotificationWithIcon("error", "Workspace name is required!");
   //                } else {
   //                   openNotificationWithIcon("error");
   //                }
   //             }
   //          }
   //       )
   //       .finally(() => {
   //          setLoad(false);
   //       });
   //    console.log(values);
   // };

   const onFinishFailed = (errorInfo) => {
      setLoad(false);
   };

   const textAreaStyle = { resize: "none" };

   const saveUploadedFile = ({ file, onSuccess }) => {
      const reader = new FileReader();

      reader.onload = () => {
         const binaryData = reader.result;

         // Check if the uploaded file matches the expected format
         if (binaryData && expectedFormat && compareExcelFiles(binaryData, expectedFormat)) {
            setUploadedFile({
               file: file,
               binaryData: binaryData
            });
            onSuccess();
         } else {
            message.error("Uploaded file does not match the expected format.");
         }
      };

      reader.onerror = (error) => {};

      reader.readAsArrayBuffer(file);
   };

   const compareExcelFiles = (binaryData1, binaryData2) => {
      // Convert binary data to arrays
      const arr1 = new Uint8Array(binaryData1);
      const arr2 = new Uint8Array(binaryData2);

      // Convert arrays to worksheets
      const workbook1 = XLSX.read(arr1, { type: "array" });
      const workbook2 = XLSX.read(arr2, { type: "array" });

      // Compare worksheets or perform any other required comparison logic
      // For simplicity, let's check the number of sheets and their names
      const sheets1 = workbook1.SheetNames;
      const sheets2 = workbook2.SheetNames;

      return (
         sheets1.length === sheets2.length &&
         sheets1.every((sheet, index) => sheet === sheets2[index])
      );
   };

   return (
      <div className="create-new-project-page flex-column align-items-center ">
         {/* <div className=" download-format bg-red">
            <div className="download-line"></div>
            <p>Your beneficiaries dataset must have the right format for it to work correctly. </p>
            {contextHolder}

            <Space direction="vertical">
               <Space wrap>
                  <Button
                     download={"Project_Starter_Template"}
                     href={"/files/beneficiaryTableTemplate.xlsx"}
                     className="download-btn"
                     type="primary"
                     // icon={<DownloadOutlined />}
                  >
                     Download Dataset Format
                  </Button>
               </Space>
            </Space>
         </div> */}
         <h2
            className="text-center d-flex align-items-center justify-content-center mt-3"
            style={{ padding: "50px" }}
         >
            New Project
            <span className="tooltips mx-2" style={{ fontSize: "14px" }}>
               {" "}
               <Tooltip placement="top" title={"new project"} arrow={mergedArrow} color={"#8153F3"}>
                  ?
               </Tooltip>
            </span>
         </h2>

         <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
            <Form.Item
               label="Project Title*"
               name="projectTitle"
               rules={[
                  {
                     required: true,
                     message: "Please specify the official title of the project."
                  }
               ]}
            >
               <Input placeholder="Enter a title" />
            </Form.Item>

            <Form.Item
               label="Project Description"
               name="projectDescription"
               rules={[
                  {
                     required: false,
                     message: "Please furnish a detailed description of the project for clarity."
                  }
               ]}
            >
               <TextArea
                  rows="6"
                  style={textAreaStyle}
                  placeholder="Enter a description"
               ></TextArea>
            </Form.Item>

            <Form.Item
               label="Project Manager*"
               name="projectManager"
               rules={[
                  {
                     required: true,
                     message: "Specify the name and designation of the assigned project manager."
                  }
               ]}
            >
               <Select
                  placeholder="--Select a manager--"
                  allowClear
                  showSearch
                  optionFilterProp="children"
               >
                  {Array.isArray(dataList) &&
                     dataList
                        .filter(
                           (member) =>
                              member.role[0] !== "DataCollector" && member.role[0] !== "Guest"
                        )
                        .map((data, i) => {
                           return (
                              <Select.Option value={data.id} key={i}>
                                 {data.fullName}
                              </Select.Option>
                           );
                        })}
               </Select>
            </Form.Item>
            <Form.Item
               label="Team Members"
               name="dataCollectors"
               rules={[
                  {
                     required: false,
                     message:
                        "For documentation purposes, provide a roster of project team members."
                  }
               ]}
            >
               <Select
                  placeholder="--Select members-- "
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
               >
                  {dataList.map((data, i) => (
                     <Select.Option value={data.id} key={i}>
                        {data.fullName}
                     </Select.Option>
                  ))}
               </Select>
            </Form.Item>
            <Form.Item
               label="Country*"
               name="country"
               rules={[
                  {
                     required: true,
                     message: "Specify the country of origin or primary activity for the project."
                  }
               ]}
            >
               <Select
                  placeholder="--Select countries-- "
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                     option.children.toLowerCase().startsWith(input.toLowerCase())
                  }
               >
                  {countries.map((data, i) => {
                     return (
                        <Select.Option value={data.id} key={i}>
                           {data.name}
                        </Select.Option>
                     );
                  })}
               </Select>
            </Form.Item>
            <Form.Item
               label="Timeline*"
               name="timeLine"
               rules={[
                  {
                     required: true,
                     message: "Please select a timeline for the project."
                  }
               ]}
            >
               <RangePicker />
            </Form.Item>
            {/* <Upload {...UploadProps} accept=".xlsx" customRequest={saveUploadedFile}>
               <Button className="beneficiaries-button">
                  {" "}
                  <PlusOutlined /> Add beneficiaries
               </Button>
            </Upload> */}
            <div className="create-survey-button-group" style={{ marginTop: "30px" }}>
               <Form.Item
                  wrapperCol={{
                     offset: 8,
                     span: 16
                  }}
               >
                  <Button type="primary" onClick={() => navigate(-1)} className="previous">
                     Cancel
                  </Button>
               </Form.Item>

               <Form.Item
                  wrapperCol={{
                     offset: 8,
                     span: 16
                  }}
               >
                  <Button
                     disabled={load}
                     loading={load}
                     htmlType="submit"
                     className="kapSurveyPrimaryButton"
                  >
                     Create Project
                  </Button>
               </Form.Item>
            </div>
         </Form>
         <BasicModal isModalOpen={isModalOpen} handleCancel={() => setIsModalOpen(false)}>
            <h3
               style={{
                  textAlign: "center"
               }}
            >
               You reached your packet limit for creating projects in workspace!
            </h3>
         </BasicModal>
      </div>
   );
}

const ProjectTypesWithErrorBoundary = withErrorBoundary(ProjectTypes);
export { ProjectTypesWithErrorBoundary as ProjectTypes };
