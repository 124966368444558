import React, { useCallback, useEffect, useState } from "react";
import { SubHeader } from "../../../../_metronic/layout/components/subheader/SubHeader";
import { IntroSection } from "../../../components/intro-section/IntroSection";
import { Tasks } from "../../../components/tasks";
import { httpService } from "../../../base/services/httpService.service";
import ReactivatedModal from "../components/ReactivatedModal/ReactivatedModal";
import { withErrorBoundary } from "../../../components/ErrorBoundary/withErrorBoundary";

import "./index.scss";

const PAGE = 1;
const PAGE_SIZE = 10000;

const MyTasks = () => {
   const [statistics, setStatistics] = useState({
      assigned: 0,
      completed: 0,
      rejected: 0,
      // inProgress: 0,
      averageTasksPerEnumerator: 0
   });

   const getTaskStatsticsByDataCollector = useCallback(async () => {
      const params = new URLSearchParams({
         page: PAGE,
         pageSize: PAGE_SIZE
      });
      try {
         const response = await httpService.get(
            `Task/GetTaskStatsticsByDataCollector?${params.toString()}`
         );
         setStatistics({
            assigned: response.data.assigned,
            completed: response.data.completed,
            rejected: response.data.rejected
            // inProgress: response.data.inProgress
         });
      } catch (error) {
         console.error("Error fetching task data:", error);
      }
   }, []);

   useEffect(() => {
      getTaskStatsticsByDataCollector();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="myTasks">
         <div className="header">
            <SubHeader></SubHeader>
         </div>
         <div className="section section01">
            <IntroSection
               dataLength={[statistics.assigned, statistics.completed, statistics.rejected]}
            ></IntroSection>
         </div>

         <div className="section section2">
            <h4 className="tableText yourTasks">Your tasks</h4>
            <Tasks />
         </div>
         <ReactivatedModal />
      </div>
   );
};

export default withErrorBoundary(MyTasks);
