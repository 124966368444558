import React from "react";
import { Col, Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import "./SearchFilter.scss";

const SearchFilter = React.memo(
   ({ showFilters, handleSearch, handleShowFilters, handleClearFilters, children }) => {
      return (
         <Row justify="space-between" gutter={[0, 16]} className="search-filter">
            <Col xs={24} sm={24} md={10} lg={10} xl={14}>
               <Input placeholder="Search..." onChange={handleSearch} suffix={<SearchOutlined />} />
            </Col>
            {showFilters && (
               <Col xs={24} sm={24} md={6} lg={6} xl={4} className="search-filter--button_show">
                  <span onClick={handleClearFilters} className="search-filter--text">
                     Clear filters
                  </span>
               </Col>
            )}
            <Col xs={24} sm={24} md={6} lg={6} xl={4} className="search-filter--button_show">
               <span onClick={handleShowFilters} className="search-filter--text">
                  {showFilters ? "Hide filters" : "Show filters"}
               </span>
            </Col>
            {showFilters && <>{children}</>}
         </Row>
      );
   }
);

export default SearchFilter;
