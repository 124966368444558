export const getTabValue = (tab) => {
   switch (tab) {
      case "Assigned":
         return 0;
      case "Rejected":
         return 1;
      case "In Progress":
         return 2;
      case "Completed":
      case "Finished":
         return 3;
      default:
         return null;
   }
};
