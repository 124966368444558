import React, { useCallback, useEffect, useState } from "react";
import { notification } from "antd";
import { EntityTypeEnum, FetchStatusEnum, StatusCodesEnum } from "../../../../utils/enums";
import { LocalStorageService } from "../../../../base/services/local-storage.service";
import { httpService } from "../../../../base/services/httpService.service";
import https from "../../../../base/utils/http";
import DeletedItemsModal from "../../../../components/DeletedItemsComponents/DeletedItemsModal/DeletedItemsModal";

const PAGE_SIZE = 10;

const DeletedProjectsModal = (props) => {
   const [data, setData] = useState([]);
   const [status, setStatus] = useState(FetchStatusEnum.Idle);
   const [pagination, setPagination] = useState({
      page: 1,
      totalSize: 0
   });
   const [deletingAll, setDeletingAll] = useState(false);

   const handleRestore = async (entity) => {
      try {
         const params = new URLSearchParams({
            entityType: EntityTypeEnum.Project,
            isDeleted: false
         });
         const res = await https.put(
            `/DataCollection/${entity.id}/toggleDeleteOrRestore?${params.toString()}`
         );
         notification.open({
            type: res.data.isSuccess ? "success" : "error",
            message: res.data.message || "Something went wrong! Please try again later."
         });
         fetchData();
      } catch (_err) {
         notification.error({ message: "Error restoring project, please try again later." });
      }
   };

   const handleDelete = async (entity) => {
      await httpService.post(
         "Projects/delete",
         {
            id: entity.id
         },
         (res) => {
            notification.open({
               type: res.isSuccess ? "success" : "error",
               message: (
                  <div>
                     <div>{res.message || "Something went wrong! Please try again later."}</div>
                     <br />
                     <ul>
                        {res?.errors?.map((err) => (
                           <li key={err}>{err}</li>
                        ))}
                     </ul>
                  </div>
               )
            });
            fetchData();
         },
         (_error) => {
            notification.error({ message: "Error deleting project, please try again later." });
         }
      );
   };

   const fetchData = useCallback(async () => {
      setStatus(FetchStatusEnum.Loading);

      const workspaceID = LocalStorageService.getItem("currentWorkspaceId");
      if (!workspaceID) {
         return;
      }
      const params = new URLSearchParams({
         isDeleted: "true",
         page: pagination.page,
         pageSize: PAGE_SIZE
      });

      httpService.get(
         `/Projects/getMyProjectsInWorkspace/${workspaceID}?${params.toString()}`,
         (res) => {
            setStatus(FetchStatusEnum.Success);
            setData(res.data?.projects);
            setPagination((prevS) => ({
               ...prevS,
               totalSize: res.data?.totalSize || 0,
               page:
                  res.data?.projects?.length === 0 && res.data?.totalSize !== 0
                     ? pagination.page - 1
                     : pagination.page
            }));
         },
         (_err) => {
            setStatus(FetchStatusEnum.Error);
         }
      );
   }, [pagination.page]);

   useEffect(() => {
      if (!props.isModalOpen) {
         return;
      }
      fetchData();
   }, [props.isModalOpen, fetchData]);

   const handleDeleteAll = async () => {
      try {
         setDeletingAll(true);
         const workspaceId = LocalStorageService.getItem("currentWorkspaceId");
         const params = new URLSearchParams({
            workspaceId: workspaceId,
            isDeleted: true
         });
         const res = await https.post(
            `/Projects/deleteAllProjectsInWorkspace?${params.toString()}`
         );
         const isSuccess = res.data.statusCode === StatusCodesEnum.OK;
         notification.open({
            type: isSuccess ? "success" : "error",
            message: res.data.message || "Something went wrong! Please try again later."
         });
         if (isSuccess) {
            props.handleOk?.();
            props.setIsModalOpen(false);
         }
         setDeletingAll(false);
      } catch (_err) {
         notification.error({ message: "Error deleting projects, please try again later." });
         setDeletingAll(false);
      }
   };

   const handleCancel = () => {
      props.handleCancel?.();
      props.setIsModalOpen(false);
   };

   const columns = [
      {
         title: "Project Name",
         dataIndex: "name",
         key: "name"
      },
      {
         title: "Manager",
         dataIndex: "manager",
         key: "manager",
         render: (manager) => manager?.fullName ?? ""
      }
   ];

   return (
      <DeletedItemsModal
         label="projects"
         isModalOpen={props.isModalOpen}
         handleCancel={handleCancel}
         entityType={EntityTypeEnum.Project}
         columns={columns}
         data={data}
         status={status}
         deletingAll={deletingAll}
         fetchData={fetchData}
         handleDelete={handleDelete}
         handleRestore={handleRestore}
         handleDeleteAll={handleDeleteAll}
      />
   );
};

export default DeletedProjectsModal;
