import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/error/error-5.scss";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function Error401() {
   const navigate = useNavigate();

   const goBack = () => navigate(-1);

   return (
      <div className="d-flex flex-column flex-root">
         <div
            className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
            style={{
               backgroundImage: `url(${toAbsoluteUrl("/media/error/bg5.jpg")})`
            }}
         >
            <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
               <h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">
                  Oops!
               </h1>
               <p className="font-weight-boldest display-4">
                  You don't have access to see this page.
               </p>
               <p className="font-size-h3">
                  If you think this is a fault
                  <br />
                  <br />
                  You can back or use our Help Center.
               </p>

               <div className="flexGrow">
                  <button className="btn btn-outline-secondary" onClick={() => goBack()}>
                     Go Back
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
}
