/**
 * Formats a file size in bytes into a human-readable format.
 * @param {number} bytes - The size of the file in bytes.
 * @param {number} [decimalPoint=2] - The number of decimal points to include in the formatted size.
 * @returns {string} A string representing the formatted file size with appropriate units (Bytes, KB, MB, GB, etc.).
 */
export function formatFileSize(bytes, decimalPoint) {
   if (!bytes || bytes === 0) {
      return "0 Bytes";
   }
   const k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));

   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
