import { notification } from "antd";

const useNotification = () => {
   const openNotification = (type, message, description) => {
      notification[type]({
         message: message,
         description: description,
         duration: 3
      });
   };

   return { openNotification };
};

export default useNotification;
