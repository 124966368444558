import React from 'react'
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import './commingSoon.scss'
function ComingSoon() {
  return (
    <div className='coming-soon'>
        <div className="kapsurvey-logo">
        <Link to={-1} className="brand-logo ">
          <img
            alt="logo"
            src={toAbsoluteUrl("/media/logos/kapSurvey-logo.png")}
            className="max-h-20px"
          />
        </Link>
        </div>
        <div className="comingSoon-illustration">
            <h1>Coming Soon!</h1>
        <img
            alt="comingSoon"
            src={toAbsoluteUrl("/media/svg/survey/comingSoon/comingSoon.png")}
          />
          <p>This page is currently under development. </p>
        </div>
    </div>
  )
}

export  {ComingSoon}
