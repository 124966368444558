import { useState } from "react";

export const useStateChanges = (selectedCountry, form) => {
   const [cities, setCities] = useState([]);

   const handleStateChange = async (selectedOption, formFields) => {
      form.setFieldsValue(formFields);

      var headers = new Headers();
      headers.append("X-CSCAPI-KEY", process.env.REACT_APP_COUNTRY_STATE_CITY_API_KEY);

      var requestOptions = {
         method: "GET",
         headers: headers,
         redirect: "follow"
      };

      try {
         const response = await fetch(
            `https://api.countrystatecity.in/v1/countries/${selectedCountry}/states/${selectedOption.role}/cities`,
            requestOptions
         );
         const parseData = await response.json();
         setCities(
            parseData
               .map((item) => ({
                  id: item.id,
                  name: item.name,
                  role: item.iso2
               }))
               .sort((a, b) => a.name.localeCompare(b.name))
         );
      } catch (error) {
         console.log("error", error);
      }
   };

   return { cities, handleStateChange };
};
