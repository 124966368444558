import React from "react";
import Buttons from "../Buttons/Buttons";
import { getStatusClassName } from "../../helpers/statusClassNames";

const MobileData = ({ filteredDataSource, onComplete, onReject, goToSurvey }) => {
   return (
      <>
         {filteredDataSource.length > 0 ? (
            <>
               {filteredDataSource?.map((item) => (
                  <div key={item.id} className="dataContentMobile">
                     <div className="generalDataInfo">
                        <p className={`status ${getStatusClassName(item?.status)}`}>
                           {item.status}
                        </p>
                        <p className="name">{item.collectionRound}</p>
                        <div className="locationData">
                           <div className="position">
                              <span>{item?.locationName}</span>
                              <span>
                                 <b>{item?.surveyName}</b>
                              </span>
                           </div>
                           <div className="position">
                              <span>Deadline</span>
                              <span className="deadline">{item?.tasksDeadline}</span>
                           </div>
                           <div>
                              <p className="collectionType">{item?.collectionType}</p>
                           </div>
                        </div>
                     </div>
                     <>
                        {item.status === "Assigned" && (
                           <Buttons
                              onComplete={onComplete}
                              onReject={onReject}
                              goToSurvey={goToSurvey}
                              text={item}
                              record={item}
                           ></Buttons>
                        )}
                     </>
                  </div>
               ))}
            </>
         ) : (
            <h3>No data</h3>
         )}
      </>
   );
};

export default MobileData;
