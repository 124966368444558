/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AuthService from "../../base/services/authentication.service";
import Login from "./login/Login.jsx";
import Registration from "./register/Registration.jsx";
import AuthLayout from "./layout/AuthLayout";
import LastPage from "./messagePages/LastPage";
import RequestPage from "./messagePages/RequestPage";
import StripePaymentPage from "./payment/StripePaymentPage";
// import StripeSuccessfulPage from "./payment/StripeSuccessfulPage";
import FreeTrialSuccessfulPage from "./payment/FreeTrialSuccessfulPage.jsx";
import ForgotPassword from "./forgot-password/ForgotPassword.jsx";
import Payment from "./payment/Payment.jsx";
import { SessionService } from "../../base/services/session.service.js";
import { withErrorBoundary } from "../../components/ErrorBoundary/withErrorBoundary.jsx";
/* route protection will be applied after regist / auth apis are connected */
import "../../assets/styles/login-1.css";

function AuthPage() {
   const [isAuthorized, setisAuth] = useState(false);

   const location = useLocation();

   useEffect(() => {
      setisAuth(AuthService.isAuthenticated());
   }, [location, setisAuth]);

   const ProtectedRoute = ({ element: Component, ...rest }) => {
      const addPaymentDetails = SessionService.getItem("addPaymentDetails");
      const currentOrgId = SessionService.getItem("currentOrgId");

      if (!addPaymentDetails && !currentOrgId) {
         return <Navigate to="/auth/login" />;
      }
      return <Component {...rest} />;
   };

   return (
      <>
         <Routes>
            {isAuthorized ? (
               <Route path="/*" element={<Navigate to="/" />} />
            ) : (
               <>
                  <Route element={<AuthLayout />}>
                     <Route path="/auth/login" element={<Login />} />
                     <Route path="/auth/registration" element={<Registration />} />
                     <Route path="/auth/payment" element={<ProtectedRoute element={Payment} />} />
                     <Route path="/*" element={<Navigate to="/auth/login" />} />
                  </Route>
                  <Route path="/auth/forgot-password" element={<ForgotPassword />} />
                  <Route path="/auth/success" element={<LastPage />} />
                  <Route path="/auth/request" element={<RequestPage />} />
                  <Route path="/subscription/stripe" element={<StripePaymentPage />} />
                  {/* <Route
                     path="/subscription/stripe/successful"
                     element={<StripeSuccessfulPage />}
                  /> */}
                  <Route path="/auth/freeTrial/successful" element={<FreeTrialSuccessfulPage />} />
               </>
            )}
         </Routes>
      </>
   );
}

const AuthPageWithErrorBoundary = withErrorBoundary(AuthPage);
export { AuthPageWithErrorBoundary as AuthPage };
