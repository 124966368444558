import React from "react";

import "./PreviewQuestionLabel.scss";

function PreviewQuestionLabel({ label, isRequired, hint }) {
   return (
      <div className="preview-question">
         <h5 className={`preview-question-label ${isRequired ? "required" : ""}`}>{label}</h5>
         {hint && <span className="preview-question-hint">{hint}</span>}
      </div>
   );
}

// export default PreviewQuestionLabel;
export default React.memo(PreviewQuestionLabel);
