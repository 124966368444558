import React from "react";
import "./style.scss";

function Test() {
   return (
      <div className="choose-new-survey-method">
         <p>admin page</p>
      </div>
   );
}

export default Test;
