import {
   BeneficiariesIcon,
   DashboardIcon,
   MembersIcon,
   PaymentPlanIcon,
   SurveyTools1Icon,
   TargetsIcon,
   TasksIcon,
   WorkspacesIcon,
   SurveyData1Icon,
   ProfileInformationIcon,
   AccountSummaryIcon,
   SubscriptionPlanIcon,
   LoginInformationIcon,
   DeleteIcon
} from "../_metronic/layout/components/aside/aside-menu/components/SidebarIcons";
import { UserRolesEnum } from "./pages/auth/userRoles.enum";

export const menu = {
   layout: [
      {
         id: "project-dashboard",
         title: "Project Dashboard",
         type: "module",
         logic: "enteredProject",
         icon: DashboardIcon,
         url: "/dashboard",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      {
         id: "menu-workspace-list-admin",
         title: "Workspaces",
         type: "module",
         logic: null,
         icon: "flaticon2-talk",
         url: "/workspace",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization
         ]
      },
      {
         id: "menu-user-management-admin",
         title: "User management",
         type: "module",
         logic: null,
         icon: MembersIcon,
         url: "/user-management",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization
         ]
      },
      {
         id: "menu-payment-plans-admin",
         title: "Payment plans",
         type: "module",
         logic: null,
         icon: PaymentPlanIcon,
         url: "/payment-plans",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager
         ]
      },
      {
         id: "menu-calls-history",
         title: "Project Dashboard",
         type: "module",
         logic: null,
         icon: "flaticon2-talk",
         url: "/dashboard",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      {
         id: "kapSurvey-divider-1",
         title: "Manage Relationships",
         type: "divider",
         logic: "enteredProject",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      // {
      //    id: "menu-rleationships",
      //    title: "Relationships",
      //    type: "module",
      //    logic: "enteredProject",
      //    icon: RelationshipIcon,
      //    url: "/relationships",
      //    role: [
      //       UserRolesEnum.SuperAdmin,
      //       UserRolesEnum.Organization,
      //       UserRolesEnum.AdminOfOrganization,
      //       UserRolesEnum.SeniorProjectManager
      //    ]
      // },
      {
         id: "menu-beneficiaries",
         title: "Beneficiaries",
         type: "module",
         logic: "enteredProject",
         icon: BeneficiariesIcon,
         url: "/beneficiaries/table",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      {
         id: "menu-targets",
         title: "Targets",
         type: "module",
         logic: "enteredProject",
         icon: TargetsIcon,
         url: "/targets",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      {
         id: "menu-task-management",
         title: "Task Management",
         type: "module",
         logic: "enteredProject",
         icon: TasksIcon,
         url: "/task-management/collection",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      {
         id: "kapSurvey-divider-2",
         title: "Manage Surveys",
         type: "divider",
         logic: "enteredProject",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      {
         id: "menu-survey-tools",
         title: "Survey Tools",
         type: "module",
         logic: "enteredProject",
         icon: SurveyTools1Icon,
         url: "/survey/survey-tools",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      {
         id: "menu-survey-data1",
         title: "Survey Data",
         type: "module",
         logic: "enteredProject",
         icon: SurveyData1Icon,
         url: "/survey/survey-data",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      {
         id: "kapSurvey-divider-55",
         title: "Project Settings",
         type: "divider",
         logic: "enteredProject",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      // {
      //    id: "menu-tasks",
      //    title: "Tasks",
      //    type: "module",
      //    logic: "enteredProject",
      //    icon: "flaticon2-setup",
      //    url: "/tasks",
      //    role: [
      //       UserRolesEnum.SuperAdmin,
      //       UserRolesEnum.Organization,
      //       UserRolesEnum.JuniorProjectManager,
      //       UserRolesEnum.AdminOfOrganization,
      //       UserRolesEnum.SeniorProjectManager,
      //       UserRolesEnum.DataCollector
      //    ]
      // },
      {
         id: "menu-members",
         title: "Members",
         type: "module",
         logic: "enteredProject",
         icon: MembersIcon,
         url: "/members",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      {
         id: "deleted-items",
         title: "Deleted items",
         type: "module",
         logic: "enteredProject",
         icon: DeleteIcon,
         url: `/deleted-items`,
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager
         ]
      }
      // {
      //    id: "menu-survey-quick-actions",
      //    title: "Quick Actions",
      //    type: "module",
      //    logic: "enteredProject",
      //    icon: "flaticon2-setup",
      //    url: "/quick-actions",
      //    role: [
      //       UserRolesEnum.SuperAdmin,
      //       UserRolesEnum.Organization,
      //       UserRolesEnum.JuniorProjectManager,
      //       UserRolesEnum.AdminOfOrganization,
      //       UserRolesEnum.SeniorProjectManager
      //    ]
      // }
      // {
      //    id: "menu-Surveys",
      //    title: "Surveys",
      //    type: "module",
      //    icon: "flaticon2-list-1",
      //    url: "/survey",
      //    role: [UserRolesEnum.SuperAdmin],
      //    subMenus: [
      //       {
      //          id: "menu-survey-import",
      //          title: "Import",
      //          type: "item",
      //          icon: "flaticon2-user",
      //          url: "/survey/import",
      //          role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
      //       },
      //       {
      //          id: "menu-survey-new",
      //          title: "New Survey",
      //          type: "item",
      //          icon: "flaticon2-user",
      //          url: "/survey/new",
      //          role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
      //       }
      //    ]
      // },
      // {
      //    id: "menu-members",
      //    title: "Members",
      //    type: "module",
      //    icon: "flaticon2-avatar",
      //    url: "/members",
      //    role: [UserRolesEnum.SuperAdmin],
      //    subMenus: [
      //       {
      //          id: "members-list",
      //          title: "Members List",
      //          type: "item",
      //          icon: "flaticon2-user",
      //          url: "/members/list",
      //          role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
      //       },
      //       {
      //          id: "members-adf",
      //          title: "Add Member",
      //          type: "item",
      //          icon: "flaticon2-user",
      //          url: "/members/new",
      //          role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
      //       },
      //       {
      //          id: "role-add",
      //          title: "Add Role",
      //          type: "item",
      //          icon: "flaticon2-user",
      //          url: "/members/role/add",
      //          role: [UserRolesEnum.SuperAdmin, UserRolesEnum.Manager]
      //       }
      //    ]
      // }
   ],
   adminLayout: [
      {
         id: "kapSurvey-divider-2",
         title: "Admin management",
         type: "divider",
         logic: "enteredProject",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager
         ]
      },
      {
         id: "menu-survey-tools",
         title: "Workspaces",
         type: "module",
         logic: "enteredProject",
         icon: WorkspacesIcon,
         url: "/admin-workspaces",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager
         ]
      },
      {
         id: "menu-survey-data2",
         title: "User management",
         type: "module",
         logic: "enteredProject",
         icon: MembersIcon,
         url: "/admin-user-management",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager
         ]
      },

      {
         id: "admin-deleted-items",
         title: "Deleted items",
         type: "module",
         logic: "enteredProject",
         icon: DeleteIcon,
         url: `/admin-deleted-items`,
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager
         ]
      },
      {
         id: "kapSurvey-divider-3",
         title: "Account settings",
         type: "divider",
         logic: "enteredProject",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.ProjectManager,
            UserRolesEnum.Guest
         ]
      },
      {
         id: "menu-survey-data4",
         title: "Account summary",
         type: "module",
         logic: "enteredProject",
         icon: AccountSummaryIcon,
         url: "/account-summary",
         role: [UserRolesEnum.Organization]
      },
      {
         id: "menu-survey-data5",
         title: "Profile information",
         type: "module",
         logic: "enteredProject",
         icon: ProfileInformationIcon,
         url: "/profile-information",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.ProjectManager
            // UserRolesEnum.Guest
         ]
      },
      {
         id: "menu-survey-data7",
         title: "Log in information",
         type: "module",
         logic: "enteredProject",
         icon: LoginInformationIcon,
         url: "/login-information",
         role: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Organization,
            UserRolesEnum.AdminOfOrganization,
            UserRolesEnum.SeniorProjectManager,
            UserRolesEnum.JuniorProjectManager,
            UserRolesEnum.ProjectManager
            // UserRolesEnum.Guest
         ]
      },
      {
         id: "menu-survey-data6",
         title: "Subscription plans and Payment",
         type: "module",
         logic: "enteredProject",
         icon: SubscriptionPlanIcon,
         url: "/subscription-plans",
         role: [UserRolesEnum.Organization]
      }
   ]
};
