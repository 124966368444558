export const UserRolesEnum = {
   Organization: "d75d01d0-af84-40d8-b37f-ebbbbccf86ed",
   SuperAdmin: "fab4fac1-c546-41de-aebc-a14da6895711",
   JuniorProjectManager: "19485e69-ace9-4c15-54ad-08dbb2ddb282",
   AdminOfOrganization: "a23ccd39-5fe4-44a4-f5f0-08dbb041020b",
   SeniorProjectManager: "6f5b8456-7510-471a-f5ef-08dbb041020b",
   ProjectManager: "afb7b5ef-79f9-41ad-9ba3-f24d5df65391",
   DataCollector: "6e75c185-c1ce-4a84-1426-08dbafa31c5e",
   Guest: "c7d36b3f-6609-4a67-a5c3-926da0a791ca"
};
