import React from "react";
import { Steps } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../assets/styles/login-1.css";
import { useAuthSore } from "../authStore";
import logo from "../../../assets/images/loginImage.svg";
import logo1 from "../../../assets/images/sign-in.png";

import logoMobile from "../../../assets/images/loginLandscape.svg";

function AuthLayout({ children }) {
   const registStep = useAuthSore((state) => state.registStep);

   const location = useLocation();

   return (
      <div className="d-flex flex-column flex-root login-layout">
         {/*begin::Login*/}
         <div
            className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
            id="kt_login"
         >
            {location.pathname === `/auth/login` ? (
               <>
                  <div
                     className="kap-survey-login-aside"
                     style={{
                        backgroundImage: `url(${logo})`
                     }}
                  ></div>
                  <div
                     className="kap-survey-login-aside-mobile"
                     style={{
                        backgroundImage: `url(${logoMobile})`
                     }}
                  ></div>
               </>
            ) : (
               <div
                  className="login-aside d-flex bgi-size-cover bgi-no-repeat"
                  style={{
                     backgroundImage: `url(${logo1})`,
                     width:"600px",
                     minHeight:"500px"
                     
                  }}
               >
                  {/*begin: Aside Container*/}
                  <div className="d-flex flex-row-fluid flex-column align-items-center step-section">
                     <Link to="/" className="flex-column-auto mt-5 pt-20 pt-md-5">
                        <img
                           alt="Logo"
                           className="max-h-70px"
                           src={toAbsoluteUrl("/media/logos/kapsurveylogo1.png")}
                        />
                     </Link>
                     {/* <img   src={toAbsoluteUrl("/media/logos/signin-photo.png")} style={{maxWidth:"100%", marginTop:"40px"}} ></img> */}

                     {/* <div
                        className="flex-row-fluid d-flex flex-column flex-start registration-stepper"
                        style={{ marginTop: "16%" }}
                     >
                        <Steps
                           direction="vertical"
                           current={registStep} //step number
                           items={[
                              {
                                 title: "Create your profile"
                              },
                              {
                                 title: "Select your plan"
                              },
                              {
                                 title: "Review & pay"
                              }
                           ]}
                        />
                     </div> */}
                  </div>
               </div>
            )}

            <div className="d-flex flex-column flex-row-fluid position-relative p-7 layout-content-2">
               {/* <div className="login-page-aside-logo">
                  {/* start:: Aside header */}
                  {/* <a
                     href="https://kapsurvey.com/"
                     className="flex-column-auto mt-5 pt-20 pb-lg-0 pb-10"
                  >
                     <img
                        alt="Logo"
                        className="max-h-40px logo-height"
                        src={toAbsoluteUrl("/media/logos/kapSurvey-logo.png")}
                     />
                  </a>
               </div> */}
               <div className="login-form-content">
                  {/* routes are here */}
                  <Outlet />
               </div>
            </div>
            {/*end::Content*/}
         </div>
         {/*end::Login*/}
      </div>
   );
}

export default AuthLayout;
