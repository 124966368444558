import { createSlice } from "@reduxjs/toolkit";
import { httpService } from "../../base/services/httpService.service";

const initialState = {
   isLoading: false,
   selectedQuestion: "-1",
   surveyData: {},
   surveyMetaInfo: {
      id: 0,
      description: "",
      dataCollectionType: 1,
      collectionGoal: 15,
      defaultLanguage: "en"
   },
   surveyEditData: [],
   translationData: {}
};

const surveySlice = createSlice({
   name: "survey",
   initialState,
   reducers: {
      setLoading(state, { payload }) {
         return {
            ...state,
            loading: payload
         };
      },
      setSelectedQuestion(state, { payload }) {
         state.selectedQuestion = payload;
      },
      setSurveyData(state, { payload }) {
         return {
            ...state,
            surveyData: payload
         };
      },
      setQuestionOrder(state, { payload }) {
         return {
            ...state,
            surveyData: {
               ...state.surveyData,
               sections: payload
            }
         };
      },
      setSections(state, { payload }) {
         return {
            ...state,
            surveyData: {
               ...state.surveyData,
               sections: payload
            }
         };
      },
      setSurveyMetaInfo(state, { payload }) {
         state.surveyEditData = payload;
      },
      setSurveyEditData(state, { payload }) {
         state.surveyEditData = payload;
      },
      setTranslationData(state, { payload }) {
         return {
            ...state,
            translationData: payload
         };
      },
      setTranslationEditData(state, { payload }) {
         state.translationEditData = payload;
      }
   }
});

export const {
   setLoading,
   setSelectedQuestion,
   setSurveyMetaInfo,
   setSurveyEditData,
   setQuestionOrder,
   setSurveyData,
   setSections,
   setQuestions,
   setTranslationData
} = surveySlice.actions;

export function fetchSurveyDataOLD() {
   return async (dispatch, getState) => {
      const dummyData = {
         id: "3fa85f64-5717-4562-b3fc-2c963f66afa1",
         name: "string",
         description: "string",
         createdBy: "string",
         creationDate: "2022-08-08T10:40:47.623Z",
         expiryDate: "2022-08-08T10:40:47.623Z",
         sections: [
            {
               id: "3fa85f64-5717-4562-b3fc-2c963f66afa2",
               description: "This is section 1",
               index: 0,
               questions: [
                  {
                     id: "3fa85f64-5717-4562-b3fc-2c963f66afa3",
                     description: "This is select one question?",
                     isRequired: true,
                     score: 0,
                     type: 1,
                     sectionNo: 0,
                     dynamicOption: [
                        {
                           id: "3fa85f64-5717-4562-b3fc-2c963f66afa4",
                           optionLabel: "Option 1",
                           optionLabelTwo: "Option 1.1",
                           indexOne: 0,
                           indexTwo: 0,
                           starValue: 0
                        },
                        {
                           id: "3fa85f64-5717-4562-b3fc-2c963f66afa44",
                           optionLabel: "Option 2",
                           optionLabelTwo: "Option 2.1",
                           indexOne: 0,
                           indexTwo: 0,
                           starValue: 0
                        }
                     ]
                  },
                  {
                     id: "3fa85f64-5717-4562-b3fc-2c963f66afa5",
                     description: "What is your name?",
                     isRequired: true,
                     score: 0,
                     type: 4,
                     sectionNo: 0
                  }
               ]
            }
         ]
      };

      dispatch(setSurveyData(dummyData));
   };
}

export function fetchSurveyData(payload, successCallback, errCB) {
   return async (dispatch, getState) => {
      await dispatch(setLoading(true));
      httpService
         .post(
            "/survey/getfull",
            {
               surveyId: payload.id,
               getAnswer: payload.getAnswer
            },
            (res) => {
               if (res.data[0].sections?.length === 0) {
                  dispatch(setSurveyData(res.data[0]));

                  httpService.post(
                     "/survey/section",
                     {
                        surveyId: payload.id,
                        title: "Untitled section",
                        description: "Section description",
                        index: 0
                     },
                     (res) => {
                        httpService.post(
                           "/survey/question",
                           {
                              text: "Untitled question",
                              isRequired: false,
                              sectionId: res.data.id,
                              score: 0,
                              questionType: 5,
                              index: 0
                           },
                           () => {
                              dispatch(fetchSurveyData(payload));
                           }
                        );
                        dispatch(fetchSurveyData(payload));
                     }
                  );
               }
               dispatch(setSurveyData(res.data[0]));

               // if (res.data[0].sections.length === 0) {
               //    dispatch(
               //       setSections([
               //          {
               //             id: "dummy-section-1",
               //             title: "Untitled section",
               //             description: "Section description",
               //             index: 0,
               //             questions: [
               //                {
               //                   id: "dummy-question-1",
               //                   text: "Untitled question",
               //                   isRequried: false,
               //                   score: 0,
               //                   questionType: 4,
               //                   index: 0
               //                }
               //             ]
               //          }
               //       ])
               //    );
               // }

               if (successCallback !== null && successCallback !== undefined) successCallback();
            },
            (res) => {
               if (errCB !== null && errCB !== undefined) errCB(res);
            }
         )
         .finally(() => {
            dispatch(setLoading(false));
         });
   };
}

export function getTranslationData(payload, successCallback, errCB) {
   return async (dispatch, getState) => {
      await dispatch(setLoading(true));
      httpService
         .post(
            "/survey/getsurveytranslation",
            {
               surveyId: payload?.id,
               cultureId: payload?.cultureId
            },
            (res) => {
               dispatch(setTranslationData(res.data));
               if (successCallback !== null && successCallback !== undefined) successCallback();
            },
            (res) => {
               if (errCB !== null && errCB !== undefined) errCB(res);
            }
         )
         .finally(() => {
            dispatch(setLoading(false));
         });
   };
}

export default surveySlice.reducer;
