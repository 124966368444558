import React, { useEffect } from "react";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import PhoneInput from "react-phone-input-2";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";

import "react-phone-input-2/lib/style.css";
import "./PhoneNumber.scss";

export const PhoneNumber = React.memo(
   ({ props, hasErrors, surveyId, userId, submissionId, label, hint, defaultValue }) => {
      const { id, questionType, isRequired, answers } = props;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const hasSubmissionId = Boolean(submissionId);

      const submissionAnswer = hasSubmissionId
         ? answers?.find((answer) => answer?.submissionId === submissionId)?.answerDetail?.number
         : null;

      const value = hasSubmissionId ? submissionAnswer : inputState?.answerDetail?.number ?? null;

      const passConstraint = useEvaluateConstraint(props.constraint, value);

      const showErrors =
         hasErrors && isRequired && (!inputState || !inputState?.answerDetail?.number);

      const onChange = (e) => {
         if (hasSubmissionId) {
            return;
         }
         if (!e) {
            setInputs({
               target: {
                  name: id,
                  value: ""
               }
            });
         } else {
            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     questionType: questionType,
                     name: props.name,
                     skipLogicAnswer: e,
                     answerDetail: {
                        number: e
                     }
                  }
               }
            });
         }
      };

      useEffect(() => {
         if (defaultValue) {
            onChange(defaultValue);
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultValue]);

      return (
         <div className="phone_number-submission-type-wrapper">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />

            <PhoneInput
               className={`phone_number-submission-type-wrapper--input ${
                  showErrors || (passConstraint !== null && !passConstraint) ? "error" : ""
               }`}
               country={"us"}
               value={String(value)}
               onChange={onChange}
               disabled={hasSubmissionId}
               placeholder="000 000 0000"
               inputProps={{
                  name: id,
                  required: isRequired,
                  readOnly: hasSubmissionId
               }}
            />

            <span className="number-submission-type-wrapper--error">
               {showErrors ? "This field is required" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
