import React, { useMemo } from "react";
import { useTranslateStore } from "../../../surveyStore";

import "./PreviewQuestionConstraints.scss";

function PreviewQuestionConstraints({ constraintMessages }) {
   const { toLanguage } = useTranslateStore();

   const message = useMemo(
      () =>
         constraintMessages.find(({ languageId }) => languageId === toLanguage)?.text ??
         constraintMessages[0]?.text,
      [constraintMessages, toLanguage]
   );

   return constraintMessages?.length ? (
      <div className="preview-question-constraint">
         <span>- {message}</span>
      </div>
   ) : null;
}

// export default PreviewQuestionConstraints;
export default React.memo(PreviewQuestionConstraints);
