import React, { Fragment } from "react";
import useOnlineStore from "../../../../../../utils/useOnlineStore";
import { QuestionCircleOutlined } from "@ant-design/icons";

import "./OnlineIndicator.scss";

function OnlineIndicator(props) {
   const isOnline = useOnlineStore((state) => state.isOnline);

   const color = isOnline ? "#0E9349" : "#FC9403";

   return (
      <Fragment>
         <div className="online-indicator">
            <span
               className="online-indicator--status"
               style={{
                  color,
                  border: `1px solid ${color}`,
                  backgroundColor: "#FFF9F0"
               }}
            >
               <span
                  className="online-indicator--status---indicator"
                  style={{
                     backgroundColor: color
                  }}
               />
               <span>{isOnline ? "Online" : "Offline"}</span>
            </span>
            <QuestionCircleOutlined
               onClick={props.indicatorClickHandler}
               className="online-indicator--info hide-on-print"
            />
         </div>
      </Fragment>
   );
}

export default OnlineIndicator;
