import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const UNIX_EPOCH = "1970-01-01"; // January 1, 1970 (the Unix Epoch)

const Functions = {
   dayjs: dayjs,

   selected: (value, choice) => {
      return value?.includes(choice);
   },

   round: (number, decimalPlaces) => {
      if (!decimalPlaces) {
         return Math.floor(number);
      }
      const factor = Math.pow(10, decimalPlaces);
      return Math.floor(number * factor) / factor;
   },

   /**
    * Returns the current date without a time component.
    * @returns {dayjs.Dayjs} dayjs object
    */
   today: () => {
      return dayjs().startOf("day");
   },

   date: (dateTime) => {
      if (typeof dateTime === "number") {
         const epoch = dayjs(UNIX_EPOCH);
         return epoch.add(dateTime, "day").startOf("day");
      }

      return dayjs(dateTime).startOf("day");
   },

   countSelected: (value) => {
      return Array.isArray(value) ? value.length : 0;
   },

   /**
    * Returns the string at the n-th position of the space_delimited_array. (The array is zero-indexed.)
    * Returns an empty string if the index does not exist.
    * @param {*} value
    * @param {number} index
    */
   selectedAt: (value, index) => {
      if (!value || !Array.isArray(value)) {
         return "";
      }
      const selectedValue = value.at(index);
      return selectedValue ?? "";
   },

   /**
    * Returns an integer equal to the 1-indexed position of the current node within the node defined by xpath.
    * @param {string} xpath - The xpath of the node to find the position of.
    * @returns {number} The 1-indexed position of the current node within the node defined by xpath.
    */
   // position: (xpath) => {
   //    const paragraphCount = document.evaluate(
   //       "count(//..)",
   //       document,
   //       null,
   //       XPathResult.ANY_TYPE,
   //       null
   //    );

   //    console.log(
   //       `This document contains ${paragraphCount.numberValue} paragraph elements.`,
   //       paragraphCount
   //    );
   //    // for (let i = 0; i < nodes.snapshotLength; i++) {
   //    //    const snapshotItem = nodes.snapshotItem(i);
   //    //    console.log("🚀 ~ snapshotItem:", snapshotItem);

   //    //    if (snapshotItem === this) {
   //    //       return i + 1;
   //    //    }
   //    // }
   //    return 0;
   // },

   /**
    * Returns the label value, in the active language, associated with the choice_name in the list of choices for the select_question.
    */
   jrChoiceName: (choice_name, choices) => {
      if (!choice_name || !choices) return "";
      const choice = choices?.find((item) => String(item.value) === String(choice_name));
      return choice ? choice.label : "";
   },

   /**
    * Returns True if the string contains the substring.
    */
   contains: (string, substring) => {
      return string?.includes(substring);
   },

   /**
    * Returns the response value of question name from the repeat-group group, in iteration i.
    */
   // indexedRepeat: () => {},

   /**
    * Joins the members of nodeset, using the string separator.
    */
   // join: (separator, nodeset) => {
   //    return nodeset?.join(separator);
   // }

   /**
    * Returns the substring of a string beginning at the index start and
    * extending to (but not including) index end (or to the end of string if end is not provided).
    * @param {string} string - The input string to extract the substring from.
    * @param {number} start - The zero-indexed position to start extraction.
    * @param {number} [end] - The zero-indexed position to end extraction (not including this position).
    * @returns {string} - The extracted substring.
    */
   substr: (string, start, end) => {
      if (typeof string !== "string") {
         return ""; // Return an empty string if input is not a string
      }

      const strLength = string.length;

      // Ensure start is within bounds
      const startIndex = Math.max(0, Math.min(strLength, start));

      // If end is provided, ensure it is within bounds, otherwise use strLength
      const endIndex = end !== undefined ? Math.min(strLength, end) : strLength;

      // Return empty string if start is greater than end
      if (startIndex > endIndex) {
         return "";
      }

      // Return the substring
      return string.substring(startIndex, endIndex);
   }
};

const comparisonOperators = Object.freeze({
   "=": "==",
   "!=": "!==",
   ">": ">",
   "<": "<",
   ">=": ">=",
   "<=": "<="
});

const REGEX = Object.freeze({
   regex: /regex\(value*,\s*'(.*)'\)/,
   ifConditions: /if\s*\(([^,]+),\s*([^,]+?),\s*(if\(.*\)|[^,]+?)\)/,
   countSelected: /count-selected\(value\)/gm,
   selectedAt: /selected-at\((.*)\)/,
   // position: /position\((.*?)\)/,
   jrChoiceName: /jr:choice-name\(\s*(.+?)\s*,\s*'([^']+)'\s*\)/,
   // indexedRepeat: /indexed-repeat\(.*\)/gm,
   not: /not\(/gm,
   and: /\band\b/gm,
   or: /\bor\b/gm,
   div: /\bdiv\b/gm,
   mod: /\bmod\b/gm,
   value: /(?<!\d)\.(?!\.)(?!\d)/gm,
   comparison: /!=|(?<![=!><])=(?!=)/g,
   foreignInputValue: /\${([^}]+)}/gm,
   complexDates: /(\${.*}|value|today\(\)|date\(.*\))\s*(-|\+)\s*(\${.*}|value|today\(\)|date\(.*\))/
});

const patterns = [
   [
      REGEX.value,
      (constraint) => {
         return constraint.replace(REGEX.value, "value");
      }
   ],

   [
      REGEX.regex,
      (constraint) => {
         const patternMatch = constraint.match(REGEX.regex);

         if (patternMatch && patternMatch.length > 1) {
            const [og, matched] = patternMatch;
            const regex = new RegExp(matched);
            const jsRegex = `${regex}.test(value)`;

            return constraint.replace(og, jsRegex);
         } else {
            throw new Error(constraint);
         }
      }
   ],
   [
      REGEX.ifConditions,
      (constraint) => {
         function parseIfCondition(condition) {
            let match = condition.match(REGEX.ifConditions);

            if (!match) {
               return condition.trim(); // Return the condition if it doesn't match the if pattern
            }

            const [, expression, thenPart, elsePart] = match;

            const parsedThen = parseIfCondition(thenPart);
            const parsedElse = parseIfCondition(elsePart);

            // Assuming variable format is ${var_name} and value is a string or number
            const parsedExpression = expression
               .trim()
               .replace(
                  /\$\{([^}]+)\}\s*([=!<>]+)\s*'([^']+)'/g,
                  (__, variable, operator, value) => {
                     return `relatedInputs['${variable}'] ${comparisonOperators[operator]} '${value}'`;
                  }
               );

            return `(${parsedExpression} ? ${parsedThen} : ${parsedElse})`;
         }
         const ternaryExpression = parseIfCondition(constraint);

         return ternaryExpression;
      }
   ],
   [REGEX.not, (constraint) => constraint.replace(REGEX.not, "!(")],
   [REGEX.and, (constraint) => constraint.replace(REGEX.and, "&&")],
   [REGEX.or, (constraint) => constraint.replace(REGEX.or, "||")],
   [REGEX.div, (constraint) => constraint.replace(REGEX.div, "/")],
   [REGEX.mod, (constraint) => constraint.replace(REGEX.mod, "%")],
   [
      REGEX.countSelected,
      (constraint) => constraint.replace(REGEX.countSelected, "countSelected(value)")
   ],
   [
      REGEX.selectedAt,
      (constraint) =>
         constraint.replace(REGEX.selectedAt, (match, params) => {
            if (!match) {
               throw new Error(constraint);
            }
            return `selectedAt(${params})`;
         })
   ],
   // [
   //    REGEX.position,
   //    (constraint) =>
   //       constraint.replace(REGEX.position, (match, params) => {
   //          return `position('${params}')`;
   //       })
   // ],
   [
      REGEX.comparison,
      (constraint) => {
         return constraint.replace(REGEX.comparison, (match) => {
            if (match === "!=") {
               return "!==";
            } else if (match === "=") {
               return "==";
            }
            return match;
         });
      }
   ],

   [
      REGEX.complexDates,
      (constraint) => {
         return constraint.replace(REGEX.complexDates, (match, left, operator, right) => {
            const nrOfDays = 1000 * 60 * 60 * 24;

            if (match) {
               return `${left}?.$isDayjsObject || ${right}?.$isDayjsObject ? ((${match}) / ${nrOfDays}) : ${match}`;
            } else {
               throw new Error(constraint);
            }
         });
      }
   ],
   [
      REGEX.jrChoiceName,
      (constraint) => {
         return constraint.replace(REGEX.jrChoiceName, (match, param1, param2) => {
            return `jrChoiceName(${param1}, meta)`;
         });
      }
   ],
   [
      REGEX.foreignInputValue,
      (constraint) => {
         return constraint.replace(REGEX.foreignInputValue, (match, inputName) => {
            if (!match) {
               throw new Error(constraint);
            }
            return `relatedInputs['${inputName}']`;
         });
      }
   ]
];

function applyReplacements(inputString) {
   let modifiedString = inputString;
   patterns.forEach(([regex, replacementFunction]) => {
      if (regex.test(modifiedString)) {
         modifiedString = replacementFunction(modifiedString);
      }
   });
   return modifiedString;
}

export { REGEX, patterns, applyReplacements, Functions };
