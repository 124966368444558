import React from "react";

import dayjs from "dayjs";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers/AssetsHelpers";
import OnlineIndicator from "./OnlineIndicator";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Fragment, useState } from "react";
import PreviewModal from "./PreviewModal";

import "./PreviewBanner.scss";

dayjs.extend(advancedFormat);

const PreviewBanner = (props) => {
   const [isModalOpen, setIsModalOpen] = useState({
      details: false,
      onlineStatus: false
   });

   const handleToggleModal = (modalName) => () => {
      setIsModalOpen((prevState) => ({
         ...prevState,
         [modalName]: !prevState[modalName]
      }));
   };

   return (
      <Fragment>
         <div className="preview-banner">
            <div className="preview-banner--info">
               <div className="preview-banner--info---status">
                  <OnlineIndicator indicatorClickHandler={handleToggleModal("onlineStatus")} />
               </div>
               <div className="preview-banner--info---desc">
                  <h5>
                     Deadline:{" "}
                     {props.surveyEndDate
                        ? dayjs(props.surveyEndDate).format("MMMM Do, YYYY")
                        : "Invalid date"}
                  </h5>
                  <h2>{props.surveyTitle}</h2>
                  <p>{props.surveyDescription}</p>
                  <Button
                     type="text"
                     onClick={handleToggleModal("details")}
                     className="hide-on-print"
                  >
                     Read more <RightOutlined />
                  </Button>
               </div>
            </div>
            <div className="preview-banner--image">
               <img
                  src={toAbsoluteUrl("/media/svg/survey/PreviewBanner.svg")}
                  alt="Survey banner"
               />
            </div>
         </div>

         <Fragment>
            {/* Survey details Modal - START */}
            <PreviewModal
               hasCloseButton
               title={props.surveyTitle}
               isModalOpen={isModalOpen.details}
               setIsModalOpen={handleToggleModal("details")}
            >
               <div>{props.surveyDescription}</div>
            </PreviewModal>
            {/* Survey details Modal - END */}

            {/* Online status modal - START */}
            <PreviewModal
               hasCloseButton
               title={"Form works offline!"}
               isModalOpen={isModalOpen.onlineStatus}
               setIsModalOpen={handleToggleModal("onlineStatus")}
            >
               <div>
                  <div>
                     This form is now fully accessible even without an Internet connection on your
                     device. Make sure to bookmark it for quick offline access anytime.
                  </div>
                  <br />
                  <div>
                     Your records are securely stored and queued directly in your browser until
                     you're back online. Once you revisit your app after gaining internet
                     connection, all records will be automatically submitted.
                  </div>
                  <br />
                  <div>
                     Only after a record has been successfully submitted, it will be removed from
                     the queue. You can safely close down your browser and device with items in the
                     queue.
                  </div>
               </div>
            </PreviewModal>
            {/* Survey details Modal - END */}
         </Fragment>
      </Fragment>
   );
};

export default React.memo(PreviewBanner);
