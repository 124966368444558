import { useState } from "react";
import { LocalStorageService } from "../services/local-storage.service";

const useReactivatedAccountModal = () => {
   const [reactivatedAccountModal, setReactivatedAccountModal] = useState(
      LocalStorageService.getItem("reactivatedAccount")
   );
   const [addPaymentMethodAccountModal, setAddPaymentMethodAccountModal] = useState(
      LocalStorageService.getItem("addPaymentMethod")
   );

   const removeLocalStorageKey = () => {
      LocalStorageService.removeItem("reactivatedAccount");
      setReactivatedAccountModal(false);
   };

   const removeLocalStoragePaymentKey = () => {
      LocalStorageService.removeItem("addPaymentMethod");
      setAddPaymentMethodAccountModal(false);
   };

   return {
      reactivatedAccountModal,
      removeLocalStorageKey,
      addPaymentMethodAccountModal,
      removeLocalStoragePaymentKey
   };
};

export default useReactivatedAccountModal;
