import React from "react";
import PaymentInformation from "../../../components/Payments/PaymentInformation";
import BillingHistory from "../../../components/Payments/BillingHistory";
import { withErrorBoundary } from "../../../components/ErrorBoundary/withErrorBoundary";

const SubscriptionPage = () => {
   return (
      <>
         <PaymentInformation />
         <BillingHistory></BillingHistory>
      </>
   );
};

export default withErrorBoundary(SubscriptionPage);
