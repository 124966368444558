import { notification } from "antd";
import { useMemo } from "react";
/**
 * @typedef {"minimal" | "search" | "quick" | "columns-pack" | "columns" | "columns-n" | "no-buttons" | "image-map" | "likert" | "map" | "label" | "list-nolabel" | "list"} Appearance
 */
const appTypes = [
   "minimal",
   "search",
   "quick", // select_one select_one_from_file and mobile only
   "columns-pack",
   "columns",
   "columns-n",
   "no-buttons",
   "image-map",
   "likert", // select_one select_one_from_file
   "map", // select_one select_one_from_file and mobile only
   "label",
   "list-nolabel",
   "list"
];
const APPEARANCES = new Set(appTypes);

/**
 * Custom hook for evaluating the appearance of a question.
 * @returns {Array<Appearance>} - List of appearances
 */
const useEvaluateAppearance = ({ appearance }) => {
   return useMemo(() => {
      if (!appearance) {
         return [];
      }
      const appearanceList = appearance.split("and").map((item) => item.trim());

      appearanceList.forEach((element) => {
         if (!APPEARANCES.has(element)) {
            notification.error({
               message: "Invalid appearance",
               description: `Appearance ${element} is not valid.`
            });
         }
      });

      return appearanceList;
   }, [appearance]);
};

export default useEvaluateAppearance;
