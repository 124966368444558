import { Checkbox, Table as AntTable } from "antd";
import React, { useState } from "react";
import "./Table.scss";
import useNotification from "../../base/hooks/useNotification";

const Table = ({
   dataSource = [],
   columns,
   rowKey,
   pagination,
   handlePagination = () => {},
   selectedDataCollectors = [],
   handleSelectedDataCollectors = () => {},
   loadData,
   isTasksMainPage = false,
   isEnumeratorsPage = false,
   shouldSelectRows = false,
   numberOfInterviews,
   isSuspicious = false,
   fixedWidth = false
}) => {
   const [selectedRowKeys, setSelectedRowKeys] = useState([]);
   const { openNotification } = useNotification();

   let sliceSelectedDataCollectors = selectedDataCollectors.slice(0, numberOfInterviews);

   const onRow = (record, index) => ({
      onClick: () => {
         const newSelectedRowKeys = isEnumeratorsPage
            ? [...sliceSelectedDataCollectors]
            : [...selectedRowKeys];
         const keyIndex = newSelectedRowKeys.indexOf(record.key);
         if (keyIndex >= 0) {
            newSelectedRowKeys.splice(keyIndex, 1);
         } else {
            newSelectedRowKeys.push(record.key);
         }
         onSelectChange(newSelectedRowKeys);
      },
      style: {
         backgroundColor: isSuspicious[index] && "#ed5249",
         color: isSuspicious[index] && "black"
      }
   });

   const rowSelection = {
      selectedRowKeys: isEnumeratorsPage ? sliceSelectedDataCollectors : selectedRowKeys,
      onChange: (newSelectedRowKeys) => {
         if (!isEnumeratorsPage) {
            setSelectedRowKeys(newSelectedRowKeys);
            handleSelectedDataCollectors(newSelectedRowKeys);
         } else {
            if (newSelectedRowKeys.length > numberOfInterviews) {
               setSelectedRowKeys(newSelectedRowKeys.slice(0, numberOfInterviews));
               handleSelectedDataCollectors(newSelectedRowKeys.slice(0, numberOfInterviews));
            }
            if (selectedDataCollectors.length == numberOfInterviews) {
               setSelectedRowKeys([]);
               handleSelectedDataCollectors([]);
            }

            if (newSelectedRowKeys.length < numberOfInterviews) {
               setSelectedRowKeys(newSelectedRowKeys.slice(0, numberOfInterviews));
               handleSelectedDataCollectors(newSelectedRowKeys.slice(0, numberOfInterviews));
            }
         }
      },
      selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
      renderCell: (checked, record, index, originNode) => <Checkbox checked={checked} />
   };

   const onSelectChange = (selectedRowKeys) => {
      if (selectedRowKeys.length - 1 != numberOfInterviews || !isEnumeratorsPage) {
         setSelectedRowKeys(selectedRowKeys);
         handleSelectedDataCollectors(selectedRowKeys);
         return;
      }
      openNotification(
         "error",
         "",
         "Number of data collectors can not be greater than the number of tasks!"
      );
   };

   return (
      <AntTable
         className="antTableData"
         onRow={onRow}
         rowKey={rowKey ?? "id"}
         rowSelection={!shouldSelectRows && rowSelection}
         columns={columns}
         dataSource={dataSource}
         scroll={{
            x: isEnumeratorsPage ? "auto" : isTasksMainPage ? 1200 : fixedWidth ? 1100 : 1500,
            y: 600
         }}
         pagination={
            pagination
               ? {
                    ...pagination,
                    position: ["bottomRight"],
                    showSizeChanger: false,
                    onChange: (page, pageSize) => {
                       handlePagination(page, pageSize);
                    }
                 }
               : false
         }
         loading={loadData}
      />
   );
};

export default Table;
