import React from "react";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import "./PreviewModal.scss";

const PreviewModal = (props) => {
   const handleOk = () => {
      props.handleOk?.();
      props.setIsModalOpen(false);
   };

   const handleCancel = () => {
      props.handleCancel?.();
      props.setIsModalOpen(false);
   };

   return (
      <Modal
         prefixCls="preview-modal"
         width={props.width ? props.width : 713}
         closable
         open={props.isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         footer={
            props.hasCloseButton ? (
               <Button
                  type="default"
                  onClick={handleCancel}
                  style={{
                     borderColor: "#5770CB",
                     color: "#5770CB"
                  }}
               >
                  Close
               </Button>
            ) : (
               props.footer
            )
         }
         closeIcon={
            <CloseOutlined
               style={{
                  color: "#5770CB",
                  fontSize: "20px"
               }}
            />
         }
      >
         <header className="preview-modal--title">
            <h3 style={props.titleStyle}>{props.title}</h3>
         </header>
         <main className="preview-modal--content">{props.children}</main>
      </Modal>
   );
};

export default PreviewModal;
