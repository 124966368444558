import { v4 as uuidv4 } from "uuid";

export const getCurrentPathname = () => {
   const pathnameArray = window.location.pathname.split("/");
   return pathnameArray[pathnameArray.length - 1];
};

export const debounce = (func, delay) => {
   let timeoutId;

   return function(...args) {
      if (timeoutId) {
         clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
         func.apply(this, args);
      }, delay);
   };
};

export const answerPayload = async (questionId, formData, index, inputs) => {
   const types = {
      34: "text",
      32: "choices",
      33: "choices",
      35: "number",
      36: "number",
      37: "text",
      38: "text",
      40: "text",
      100: "number",
      104: "text",
      106: "answerFiles",
      112: "questionChoice",
      113: "text",
      275: "number",
      277: "Matrix",
      278: "Ranking",
      276: "Rating"
   };

   switch (questionId) {
      case 32:
      case 33:
         for (let i = 0; i < Object.values(inputs)[index].answerDetail.choices.length; i++) {
            formData.append(
               `answers[${index}].answerDetail.choices[${i}]`,
               Object.values(inputs)[index].answerDetail.choices[i]
            );
         }
         break;
      case 278:
         for (let i = 0; i < Object.values(inputs)[index].answerDetail.answerRankings.length; i++) {
            formData.append(
               `answers[${index}].answerDetail.answerRankings[${i}].choiceId`,
               Object.values(inputs)[index].answerDetail.answerRankings[i].choiceId
            );
            formData.append(
               `answers[${index}].answerDetail.answerRankings[${i}].rank`,
               Object.values(inputs)[index].answerDetail.answerRankings[i].rank
            );
         }
         break;
      case 277:
         for (let i = 0; i < Object.values(inputs)[index].answerDetail.answerMatrix.length; i++) {
            formData.append(
               `answers[${index}].answerDetail.answerMatrix[${i}].choiceId`,
               Object.values(inputs)[index].answerDetail.answerMatrix[i].choiceId
            );
            formData.append(
               `answers[${index}].answerDetail.answerMatrix[${i}].secondChoiceId`,
               Object.values(inputs)[index].answerDetail.answerMatrix[i].secondChoiceId
            );
            formData.append(
               `answers[${index}].answerDetail.answerMatrix[${i}].text`,
               Object.values(inputs)[index].answerDetail.answerMatrix[i].text
            );
         }
         break;
      case 276:
         for (let i = 0; i < Object.values(inputs)[index].answerDetail.answerRatings.length; i++) {
            formData.append(
               `answers[${index}].answerDetail.answerRatings[${i}].choiceId`,
               Object.values(inputs)[index].answerDetail.answerRatings[i].choiceId
            );
            formData.append(
               `answers[${index}].answerDetail.answerRatings[${i}].secondChoiceId`,
               Object.values(inputs)[index].answerDetail.answerRatings[i].secondChoiceId
            );
         }
         break;
      case 106: {
         const answerFileBase64 = Object.values(inputs)[index].answerDetail.answerFileBase64;
         const answerFiles = Object.values(inputs)[index].answerDetail.answerFiles;

         if (answerFileBase64) {
            try {
               const response = await fetch(answerFileBase64.data);

               const blob = await response.blob();
               const file = new File([blob], answerFileBase64.name, { type: blob.type });
               formData.append(`answers[${index}].answerDetail.answerFiles`, file);
            } catch (error) {
               console.error("Error fetching answer file:", error);
            }
         } else {
            formData.append(`answers[${index}].answerDetail.answerFiles`, answerFiles);
         }
         break;
      }
      case 113:
         formData.append(
            `answers[${index}].answerDetail.text`,
            Object.values(inputs)[index].answerDetail.text || null
         );
         break;
      default:
         formData.append(
            `answers[${index}].answerDetail.${types[questionId]}`,
            Object.values(inputs)[index].answerDetail[types[questionId]]
         );
         break;
   }
};

export function getGeolocation() {
   return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
         navigator.geolocation.getCurrentPosition(
            (position) => {
               const geolocation = {
                  lat: position.coords.latitude,
                  long: position.coords.longitude
               };
               resolve(geolocation);
            },
            (error) => {
               reject(error);
            }
         );
      } else {
         reject("Geolocation is not available in this browser.");
      }
   });
}

const createQuestion = (question) => {
   const id = uuidv4();
   return {
      itemLabel: "Question",
      id: id,
      surveyItem: {
         title: "",
         name: "",
         description: null,
         questionType: question.questionType,
         order: question.order,
         sectionId: question.sectionId,
         created: true,
         isRequired: false,
         questionLabels: [],
         surveyQuestionHints: [],
         selectQuestionChoices: [],
         range: null,
         secondChoices: [],
         id: id
      }
   };
};

const createInnerSection = (order) => {
   const id = uuidv4();
   return {
      itemLabel: "Section",
      id: id,
      sectionId: id,
      surveyItem: {
         description: "",
         order: order
      },
      innerItems: [],
      created: true
   };
};

// export const addQuestion = (survey, payload) => {
//    const { order, sectionId } = payload;
//    survey.forEach((item) => {
//       if (item.itemLabel === "Section") {
//          if (item.id !== sectionId) {
//             addQuestion(item.innerItems, payload);
//          } else {
//             console.log("dwdw ", item);
//             for (let i = order - 1; i < item.innerItems.length; i++) {
//                item.innerItems[i].surveyItem.order += 1;
//             }
//             item.innerItems.splice(order, 0, createQuestion(payload));
//          }
//       }
//    });
// };
export const addQuestion = (survey, payload) => {
   const { order, sectionId } = payload;

   survey.forEach((item) => {
      if (item.itemLabel === "Section" && item.id === sectionId) {
         console.log("order ", order, item.innerItems);

         item.innerItems.splice(order - 1, 0, createQuestion(payload));

         item.innerItems.forEach((innerItem, index) => {
            innerItem.surveyItem.order = index + 1;
         });
      } else if (item.innerItems) {
         addQuestion(item.innerItems, payload);
      }
   });
};

export const addInnerSection = (survey, payload) => {
   const { order, sectionId } = payload;
   survey.forEach((item) => {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            addInnerSection(item.innerItems, payload);
         } else {
            for (let i = order - 1; i < item.innerItems.length; i++) {
               item.innerItems[i].surveyItem.order += 1;
            }
            item.innerItems.splice(order, 0, createInnerSection(order));
         }
      }
   });
};

const getParentSurveyOrder = (data, targetId) => {
   if (Array.isArray(data)) {
      for (const item of data) {
         const parentSurveyOrder = getParentSurveyOrder(item, targetId);
         if (parentSurveyOrder !== null) {
            return parentSurveyOrder;
         }
      }
   } else {
      if (data.id === targetId) {
         return data.surveyItem.order;
      }

      if (data?.innerItems && data?.innerItems?.length > 0) {
         for (const item of data.innerItems) {
            if (item.id === targetId) {
               return data.surveyItem.order;
            }

            const parentSurveyOrder = getParentSurveyOrder(item, targetId);
            if (parentSurveyOrder !== null) {
               return parentSurveyOrder;
            }
         }
      }
   }

   return null;
};

export const addSection = (survey, targetId) => {
   const parentOrder = getParentSurveyOrder(survey, targetId);

   for (let i = parentOrder; i < survey.length; i++) {
      survey[i].surveyItem.order += 1;
   }
   survey.splice(parentOrder + 1, 0, createInnerSection(parentOrder + 1));
};
export const addFirstSection = (survey) => {
   for (let i = 0; i < survey.length; i++) {
      survey[i].surveyItem.order += 1;
   }
   survey.splice(0, 0, createInnerSection(1));
};

export const deleteQuestion = (survey, sectionId, questionId) => {
   for (const item of survey) {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            deleteQuestion(item.innerItems, sectionId, questionId);
         } else {
            const index = item.innerItems.findIndex((item) => item.id === questionId);
            if (index > -1) {
               for (let i = index + 1; i < item.innerItems.length; i++) {
                  item.innerItems[i].surveyItem.order -= 1;
               }
               item.innerItems.splice(index, 1);
               break;
            }
         }
      }
   }
};

export const deleteSection = (survey, sectionId) => {
   for (const item of survey) {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            deleteSection(item.innerItems, sectionId);
         } else {
            const index = survey.findIndex((item) => item.id === sectionId);
            if (index > -1) {
               for (let i = index + 1; i < survey.length; i++) {
                  survey[i].surveyItem.order -= 1;
               }
               survey.splice(index, 1);
               break;
            }
         }
      }
   }
};

const generateDummyId = () => {
   return (
      "dummy" +
      Math.random()
         .toString(36)
         .substr(2, 9)
   );
};

export const duplicateQuestion = (survey, sectionId, questionId) => {
   survey.forEach((item) => {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            duplicateQuestion(item.innerItems, sectionId, questionId);
         } else {
            const index = item.innerItems.findIndex((item) => item.id === questionId);
            if (index > -1) {
               for (let i = index + 1; i < item.innerItems.length; i++) {
                  item.innerItems[i].surveyItem.order += 1;
               }

               const id = uuidv4();
               const questionItems = {
                  ...item.innerItems[index].surveyItem,
                  order: item.innerItems[index].surveyItem.order + 1,
                  id: id,
                  duplicated: true,
                  questionLabels: [
                     {
                        text: item.innerItems[index].surveyItem.questionLabels[0].text
                     }
                  ],
                  selectQuestionChoices: item.innerItems[
                     index
                  ].surveyItem.selectQuestionChoices?.map((item) => ({
                     id: generateDummyId(),
                     choiceLabels: [{ id: undefined, text: item.choiceLabels[0].text }]
                  })),
                  secondChoices: item.innerItems[index].surveyItem.secondChoices?.map((item) => ({
                     id: generateDummyId(),
                     choiceLabels: [{ id: undefined, text: item.choiceLabels[0].text }]
                  }))
               };

               item.innerItems.splice(index, 0, {
                  ...item.innerItems[index],
                  id: id,
                  surveyItem: questionItems
               });
            }
         }
      }
   });
   console.log({ survey });
};

export const updateQuestionHint = (survey, sectionId, questionId, text) => {
   survey.forEach((item) => {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            updateQuestionHint(item.innerItems, sectionId, questionId, text);
         } else {
            const index = item.innerItems.findIndex((item) => item.id === questionId);
            if (index > -1) {
               const hint = [{ text: text }];
               item.innerItems[index].surveyItem.surveyQuestionHints = hint;
            }
         }
      }
   });

   return survey;
};

export const updateIsRequiredStatus = (survey, questionId, status) => {
   const findAndUpdateQuestion = (items) => {
      items.forEach((item) => {
         if (item.itemLabel === "Section") {
            const questionIndex = item.innerItems.findIndex((q) => q.id === questionId);
            if (questionIndex > -1) {
               // Update the isRequired status
               item.innerItems[questionIndex].surveyItem.isRequired = status;
            } else {
               findAndUpdateQuestion(item.innerItems);
            }
         }
      });
   };

   findAndUpdateQuestion(survey);
   return survey;
};

export const updateQuestionLabel = (survey, sectionId, questionId, text) => {
   survey.forEach((item) => {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            if (item.id === questionId) {
               item.surveyItem.description = text;
            }
            updateQuestionLabel(item.innerItems, sectionId, questionId, text);
         } else {
            const index = item.innerItems.findIndex((item) => item.id === questionId);
            if (index > -1) {
               const questionLabels = [{ text: text }];
               item.innerItems[index].surveyItem.questionLabels = questionLabels;
            }
         }
      }
   });

   return survey;
};

export const updateQuestionType = (survey, sectionId, questionId, type) => {
   survey.forEach((item) => {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            updateQuestionType(item.innerItems, sectionId, questionId, type);
         } else {
            const index = item.innerItems.findIndex((item) => item.id === questionId);
            if (index > -1) {
               item.innerItems[index].surveyItem.questionType = type;
            }
         }
      }
   });

   return survey;
};

export const updateQuestionChoices = (survey, sectionId, questionId, choices) => {
   for (const item of survey) {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            updateQuestionChoices(item.innerItems, sectionId, questionId, choices);
         } else {
            const index = item.innerItems.findIndex((item) => item.id === questionId);
            if (index > -1) {
               item.innerItems[index].surveyItem.selectQuestionChoices = choices;
               break;
            }
         }
      }
   }
   return survey;
};

export const updateMaxChoices = (survey, sectionId, questionId, maxChoices) => {
   for (const item of survey) {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            updateMaxChoices(item.innerItems, sectionId, questionId, maxChoices);
         } else {
            const index = item.innerItems.findIndex((item) => item.id === questionId);
            if (index > -1) {
               item.innerItems[index].surveyItem.maxChoices = maxChoices; // Update maxChoices property
               break;
            }
         }
      }
   }
   return survey;
};

export const updateSecondQuestionChoices = (survey, sectionId, questionId, choices) => {
   for (const item of survey) {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            updateSecondQuestionChoices(item.innerItems, sectionId, questionId, choices);
         } else {
            const index = item.innerItems.findIndex((item) => item.id === questionId);
            if (index > -1) {
               // const mappedChoices = choices.map((choice) => {
               //    return {
               //       // id: uuidv4(),
               //       choiceLabels: [choice]
               //    };
               // });
               item.innerItems[index].surveyItem.secondChoices = choices;
               break;
            }
         }
      }
   }
   return survey;
};

export const updateRangeValues = (survey, sectionId, questionId, rangeOptions) => {
   for (const item of survey) {
      if (item.itemLabel === "Section") {
         if (item.id !== sectionId) {
            updateRangeValues(item.innerItems, sectionId, questionId, rangeOptions);
         } else {
            const index = item.innerItems.findIndex((item) => item.id === questionId);
            if (index > -1) {
               item.innerItems[index].surveyItem.range = rangeOptions;
               break;
            }
         }
      }
   }
   return survey;
};

// export const duplicateSection = (survey, sectionId) => {
//    survey.forEach((item) => {
//       if (item.itemLabel === "Section") {
//          if (item.id !== sectionId) {
//             duplicateSection(item.innerItems, sectionId);
//          } else {
//             const index = survey.findIndex((item) => item.id === sectionId);
//             if (index > -1) {
//                for (let i = index + 1; i < survey.length; i++) {
//                   survey[i].surveyItem.order -= 1;
//                }
//                const id = uuidv4();
//                // const questionItems = {
//                //    ...item.innerItems[index].surveyItem,
//                //    order: item.innerItems[index].surveyItem.order + 1,
//                //    id: id
//                // };

//                survey.splice(index, 0, survey[index]);
//                console.log(survey);
//             }
//          }
//       }
//    });
// };

export const getKeysByValues = (object, values) => {
   return values.map((value) => Object.keys(object).find((key) => object[key] === value));
};
