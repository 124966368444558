import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Col, Row, Spin } from "antd";
import { CreditCardOutlined, EditOutlined } from "@ant-design/icons";
import "./index.scss";
import CurrentSubscription from "./CurrentSubscription";
import BasicModal from "../../ui/BasicModal/BasicModal";
import CheckoutForm from "../../pages/auth/payment/StripeForm";
import { httpService } from "../../base/services/httpService.service";
import AuthService from "../../base/services/authentication.service";
import CancelSubscription from "./CancelSubscription";
import dayjs from "dayjs";

const PaymentInformation = React.memo(() => {
   const { REACT_APP_STRIPE_PUBLISHABLEKEY } = process.env;
   const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLEKEY);
   const organizationId = AuthService.getOrganizationId();
   const [openModal, setOpenModal] = useState({
      editPayment: false,
      cancelSubscription: false
   });
   const [isLoading, setIsLoading] = useState(true);
   const [plan, setPlan] = useState(false);
   const [stripeSubscriptionData, setStripeSubscriptionData] = useState({
      stripeSubscriptionId: null,
      subscriptionStartsAt: null,
      subscriptionEndsAt: null,
      subscriptionStatus: null,
      planId: null,
      planName: null,
      clientSecret: null,
      price: null,
      subscriptionId: null,
      paymentMethodId: null,
      last4CardDigits: null,
      cancelAt: null
   });

   useEffect(() => {
      setIsLoading(true);
      httpService
         .get(
            `/Payment/getStripeSubscriptionByOrganization?organizationId=${organizationId}`,
            (res) => {
               // SessionService.setItem("subscriptionId", res.data.subscriptionId);
               setStripeSubscriptionData(res.data);
            }
         )
         .finally(() => {
            setIsLoading(false);
         });
   }, [plan]);

   const appearance = {
      theme: "stripe"
   };

   const options = {
      clientSecret: stripeSubscriptionData.clientSecret,
      appearance
   };

   const handlePlan = () => {
      setPlan(!plan);
   };

   return (
      <>
         {isLoading ? (
            <Spin
               style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  width: "100%"
               }}
            />
         ) : (
            <div className="paymentInformation accountSettings">
               {/* {stripeSubscriptionData.subscriptionStatus !== "canceled" &&
                  !stripeSubscriptionData.cancelAt && ( */}
               {stripeSubscriptionData.subscriptionStatus !== "canceled" && (
                  <Row gutter={[16, 16]} justify="space-between" align="middle">
                     <Col xs={24} sm={18} md={12} lg={12} xl={12}>
                        <span className="title">Payment information</span>
                     </Col>
                     <Col xs={24} sm={6} md={12} lg={12} xl={12} className="cancelSubscription">
                        <Button
                           type="default"
                           className="defaultButton"
                           onClick={() => setOpenModal({ cancelSubscription: true })}
                        >
                           Cancel my subscription
                        </Button>
                     </Col>
                  </Row>
               )}
               <CurrentSubscription
                  planName={stripeSubscriptionData.planName}
                  subscriptionStatus={stripeSubscriptionData.subscriptionStatus}
                  subscriptionId={stripeSubscriptionData.subscriptionId}
                  price={stripeSubscriptionData.price}
                  isMonthly={stripeSubscriptionData.isMonthly}
                  // handleUpdatePlan={handlePlan}
               ></CurrentSubscription>
               <div className="paymentMethod">
                  <div className="nextPayment">
                     <p>Next payment due</p>
                     <h3>
                        {stripeSubscriptionData?.subscriptionEndsAt &&
                           dayjs(stripeSubscriptionData?.subscriptionEndsAt).format("MMM D, YYYY")}
                     </h3>
                  </div>
                  <div className="editPaymentMethod">
                     <p>Payment method</p>
                     <div className="cardInformation">
                        <div className="infoDetails">
                           <div className="creditCardIcon">
                              <CreditCardOutlined
                                 style={{
                                    strokeWidth: "35",
                                    stroke: "white"
                                 }}
                              />
                           </div>
                           <div className="visaEnding">
                              {stripeSubscriptionData.paymentMethodId ? (
                                 <>
                                    <span>Credit card</span>
                                    <span>
                                       Visa ending in ****{stripeSubscriptionData.last4CardDigits}
                                    </span>
                                 </>
                              ) : (
                                 <span>No card details added</span>
                              )}
                           </div>
                        </div>
                        <Button
                           type="default"
                           className="defaultButton"
                           icon={<EditOutlined />}
                           onClick={() => setOpenModal({ editPayment: true })}
                        >
                           {stripeSubscriptionData.paymentMethodId ? (
                              <span>Edit payment method</span>
                           ) : (
                              <span>Add payment method</span>
                           )}
                        </Button>
                     </div>
                  </div>
               </div>
               {openModal.editPayment && (
                  <BasicModal
                     isModalOpen={openModal.editPayment}
                     handleCancel={() => setOpenModal({ editPayment: false })}
                  >
                     {stripeSubscriptionData.clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                           <CheckoutForm
                              handleOk={() => setOpenModal({ editPayment: false })}
                              handleCardDetails={handlePlan}
                           />
                        </Elements>
                     )}
                  </BasicModal>
               )}
               {openModal.cancelSubscription && (
                  <CancelSubscription
                     isModalOpen={openModal.cancelSubscription}
                     handleAccountModal={() => setOpenModal({ cancelSubscription: false })}
                     subscriptionId={stripeSubscriptionData.subscriptionId}
                     handleCancelPlan={handlePlan}
                  ></CancelSubscription>
               )}
            </div>
         )}
      </>
   );
});

export default PaymentInformation;
