import React, { useRef, useState } from "react";
import { Button, Col, Row, Upload, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import BasicModal from "../BasicModal/BasicModal";
import { usePersonalDataStore, useProfileStore } from "../../pages/auth/authStore";
import AuthService from "../../base/services/authentication.service";
import { httpService } from "../../base/services/httpService.service";
import { StatusCodesEnum } from "../../utils/enums";

import "./FileUpload.scss";

const FileUpload = React.memo(({ fileUrl, handleFileUrl, handleCancel }) => {
   const userId = AuthService.getUser()?.id || "";
   const [fileList, setFileList] = useState([]);
   const [previewVisible, setPreviewVisible] = useState(false);
   const [croppedImage, setCroppedImage] = useState(null);
   const [croppingImage, setCroppingImage] = useState(false);
   const setPersonalData = usePersonalDataStore((state) => state.setPersonalData); // Get action from store
   const { userLetter, formData } = useProfileStore();
   const cropperRef = useRef(null);

   const handleFileChange = ({ fileList }) => {
      const latestFile = fileList.length > 0 ? [fileList[fileList.length - 1]] : [];
      setFileList(latestFile);

      if (fileList.length > 0) {
         setPreviewVisible(true);
      }
   };

   const handleCrop = () => {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();
      croppedCanvas.toBlob((blob) => {
         const croppedFile = new File([blob], fileList[0].name, {
            type: "image/jpeg",
            lastModified: Date.now()
         });
         setCroppedImage(croppedFile);
         setPreviewVisible(false);
      });
   };

   const handleUpload = async () => {
      if (!croppedImage) {
         console.error("Please crop the image before uploading.");
         return;
      }

      setCroppingImage(true);
      const formData = new FormData();
      formData.append("ProfilePicture", croppedImage);
      formData.append("UserId", userId);
      httpService
         .post(`/Account/addOrUpdateProfilePicture`, formData, (response) => {
            if (!response) return;
            const isSuccess = response.statusCode === StatusCodesEnum.OK;
            if (isSuccess) {
               handleFileUrl();
               setPersonalData({ profilePictureUrl: response.data.fileUrl });
            } else {
               notification.warning({
                  type: "warning",
                  message: response.data.message || "Something went wrong! Please try again later."
               });
            }
            setCroppingImage(false);
         })
         .catch((error) => {
            setCroppingImage(false);
            notification.error({
               message: error?.response?.data?.message || "Unable to update the profile picture."
            });
         });
   };

   return (
      <div className="file-upload-page">
         <h3 className="file-upload--title">Edit profile picture</h3>
         <div className="user-profile-logo">
            {formData.userProfilePictureUrl || croppedImage ? (
               <img
                  width={120}
                  height={120}
                  src={croppedImage ? URL.createObjectURL(croppedImage) : fileUrl}
                  alt=""
                  style={{
                     borderRadius: "100px"
                  }}
               />
            ) : (
               <span>{userLetter}</span>
            )}
         </div>
         <p className="file-upload-page--paragraph">Upload a file to change your profile picture</p>
         <Upload.Dragger
            name="file"
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleFileChange}
            className="upload-file"
            accept=".png, .jpg, .jpeg"
            showUploadList={false}
            progress="trailColor"
            prefixCls="upload-file"
         >
            <div className="centered-container">
               <div className="icon-box">
                  <UploadOutlined style={{ color: "#4158CF", fontSize: "20px" }} />
               </div>
               <p className="upload-file--text">
                  Drag and drop files here, or click to select files
               </p>
               <span className="upload-file-span">JPG or PNG</span>
               <br />
               <span>Max file size: 20MB</span>
            </div>
         </Upload.Dragger>
         <BasicModal
            isModalOpen={previewVisible}
            handleCancel={() => {
               setPreviewVisible(false);
            }}
            footerButtonCustomStyle
         >
            <Cropper
               ref={cropperRef}
               src={fileList.length > 0 ? URL.createObjectURL(fileList[0].originFileObj) : null}
               style={{ height: 400, width: "100%" }}
               aspectRatio={1}
               viewMode={1}
               minCropBoxHeight={10}
               minCropBoxWidth={10}
               background={false}
               responsive={true}
               autoCropArea={1}
               checkOrientation={false}
               guides={true}
            />
            <br />
            <Button className="primaryButton" onClick={handleCrop}>
               Crop
            </Button>
         </BasicModal>
         <Row gutter={16} className="mt-8" justify="center" align="middle">
            <Col>
               <Button className="cancel" disabled={croppingImage} onClick={handleCancel}>
                  Cancel
               </Button>
            </Col>
            <Col>
               <Button className="primaryButton" onClick={handleUpload} loading={croppingImage}>
                  Change profile picture
               </Button>
            </Col>
         </Row>
      </div>
   );
});

export default FileUpload;
