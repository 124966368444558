import { Form, Radio } from "antd";
import React, { useState } from "react";

import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { SessionService } from "../../../../../base/services/session.service";
function PaymentMethod() {
   const [paymentMethod, setPaymentMethod] = useState();

   const onChange = (e) => {
      setPaymentMethod(e.target.value);
      SessionService.setItem("registeringPaymentType", e.target.value);
   };

   return (
      <div
         className="payment-method"
         style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
      >
         {/* <Form.Item label="Select your preferred payment method">
            <Radio.Group name="radiogroup" defaultValue={"stripe"} onChange={onChange}> */}
         {/* <Radio disabled value={"bankTransfer"}>
                  <img
                     src={`${toAbsoluteUrl("/media/svg/payment-method/bank-transfer.png")}`}
                     alt=""
                  />{" "}
                  <p>Bank Transfer</p>
               </Radio>
               <Radio disabled value={"paypal"}>
                  <img src={`${toAbsoluteUrl("/media/svg/payment-method/paypal.png")}`} alt="" />
               </Radio> */}
         {/* <Radio value={"stripe"}>
                  <img src={`${toAbsoluteUrl("/media/svg/payment-method/stripe.png")}`} alt="" />
               </Radio>
            </Radio.Group>
         </Form.Item> */}
      </div>
   );
}

export { PaymentMethod };
