import { ArrowRightOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";

const Buttons = ({ text, record, onComplete, onReject, goToSurvey }) => {
   return (
      <>
         {text.status === "Assigned" && (
            <div className="tableButtons">
               {/* <Tooltip title="Mark as done" placement="bottom">
                  <Button
                     className={`acceptOrReject markAsDone`}
                     onClick={(e) => {
                        e.stopPropagation();
                        onComplete(record);
                     }}
                  >
                     <CheckOutlined />
                  </Button>
               </Tooltip> */}
               <Tooltip title="Reject task" placement="bottom">
                  <Button
                     className={`acceptOrReject reject`}
                     onClick={(e) => {
                        e.stopPropagation();
                        onReject(record);
                     }}
                  >
                     <CloseOutlined />
                  </Button>
               </Tooltip>
               <Tooltip title="Go to survey" placement="bottom">
                  <Button
                     className={`acceptOrReject goToSurvey`}
                     onClick={(e) => {
                        e.stopPropagation();
                        goToSurvey(record);
                     }}
                  >
                     <ArrowRightOutlined />
                  </Button>
               </Tooltip>
            </div>
         )}
      </>
   );
};

export default Buttons;
