import React from "react";
import { Pagination as AntPagination } from "antd";
import "./Pagination.scss";

const Pagination = ({ handlePagination = () => {}, pagination }) => {
   return (
      <div className="antPagination">
         <AntPagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={(page, pageSize) => handlePagination(page, pageSize)}
            showSizeChanger={false}
         ></AntPagination>
      </div>
   );
};

export default Pagination;
