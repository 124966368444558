import React from "react";

import styles from "../../pages/admin/user-management/UserManagement.module.scss";
import { Select } from "antd";
import { genderOptions } from "../../utils/options";

const { Option } = Select;

const MembersFilters = React.memo(
   ({
      onSelectChange,
      filterValue,
      rolesList = [],
      languages = [],
      countries = [],
      projects = [],
      workspaces = [],
      adminUserManagementPage
   }) => {
      return (
         <>
            <div className={styles.filtersContainer}>
               <Select
                  size="medium"
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="Filter by role"
                  optionFilterProp="children"
                  onChange={(value) => onSelectChange(value, "roles")}
                  filterOption={(input, option) => {
                     return (option?.children ?? "").toLowerCase().startsWith(input.toLowerCase());
                  }}
                  className={styles.filterSelect}
                  value={filterValue.roles.map((item) => item)}
               >
                  {rolesList.map((role) => (
                     <Option key={role.id} value={role.id}>
                        {role.name}
                     </Option>
                  ))}
               </Select>
               <Select
                  size="medium"
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="Filter by language"
                  optionFilterProp="children"
                  onChange={(value) => onSelectChange(value, "languages")}
                  filterOption={(input, option) => {
                     return (
                        option?.children?.toLowerCase().startsWith(input.toLowerCase()) ||
                        option?.children?.toLowerCase().includes(`, ${input.toLowerCase()}`)
                     );
                  }}
                  className={styles.filterSelect}
                  value={filterValue.languages.map((item) => item)}
               >
                  {languages.map((language) => (
                     <Option key={language.value} value={language.id}>
                        {language.label}
                     </Option>
                  ))}
               </Select>
               <Select
                  size="medium"
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="Filter by gender"
                  optionFilterProp="children"
                  onChange={(value) => onSelectChange(value, "genderValues")}
                  filterOption={(input, option) =>
                     (option?.label ?? "").toLowerCase().startsWith(input.toLowerCase())
                  }
                  options={genderOptions}
                  className={styles.filterSelect}
                  value={filterValue.genderValues.map((item) => item)}
               />
               <Select
                  mode="multiple"
                  size="medium"
                  showSearch
                  allowClear
                  placeholder="Filter by location"
                  optionFilterProp="children"
                  onChange={(value) => onSelectChange(value, "locations")}
                  filterOption={(input, option) => {
                     return option && option.children
                        ? option.children.toLowerCase().startsWith(input.toLowerCase())
                        : false;
                  }}
                  className={styles.filterSelect}
                  value={filterValue.locations.map((item) => item)}
               >
                  {countries.map((country) => (
                     <Option key={country.value} value={country.id}>
                        {country.label}
                     </Option>
                  ))}
               </Select>
               {adminUserManagementPage && (
                  <>
                     <Select
                        mode="multiple"
                        size="medium"
                        showSearch
                        allowClear
                        placeholder="Filter by workspace"
                        optionFilterProp="children"
                        onChange={(value) => onSelectChange(value, "workspaces")}
                        filterOption={(input, option) => {
                           return option && option.children
                              ? option.children.toLowerCase().startsWith(input.toLowerCase())
                              : false;
                        }}
                        className={styles.filterSelect}
                        value={filterValue.workspaces.map((item) => item)}
                     >
                        {workspaces.map((workspace) => (
                           <Option key={workspace.id} value={workspace.id}>
                              {workspace.name}
                           </Option>
                        ))}
                     </Select>
                     <Select
                        mode="multiple"
                        size="medium"
                        showSearch
                        allowClear
                        placeholder="Filter by project"
                        optionFilterProp="children"
                        onChange={(value) => onSelectChange(value, "projects")}
                        filterOption={(input, option) => {
                           return option && option.children
                              ? option.children.toLowerCase().startsWith(input.toLowerCase())
                              : false;
                        }}
                        className={styles.filterSelect}
                        value={filterValue.projects.map((item) => item)}
                     >
                        {projects.map((project) => (
                           <Option key={project.id} value={project.id}>
                              {project.name}
                           </Option>
                        ))}
                     </Select>
                  </>
               )}
            </div>
         </>
      );
   }
);

export default MembersFilters;
