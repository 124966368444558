import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { httpService } from "../../../../base/services/httpService.service";
import { Button, Form, Input, notification, Spin } from "antd";
import "../../style.scss";
import BasicModal from "../../../../ui/BasicModal/BasicModal";

function NewWorkspace() {
   const [formData] = useState([]);
   const textAreaStyle = { resize: "none" };
   const [load, setLoad] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const navigate = useNavigate();

   const onFinish = (values) => {
      setLoad(true);
      httpService
         .post(
            "/Workspaces/add",

            {
               name: values.workspaceName,
               description: values.description ? values.description : ""
            },
            (res) => {
               if (res?.isSuccess) {
                  notification.success({
                     message: "Workspace added successfully!"
                  });
                  setLoad(false);
                  navigate(-1);
               }
            },
            (error) => {
               if (
                  error.response.status === 400 &&
                  Array.isArray(error.response.data.errors) &&
                  error.response.data.errors.includes &&
                  error.response.data.errors.includes(
                     "You reached your packet limit for creating workspaces!!"
                  )
               ) {
                  setIsModalOpen(true);
               } else {
                  if (values.workspaceName.trim() === "") {
                     notification.error({
                        message: "Workspace name is required!"
                     });
                  } else {
                     notification.error({
                        message: "Something went wrong! Please try again later."
                     });
                  }
               }
            }
         )
         .finally(() => {
            setLoad(false);
         });
      console.log(values);
   };
   const onFinishFailed = (errorInfo) => {
      setLoad(false);
   };

   return (
      <div className="my-new-workspace">
         <div className="new-workspace-form">
            <div className="work-space">
               <h3>Add New Workspace</h3>
               <Form
                  name="basic"
                  initialValues={{
                     remember: true
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
               >
                  <Form.Item
                     label="Workspace Name*"
                     name="workspaceName"
                     rules={[
                        {
                           required: true,
                           message: "Please input your Workspace Name!"
                        }
                     ]}
                  >
                     <Input />
                  </Form.Item>
                  <p>{formData.workspaceName}</p>
                  <Form.Item label="Workspace Description" name="description">
                     <textarea rows="6" style={textAreaStyle}></textarea>
                  </Form.Item>

                  <div className="workspace-button-group d-flex justify-content-center ">
                     <Form.Item style={{ marginRight: "5%", display: "flex", alignItems: "end" }}>
                        <Button onClick={() => navigate(-1)} className="workspace-cancel-button">
                           Cancel
                        </Button>
                     </Form.Item>
                     <Form.Item>
                        <Button
                           type="primary"
                           htmlType="submit"
                           className="kapSurveyPrimaryButton"
                           disabled={load}
                           loading={load}
                        >
                           {load ? <>Processing</> : "Add New"}
                        </Button>
                     </Form.Item>
                  </div>
               </Form>
            </div>
         </div>
         <BasicModal isModalOpen={isModalOpen} handleCancel={() => setIsModalOpen(false)}>
            <h3>You reached your packet limit for creating workspaces!</h3>
         </BasicModal>
      </div>
   );
}

export { NewWorkspace };
