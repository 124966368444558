import React from "react";

function RegisterHeader({ step }) {
   return (
      <>
         {step === 0 && (
            <>
               {/* <h2>
                   test
                  </h2>
                  <h2>Create your profile</h2> */}
               <p>
                  Alredy have an account?
                  <a
                     href="/auth/login"
                     style={{ color: "#F58F45", textDecoration: "underline", marginLeft: "5px" }}
                  >
                     Log in here
                  </a>
               </p>
            </>
         )}
         {step === 1 && (
            <>
               {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
               >
                  <path
                     d="M14.7358 8.2339L2.73584 8.2339M2.73584 8.2339L8.10255 2.86719M2.73584 8.2339L8.10255 13.6006"
                     stroke="#5770CB"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>{" "}
               <a href="/auth/registration" style={{ marginLeft: "5px", color:"#5770CB" }}>
                  Go back to profile information
               </a> */}
            </>
         )}

         {step === 2 && (
            <>
               {/* <h2>
                  <span>3</span>
               </h2>
               <h2>Review & pay</h2> */}
            </>
         )}
      </>
   );
}

export { RegisterHeader };
