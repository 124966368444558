import { REGEX } from "../../../../../../../helpers/constraintParsers";

export const getForeignInputNames = (str) => {
   if (!str?.trim()) {
      return [];
   }

   let inputNames = new Set();
   const matches = str.matchAll(REGEX.foreignInputValue);
   for (const match of matches) {
      const [, inputName] = match;
      inputNames.add(inputName);
   }

   return Array.from(inputNames);
};
