import React, { useState } from "react";
import "./OrganizationManagement.scss";
import { Button, Modal, Form, Input } from "antd";
import CompanyInfoModal from "./CompanyInfoModal";
import LoginInfoModal from "./LoginInfoModal";
import AuthService from "../../../base/services/authentication.service";
import { useSelector } from "react-redux";

const getUser = (state) => state.user;

function OrganizationManagement() {
   const [companyInfoModalVisible, setCompanyInfoModalVisible] = useState(false);
   const [loginInfoModalVisible, setLoginInfoModalVisible] = useState(false);
   const useremail = AuthService.getUser()?.email;
   const organization = {
      type: AuthService.getUser()?.organizationType,
      name: AuthService.getUser()?.organizationName,
      country: AuthService.getUser()?.countryName
   }

   const { user } = useSelector((state) => {
     return {
         user: getUser(state),
     };
 });


   


   const showCompanyInfoModal = () => {
      setCompanyInfoModalVisible(true);
   };

   const handleCompanyInfoOk = () => {
      setCompanyInfoModalVisible(false);
   };

   const handleCompanyInfoCancel = () => {
      setCompanyInfoModalVisible(false);
   };
   const showLoginInfoModal = () => {
      setLoginInfoModalVisible(true);
   };

   const handleLoginInfoOk = () => {
      setLoginInfoModalVisible(false);
   };

   const handleLoginInfoCancel = () => {
      setLoginInfoModalVisible(false);
   };
   return (
      <div className="background">
         <h1 style={{ padding: "30px" }}>Organization Management</h1>
         <div className="row information">
            <div className="col">
               <h2 className="org-mng-heading">Company Information</h2>
               <p className="paragraph-heading">Organization Name</p>
               <p className="paragraph-text">{organization?.name}</p>
               <p className="paragraph-heading">Organization Type</p>
               <p className="paragraph-text">{organization.type}</p>
               <p className="paragraph-heading">Country</p>
               <p className="paragraph-text">{organization.country}</p>
               {/* <Button
                  className="kapSurveyPrimaryButton"
                  style={{ marginTop: "20px" }}
                  onClick={showCompanyInfoModal}
                  disabled
               >
                  Update company info
               </Button> */}
               <CompanyInfoModal
                  visible={companyInfoModalVisible}
                  handleOk={handleCompanyInfoOk}
                  handleCancel={handleCompanyInfoCancel}
               />
            </div>
            <div className="col">
               <h2 className="org-mng-heading">Login Information</h2>
               <p className="paragraph-heading">Username</p>
               <p className="paragraph-text">{useremail}</p>
               <p className="paragraph-heading">Password</p>
               <p className="paragraph-text">********</p>
               <Button className="kapSurveyPrimaryButton" style={{ marginTop: "20px" }} onClick={showLoginInfoModal}>
                  Update Login info
               </Button>
               <LoginInfoModal
            visible={loginInfoModalVisible}
            handleOk={handleLoginInfoOk}
            handleCancel={handleLoginInfoCancel}
          />
            </div>
         </div>
         <hr />
         {/* <div className="row information">
            <div className="col">
               <h2 className="org-mng-heading">Payment Plan</h2>
               <p className="paragraph-heading">Current subscription</p>
               <p className="paragraph-text">Test</p>
               <p className="paragraph-heading">Next payment date</p>
               <p className="paragraph-text">testing</p>
               <p className="paragraph-heading">Payment method</p>
               <p className="paragraph-text">Testing</p>
               <Button className="kapSurveyPrimaryButton" style={{ marginTop: "20px" }} disabled>
                  Change Payment
               </Button>
            </div>
            <div className="col"></div>
         </div> */}
      </div>
   );
}

export { OrganizationManagement };
