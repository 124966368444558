import React from "react";
import { Button, Form, Input, Modal } from "antd";
import "./index.scss";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useNavigate } from "react-router-dom";

const ForgotPasswordModal = ({ isModalOpen, handleOk, handleCancel, isPasswordValid }) => {
   const navigate = useNavigate();

   return (
      <>
         <Modal
            open={isModalOpen || isPasswordValid}
            onOk={handleOk}
            onCancel={handleCancel}
            className="forgorPasswordModal"
         >
            <div>
               <div className="borderTop"></div>
               {isModalOpen ? (
                  <>
                     <img src={toAbsoluteUrl("/media/bg/email-icon.png")} alt="" />
                     <p className="title">Email sent!</p>
                     <p className="description">
                        We have sent a 6-digit code to your email. Enter the code below to reset
                        your password.{" "}
                     </p>

                     <Form
                        className="forgetPasswordForm"
                        name="nest-messages"
                        onFinish={handleOk}
                        style={{
                           maxWidth: 600,
                           margin: "0 auto"
                        }}
                     >
                        <p className="verificationText">Enter the verification code below</p>{" "}
                        <Form.Item name="verificationCode" className="verificationCode">
                           <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item className="d-flex justify-content-center">
                           <Button
                              htmlType="submit"
                              style={{
                                 width: "150px",
                                 height: "40px",
                                 background: "#5770CB",
                                 color: "#FFF"
                              }}
                           >
                              Submit code
                           </Button>
                        </Form.Item>
                     </Form>
                  </>
               ) : isPasswordValid ? (
                  <>
                     <img src={toAbsoluteUrl("/media/bg/confirmation-icon.png")} alt="" />
                     <p className="title2">Password changed successfully</p>

                     <Button
                        onClick={() => {
                           handleCancel();
                           setTimeout(() => {
                              navigate("/auth/login");
                           }, 1000);
                        }}
                        style={{
                           width: "150px",
                           height: "40px",
                           background: "#5770CB",
                           color: "#FFF"
                        }}
                     >
                        Got it
                     </Button>
                  </>
               ) : null}
            </div>
         </Modal>
      </>
   );
};

export default ForgotPasswordModal;
