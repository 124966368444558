import { Col, Select } from "antd";
import React, { useEffect, useState } from "react";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import style from "./Ranking.module.scss";
import { ReactComponent as DragIcon } from "./DragIcon.svg";
import { useSurveyInputStore } from "../../../../surveyStore";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";

export const Ranking = React.memo(
   ({ props, hasErrors, userId, surveyId, submissionId, label, hint }) => {
      const { id, selectQuestionChoices, questionType, isRequired } = props;
      const [value, setValue] = useState([]);

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const wrapperStyle =
         hasErrors &&
         isRequired &&
         (!inputState ||
            !inputState?.answerDetail?.answerRankings ||
            inputState?.answerDetail?.answerRankings.length < selectQuestionChoices.length)
            ? { border: "1px solid red", borderRadius: "7px" }
            : {};

      const items = selectQuestionChoices.map((choice, i) => ({
         value: i + 1,
         label: i + 1
      }));

      const onChange = (e, choiceId) => {
         const existingIndex = value.findIndex((el) => el.choiceId === choiceId);

         if (existingIndex !== -1) {
            setValue((prev) => {
               const updatedValue = [...prev];
               updatedValue[existingIndex] = { choiceId, rank: e };
               return updatedValue;
            });
         } else {
            setValue((prev) => [...prev, { choiceId, rank: e }]);
         }
      };

      useEffect(() => {
         if (value.length === 0) return;
         setInputs({
            target: {
               name: id,
               value: {
                  surveyId: surveyId,
                  userId: userId,
                  questionId: id,
                  questionType: questionType,
                  name: props.name,
                  skipLogicAnswer: value,
                  answerDetail: {
                     answerRankings: value
                  }
               }
            }
         });
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [value]);

      useEffect(() => {
         if (!inputState) return;

         setValue(inputState?.answerDetail?.answerRankings);
      }, [inputState, id]);

      return (
         <Col lg={24} md={24} sm={24} xs={24}>
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />
            <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />

            <div className={style.wrapper} style={wrapperStyle}>
               <div className={style.optionsList}>
                  {selectQuestionChoices.map((option) => {
                     return (
                        <div className={style.option} key={option.choiceLabels[0]?.text}>
                           <div className={style.leftSide}>
                              <DragIcon />
                              <p>{option.choiceLabels[0]?.text}</p>
                           </div>
                           <Select
                              style={{
                                 width: "80px",
                                 marginLeft: "10px",
                                 marginBottom: "4px"
                              }}
                              onChange={(e) => onChange(e, option.id)}
                              value={value.find((el) => el.choiceId === option.id)?.rank}
                              options={items}
                              className={`${submissionId ? "submissionStyle" : ""}`}
                           />
                        </div>
                     );
                  })}
               </div>
            </div>
            {hasErrors &&
               isRequired &&
               (!inputState ||
                  !inputState?.answerDetail?.answerRankings ||
                  inputState?.answerDetail?.answerRankings.length <
                     selectQuestionChoices.length) && (
                  <span style={{ color: "red" }}>All rankings must be selected</span>
               )}
         </Col>
      );
   }
);
