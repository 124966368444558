import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { httpService } from "../../../../base/services/httpService.service";
import { Button, Form, Input, notification, Spin } from "antd";
import "../../style.scss";

export default function EditPage() {
   const { id } = useParams();
   const textAreaStyle = { resize: "none" };
   const [data, setData] = useState({});
   const [loading, setLoading] = useState(true);
   const [load, setLoad] = useState(false);
   const navigate = useNavigate();

   const onFinish = (values) => {
      setLoad(true);
      httpService.post(
         "/Workspaces/edit",

         {
            name: values.workspaceName,
            description: values.description,
            id: id
         },
         (res) => {
            if (res?.isSuccess) {
               notification.success({
                  message: "Workspace edited successfully!"
               });
               setTimeout(() => {
                  setLoad(false);
                  navigate(-1);
               }, 1500);
            }
         },
         (error) => {
            setLoad(false);
            notification.error({
               message: "Error editing this workspace, please try again later."
            });
         }
      );
   };
   const onFinishFailed = (errorInfo) => {
      setLoad(false);
      notification.error({
         message: "Error editing this workspace, please try again later."
      });
   };

   useEffect(() => {
      httpService.get(`/Workspaces/getById/${id}`, (res) => {
         setData(res.data);
         setLoading(false);
         console.log(res.data);
      });
   }, [id]);

   const initialValues = {
      workspaceName: data.name,
      description: data.description
   };

   if (loading) {
      return <div>Loading...</div>;
   }

   return (
      <div className="my-new-workspace">
         <div className="new-workspace-form">
            <div className="work-space">
               <h3>Edit Workspace information</h3>
               <Form
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={initialValues}
               >
                  <Form.Item
                     label="Workspace Name*"
                     name="workspaceName"
                     rules={[
                        {
                           required: true,
                           message: "Please input your Workspace Name!"
                        }
                     ]}
                  >
                     <Input />
                  </Form.Item>

                  <Form.Item label="Workspace Description" name="description">
                     <Input.TextArea rows={6} style={textAreaStyle} />
                  </Form.Item>

                  <div className="workspace-button-group d-flex justify-content-center ">
                     <Form.Item style={{ marginRight: "5%" }}>
                        <Button onClick={() => navigate(-1)} className="workspace-cancel-button">
                           Cancel
                        </Button>
                     </Form.Item>
                     <Form.Item>
                        <Button
                           type="primary"
                           htmlType="submit"
                           className="kapSurveyPrimaryButton"
                           disabled={load}
                           loading={load}
                        >
                           {load ? <>Processing</> : "Save Changes"}
                        </Button>
                     </Form.Item>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   );
}
