import React, { Fragment, useEffect } from "react";
import { Checkbox, Select } from "antd";
import PreviewQuestionLabel from "../../../common/PreviewQuestionLabel/PreviewQuestionLabel";
import { useSurveyInputStore } from "../../../../surveyStore";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";
import PreviewQuestionConstraints from "../../../common/PreviewQuestionConstraints/PreviewQuestionConstraints";
import useEvaluateChoiceFilter from "../../question-preview/hooks/useEvaluateChoiceFilter";
import useEvaluateAppearance from "../../question-preview/hooks/useEvaluateAppearance";

import "./SelectMultiple.scss";

const CheckboxGroup = Checkbox.Group;

function getLabelByLanguage(labels, language) {
   return labels.find(({ languageId }) => languageId === language)?.text ?? labels[0]?.text;
}

export const SelectMultiple = React.memo(
   ({ props, hasErrors, surveyId, userId, language, submissionId, label, hint, defaultValue }) => {
      const { id, selectQuestionChoices, questionType, isRequired, answers } = props;
      let maxChoices = props.maxChoices;

      const inputState = useSurveyInputStore((state) => state.inputs?.[id]);
      const setInputs = useSurveyInputStore((state) => state.setInputs);

      const hasSubmissionId = Boolean(submissionId);

      const passConstraint = useEvaluateConstraint(props.constraint, inputState?.skipLogicAnswer);

      const answer = answers?.find((answer) => answer?.submissionId === submissionId);

      const submissionAnswer = hasSubmissionId
         ? answer?.answerDetail?.choices.map((choice) => choice.choiceId)
         : null;

      const showErrors =
         hasErrors &&
         isRequired &&
         (!inputState || inputState?.answerDetail?.choices?.length === 0);

      const derivedOptions = useEvaluateChoiceFilter({
         choiceFilter: hasSubmissionId ? null : props.choiceFilter,
         name: props.name,
         selectQuestionChoices
      });

      const appearance = useEvaluateAppearance({
         appearance: props.appearance
      });

      const selectMultipleOptions = derivedOptions.map((option) => {
         return {
            value: option.id,
            label: option.choiceLabels
               ? getLabelByLanguage(option.choiceLabels, language)
               : "( No label )",

            name: option.name,
            disabled:
               maxChoices &&
               inputState?.answerDetail?.choices?.length >= maxChoices &&
               !inputState?.answerDetail?.choices?.includes(option.id)
         };
      });

      const handleCheckBoxGroupChange = (value) => {
         if (hasSubmissionId) {
            return;
         }

         if (value.length === 0) {
            setInputs({
               target: {
                  name: id,
                  value: ""
               }
            });
         } else {
            const selectedChoices = selectMultipleOptions.filter((choice) =>
               value.some((i) => [choice.value, choice.name].includes(i))
            );

            const selectedNames = selectedChoices.map((choice) => choice.name);
            const selectedValues = selectedChoices.map((choice) => choice.value);

            setInputs({
               target: {
                  name: id,
                  value: {
                     surveyId: surveyId,
                     userId: userId,
                     questionId: id,
                     name: props.name,
                     skipLogicAnswer: selectedNames,
                     questionType: questionType,
                     answerDetail: {
                        choices: selectedValues
                     }
                  }
               }
            });
         }
      };

      useEffect(() => {
         if (props.default) {
            //#TODO check null value error on split 
            const defaultOptions = props.default.split("and").map((s) => s.trim());
            handleCheckBoxGroupChange(defaultOptions);
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.default]);

      useEffect(() => {
         if (props.choiceFilter) {
            setInputs({ target: { name: id, value: "" } });
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [derivedOptions, id, props.choiceFilter]);

      return (
         <div className="select_multiple-submission-type">
            <PreviewQuestionLabel label={label} isRequired={isRequired} hint={hint} />

            <div
               className={`select_multiple-submission-type--options${
                  showErrors || (passConstraint !== null && !passConstraint) ? "_error" : ""
               }`}
            >
               {appearance.includes("minimal") ? (
                  <Select
                     allowClear
                     showSearch={false}
                     mode="multiple"
                     style={{ width: "100%" }}
                     size="large"
                     placeholder="none selected"
                     value={
                        hasSubmissionId
                           ? submissionAnswer
                           : inputState?.answerDetail?.choices ?? null
                     }
                     options={selectMultipleOptions}
                     onChange={handleCheckBoxGroupChange}
                     maxTagCount={0}
                     maxTagPlaceholder={(values) =>
                        values.length > 1
                           ? `${values.length} selected`
                           : values.map(({ label }) => label).join(", ")
                     }
                     dropdownRender={(menu) =>
                        selectMultipleOptions.length ? (
                           <div
                              style={{
                                 maxHeight: "300px",
                                 overflowY: "auto"
                              }}
                           >
                              <CheckboxGroup
                                 value={
                                    hasSubmissionId
                                       ? submissionAnswer
                                       : inputState?.answerDetail?.choices ?? null
                                 }
                                 options={selectMultipleOptions}
                                 onChange={handleCheckBoxGroupChange}
                                 prefixCls="select_multiple-submission-type--options---checkbox"
                              />
                           </div>
                        ) : (
                           menu
                        )
                     }
                  />
               ) : (
                  <Fragment>
                     <CheckboxGroup
                        value={
                           hasSubmissionId
                              ? submissionAnswer
                              : inputState?.answerDetail?.choices ?? null
                        }
                        options={selectMultipleOptions}
                        onChange={handleCheckBoxGroupChange}
                        prefixCls="select_multiple-submission-type--options---checkbox"
                     />
                     {typeof maxChoices !== "undefined" && maxChoices > 0 && (
                        <div className="select_multiple-submission-type--options---max_choice">
                           {`You can select up to ${maxChoices} choices.`}
                        </div>
                     )}
                  </Fragment>
               )}
            </div>

            <span className="select_multiple-submission-type--error">
               {showErrors ? "You must select at least one" : " "}
               {passConstraint !== null && !passConstraint ? (
                  <PreviewQuestionConstraints constraintMessages={props.constraintMessages} />
               ) : null}
            </span>
         </div>
      );
   }
);
