import { InputType } from "../../../../../enums/itemTypes.enum";
import { useSurveyInputStore } from "../../../../surveyStore";

const useInputValuesByName = (inputNames) => {
   const hasInputs = inputNames.length > 0;
   const inputValuesObject = useSurveyInputStore(
      ({ inputs }) => {
         if (!hasInputs || !inputs) {
            return {};
         }

         const filteredInputs = Object.values(inputs).filter((input) =>
            inputNames.includes(input.name)
         );

         return filteredInputs.reduce((acc, input) => {
            const answerDetailKey = Object.keys(input.answerDetail)[0];
            if (answerDetailKey) {
               switch (input.questionType) {
                  case InputType.SELECT_MULTIPLE:
                  case InputType.SELECT_ONE:
                  case InputType.DATE:
                  case InputType.DATE_TIME:
                  case InputType.TIME:
                     acc[input.name] = input.skipLogicAnswer;
                     break;
                  default:
                     acc[input.name] = input.answerDetail[answerDetailKey];
                     break;
               }
            }
            return acc;
         }, {});
      },
      (prevState, currState) => {
         return Object.is(JSON.stringify(prevState), JSON.stringify(currState));
      }
   );

   return inputValuesObject;
};

export default useInputValuesByName;
