import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "antd";
import BasicModal from "../../../../ui/BasicModal/BasicModal";
import useReactivatedAccountModal from "../../../../base/hooks/useReactivatedAccountModal";

const AddPaymentMethodModal = React.memo(() => {
   const navigate = useNavigate();

   const {
      addPaymentMethodAccountModal,
      removeLocalStoragePaymentKey
   } = useReactivatedAccountModal();

   return (
      <>
         {addPaymentMethodAccountModal && (
            <BasicModal
               isModalOpen={addPaymentMethodAccountModal}
               handleCancel={removeLocalStoragePaymentKey}
               width={400}
               footerButtonCustomStyle
            >
               <div className="subscription-notification">
                  <h2>Your subscription is ending soon!</h2>
                  <p>
                     Your subscription ends in
                     <br />
                  </p>
                  <span className="subscription-notification--days">
                     {addPaymentMethodAccountModal.differenceInDays} days
                  </span>
                  <p>Please add a payment method to continue using KAPsurvey</p>
               </div>
               <Row gutter={16} className="mt-8" justify="center" align="middle">
                  <Col>
                     <Button className="cancel" onClick={removeLocalStoragePaymentKey}>
                        Cancel
                     </Button>
                  </Col>
                  <Col>
                     <Button
                        className="primaryButton"
                        onClick={() => {
                           navigate("/subscription-plans");
                           removeLocalStoragePaymentKey();
                        }}
                     >
                        Add payment method
                     </Button>
                  </Col>
               </Row>
            </BasicModal>
         )}
      </>
   );
});

export default AddPaymentMethodModal;
