import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DropDownComp } from "./components/newWorkSpace/DropDown";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { httpService } from "../../base/services/httpService.service";
import { LocalStorageService } from "../../base/services/local-storage.service";
import AuthService from "../../base/services/authentication.service";
import { UserRolesEnum } from "../auth/userRoles.enum";
import { Spin } from "antd";
import ReactivatedModal from "./components/ReactivatedModal/ReactivatedModal";
import { withErrorBoundary } from "../../components/ErrorBoundary/withErrorBoundary";

import "./style.scss";

function Index() {
   const userDetails = JSON.parse(localStorage.getItem("roles"));
   const [isLoading, setIsLoading] = useState(false);
   const [workSpaces, setWorkSpaces] = useState([]);
   const navigate = useNavigate();

   useEffect(() => {
      setIsLoading(true);
      httpService.get("/Workspaces/getMyWorkspaces", (res) => {
         setWorkSpaces(res.data);
         setIsLoading(false);
      });
   }, []);

   const [isMounted, setIsMounted] = useState(true);
   console.log(isMounted);

   useEffect(() => {
      return () => {
         setIsMounted(false);
      };
   }, []);

   const handleDelete = (id) => {
      httpService
         .post("/Workspaces/delete", {
            id: id
         })
         .then(() => {
            setWorkSpaces((prevWorkSpaces) =>
               prevWorkSpaces.filter((workspace) => workspace.id !== id)
            );
         });
   };

   const enterWorkspace = (workspaceId) => {
      LocalStorageService.setItem("currentWorkspaceId", workspaceId);
      LocalStorageService.removeItem("currentProjectID");
      if (AuthService.isAuthorized([UserRolesEnum.DataCollector])) {
         navigate("/tasks");
         return;
      }
      navigate("/project-selection");
   };

   useEffect(() => {
      LocalStorageService.removeItem("currentWorkspaceId");
      LocalStorageService.removeItem("currentProjectID");
   }, []);

   return (
      <div className="workspace-choose-page">
         <h3>Welcome back, {AuthService.getUser()?.fullName}!</h3>
         <span>{(userDetails?.[0].name).replace(/([a-z])([A-Z])/g, "$1 $2")}</span>

         <div className="workspace-section">
            <h4>Your workspaces</h4>
            <p>Choose the workspace you would like to work on</p>
            <section className="workspaces">
               {isLoading ? (
                  <Spin
                     style={{
                        position: "absolute",
                        zIndex: "2",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)"
                     }}
                  />
               ) : (
                  workSpaces.map((workspace) => {
                     return (
                        <div className="work-box" key={workspace.id}>
                           {AuthService.isAuthorized([
                              UserRolesEnum.SuperAdmin,
                              UserRolesEnum.Organization,
                              UserRolesEnum.AdminOfOrganization,
                              UserRolesEnum.SeniorProjectManager
                           ]) && <DropDownComp handleDelete={handleDelete} id={workspace.id} />}

                           <div
                              onClick={() => enterWorkspace(workspace.id)}
                              className="your-workspaces clickable"
                              key={workspace.id}
                           >
                              <div>
                                 <div style={{ height: "45px" }}>
                                    <span>
                                       <img
                                          src={`${toAbsoluteUrl(
                                             "/media/svg/custom-icons/workspace/workspace-icon.png"
                                          )}`}
                                          alt=""
                                       />
                                    </span>
                                 </div>
                                 <div style={{ height: "50px", padding: "0 10px" }}>
                                    <p>{workspace.name}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     );
                  })
               )}
               {AuthService.isAuthorized([
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Organization,
                  UserRolesEnum.AdminOfOrganization,
                  UserRolesEnum.SeniorProjectManager
               ]) && (
                  <Link to={"/workspace/new-work-space"}>
                     <div className="add-new-workspace">
                        <div>
                           <span className="new-workspace-addIcon">
                              <img
                                 src={`${toAbsoluteUrl(
                                    "/media/svg/custom-icons/workspace/added-icon.png"
                                 )}`}
                                 alt=""
                              />
                           </span>
                           <p>New workspace</p>
                        </div>
                     </div>
                  </Link>
               )}
            </section>
         </div>
         <ReactivatedModal />
      </div>
   );
}

export default withErrorBoundary(Index);
