import { useMemo } from "react";
import { notification } from "antd";
import {
   REGEX,
   Functions,
   applyReplacements
} from "../../../../../../../helpers/choiceFilterParser";
import { getForeignInputNames } from "../helpers/getForeignInputNames";
import useInputValuesByName from "./useInputValueByName";

const useEvaluateChoiceFilter = (props) => {
   const inputNames = getForeignInputNames(props.choiceFilter);
   const relatedInputs = useInputValuesByName(inputNames);

   return useMemo(() => {
      if (!props.choiceFilter) {
         return props.selectQuestionChoices;
      }

      if (Object.keys(relatedInputs).length === 0 || props.selectQuestionChoices.length === 0) {
         return [];
      }

      if (REGEX.columnFilter.test(props.choiceFilter)) {
         const filteredOptions = props.selectQuestionChoices.filter((option) => {
            if (!option.choiceFilters?.length) {
               return true;
            }
            const { filterChoice } = option.choiceFilters[0];
            const question = filterChoice.questionsResponse[0];

            return relatedInputs[question.name] === filterChoice.name;
         });

         return filteredOptions;
      }

      const parsedFilter = applyReplacements(props.choiceFilter);

      const name = props.selectQuestionChoices.slice();

      try {
         // eslint-disable-next-line no-new-func
         const evaluate = new Function(
            ...Object.keys(Functions),
            "relatedInputs",
            "name",
            `return ${parsedFilter};`
         );
         const result = evaluate(...Object.values(Functions), relatedInputs, name);

         return Array.isArray(result) ? result : [];
      } catch (e) {
         notification.warning({
            message: "Error evaluating choice filter",
            description: `Please check if the syntax is correct. 
                 Select One: ${props.name} - ${props.choiceFilter}`
         });

         console.error(e, "SelectOne.jsx - evaluate");
         return [];
      }
   }, [props.choiceFilter, props.name, props.selectQuestionChoices, relatedInputs]);
};

export default useEvaluateChoiceFilter;
