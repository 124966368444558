export const getStatusClassName = (text) => {
   let statusClassName = "";
   switch (text) {
      case "Assigned":
      case "Pending":
         statusClassName = "assigned";
         break;
      case "Finished":
      case "Completed":
      case "Paid":
         statusClassName = "finished";
         break;
      case "In Progress":
      case "Accepted":
         statusClassName = "in-progress";
         break;
      case "Rejected":
         statusClassName = "rejected";
         break;
      default:
         break;
   }
   return statusClassName;
};
