import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { httpService } from "../../../base/services/httpService.service";
import Toast from "../../../base/components/Toast";
// import StripeSuccessful from "../../../assets/images/StripeSuccessful.webp";
import styles from "./stripeSuccessfulPage.module.css";
import { SessionService } from "../../../base/services/session.service";

function FreeTrialSuccessfulPage() {
   const { state } = useLocation();
   const navigate = useNavigate();

   //    useEffect(() => {
   //       if (!state?.fromApp) {
   //          return navigate("/auth/login");
   //       }
   //    }, [state]);

   const handleClick = (event) => {
      event.preventDefault();
      navigate("/auth/login");
   };

   return (
      <div className={styles["stripe-success-container"]}>
         <h1 className={styles["stripe-success-title"]}>
            You will not be charged until the end of free trial!
         </h1>
         <br />
         {/* <img src={StripeSuccessful} alt="Stripe successful image" width={"50%"} /> */}
         {/* <br /> */}
         <h4 className={styles["stripe-success-description"]}>
            You have successfully subscribed to KAPsurvey.
         </h4>
         <br />
         <button
            className={styles["stripe-success-button"]}
            onClick={(event) => handleClick(event)}
         >
            Sign in now
         </button>
      </div>
   );
}

export default FreeTrialSuccessfulPage;
