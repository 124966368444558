import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./IntroSection.scss";
import AuthService from "../../base/services/authentication.service";

export const IntroSection = ({ isTasksMainPage = false, dataLength }) => {
   const [chartData] = useState({
      options: {
         dataLabels: {
            enabled: false
         },
         chart: {
            type: "donut",
            width: "1000px", // Set the width here (e.g., '400px' or '80%')
            height: "1000px"
         },
         // responsive: [
         //    {
         //       breakpoint: 768
         //    }
         // ],
         legend: {
            position: "bottom",
            fontSize: isTasksMainPage ? "12px" : "18px"
         },
         labels: ["Assigned", "Completed", "Rejected"],
         colors: ["#6B89FC", "#28C76F", "#ea5455"],
         plotOptions: {
            pie: {
               donut: {
                  size: "65%"
                  // labels: {
                  //    show: true,
                  //    name: {
                  //       show: false
                  //    },
                  //    value: {
                  //       show: true,
                  //       formatter: (val, c) => {
                  //          console.log("vall", c);
                  //          return (
                  //             val +
                  //             "/" +
                  //             c.globals.seriesTotals.reduce((a, b) => {
                  //                return a + b;
                  //             }, 0)
                  //          );
                  //       }
                  //    },
                  //    total: {
                  //       show: true,
                  //       showAlways: false,
                  //       formatter: (w) => {
                  //          return w.globals.seriesTotals.reduce((a, b) => {
                  //             return a + b;
                  //          }, 0);
                  //       }
                  //    }
                  // }
               }
            }
         },
         yaxis: {
            show: true,
            showAlways: true,
            showForNullSeries: true,
            seriesName: undefined,
            opposite: false,
            reversed: false,
            logarithmic: false,
            logBase: 10,
            tickAmount: 6,
            min: 6,
            max: 6,
            forceNiceScale: false,
            floating: false,
            decimalsInFloat: undefined
         }
         // responsive: [{
         //    breakpoint: 768, // or whatever breakpoint you need
         //    options: {
         //       // Adjust chart options for smaller screens here
         //    }
         // }]
      }
   });

   let userName = AuthService.getUser()?.fullName.substring(
      0,
      AuthService.getUser()?.fullName.indexOf(" ")
   );
   return (
      <div
         className={`introSection ${isTasksMainPage ? "" : "introSectionDataCollector"}`}
         style={{
            background: isTasksMainPage && "#fff",
            height: isTasksMainPage && "100%"
         }}
      >
         {!isTasksMainPage && (
            <div className="headingText">
               <span>Hello {userName}!</span>
               <p>Here’s an overview of your progress</p>
            </div>
         )}
         {/* <div> */}
         <div id="chart" className="chart">
            <ReactApexChart
               options={chartData.options}
               series={dataLength}
               type="donut"
               height={isTasksMainPage ? 245 : 300}
            />
            {/* </div> */}
         </div>
      </div>
   );
};
