import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/AssetsHelpers";

const OrganizationsBanner = () => {
   return (
      <div className="organizationsBanner">
         <div className="organizationsBannerInfo">
            <h2>My organizations in KAPsurvey</h2>
         </div>
         <div className="organizationsBannerImage">
            <img src={toAbsoluteUrl("/media/svg/survey/PreviewBanner.svg")} alt="Survey banner" />
         </div>
      </div>
   );
};

export default OrganizationsBanner;
