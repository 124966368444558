import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeForm from "./StripeForm";
import FreeTrialSuccessfulPage from "./FreeTrialSuccessfulPage";
import { httpService } from "../../../base/services/httpService.service";
import { SessionService } from "../../../base/services/session.service";
import "./stripe.css";
import KAPsurveyLoader from "../../../assets/loader/KAPsurveyLoader";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const { REACT_APP_STRIPE_PUBLISHABLEKEY } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLEKEY);

export default function StripePaymentPage() {
   const [isLoading, setIsLoading] = useState(true);
   const [clientSecret, setClientSecret] = useState();

   useEffect(() => {
      httpService
         .post(
            "/payment/stripe/createSubscription",
            {
               organizationId: SessionService.getItem("currentOrgId"),
               packetId: SessionService.getItem("registeringPlan")?.id
            },
            (res) => {
               console.log("resss:", res.data.clientSecret);
               SessionService.setItem("subscriptionId", res.data.subscriptionId);
               setClientSecret(res.data.clientSecret);
            }
         )
         .finally(() => {
            setIsLoading(false);
         });
   }, []);

   const appearance = {
      theme: "stripe"
   };
   const options = {
      clientSecret,
      appearance
   };

   return (
      <div className="App">
         {isLoading && <KAPsurveyLoader />}
         {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
               <StripeForm clientSecret={clientSecret} />
               <FreeTrialSuccessfulPage clientSecret={clientSecret} />
            </Elements>
         )}
      </div>
   );
}
