import { useEffect, useMemo } from "react";
import { useSurveyInputStore } from "../../../../surveyStore";
import useGetOrderedSurveys from "../../../../editor/logic-builder/hooks/useGetOrderedSurveys";
import useEvaluateConstraint from "../../question-preview/hooks/useEvaluateConstraint";
import { REGEX } from "../../../../../../../helpers/constraintParsers";

const CalculateQuestion = ({ props, surveyId, userId, submissionId, defaultValue, language }) => {
   const { answers } = props;

   const setInputs = useSurveyInputStore((state) => state.setInputs);
   const orderedSurveys = useGetOrderedSurveys();
   const jrChoiceNameMatch = props.calculation && props.calculation.match(REGEX.jrChoiceName);
   const hasChoiceNameRegex = jrChoiceNameMatch && jrChoiceNameMatch?.[0] !== "";

   const choiceNameOptions = useMemo(() => {
      if (!hasChoiceNameRegex) return [];
      if (!orderedSurveys?.surveyOrdered || !jrChoiceNameMatch?.[2]) return [];
      const surveyItem = orderedSurveys.surveyOrdered.find((item) =>
         jrChoiceNameMatch[2].includes(item.surveyItem?.name)
      );
      if (!surveyItem) return [];

      return surveyItem.surveyItem.selectQuestionChoices.map((item) => {
         const translatedLabel = item.choiceLabels.find((label) => label.languageId === language)
            ?.text;
         const fallbackLabel = item.choiceLabels[0]?.text;

         return {
            label: translatedLabel || fallbackLabel,
            value: item.name
         };
      });
   }, [hasChoiceNameRegex, orderedSurveys, jrChoiceNameMatch, language]);

   const evaluateConstraint = useEvaluateConstraint(
      jrChoiceNameMatch?.[0],
      true,
      choiceNameOptions
   );

   const hasSubmissionId = Boolean(submissionId);

   const submissionAnswer = useMemo(
      () => answers?.find((answer) => answer?.submissionId === submissionId)?.answerDetail?.text,
      [answers, submissionId]
   );

   useEffect(() => {
      const value = hasSubmissionId ? submissionAnswer : evaluateConstraint ?? defaultValue;
      setInputs({
         target: {
            name: props.id,
            value: {
               surveyId: surveyId,
               userId: userId,
               questionId: props.id,
               questionType: props.questionType,
               name: props.name,
               skipLogicAnswer: value,
               answerDetail: {
                  text: value
               }
            }
         }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [defaultValue, hasSubmissionId, submissionAnswer, evaluateConstraint]);

   return null;
};

export default CalculateQuestion;
