export const returnPreviousPage = (pathname, roles, isSingleOrganization = false) => {
   if (
      pathname === "/workspace/new-work-space" ||
      pathname === "/project-selection" ||
      pathname.includes("workspace/edit") ||
      pathname === "/account-summary" ||
      pathname === "/profile-information" ||
      pathname === "/subscription-plans" ||
      pathname === "/login-information" ||
      pathname === "/admin-deleted-items"
   ) {
      return { text: "Back to workspaces", path: roles ? "/workspace" : "admin-workspaces" };
   } else if (pathname === "/dashboard" || pathname.includes("project-selection/create-project/")) {
      return { text: "Back to projects", path: "/project-selection" };
   } else if (pathname === "/survey/survey-data" || pathname === "/survey/survey-data") {
      return { text: "Back to dashboard", path: "/dashboard" };
   } else if (
      pathname.includes("survey") ||
      (pathname.includes("beneficiaries/") && !pathname.includes("beneficiaries/table"))
   ) {
      return { text: "Go back", path: -1 };
   } else if (
      pathname.includes("/targets/edit-samplesize") ||
      pathname.includes("/targets/samplesizecalculator")
   ) {
      return { text: "Go back", path: -1 };
   } else if (
      !isSingleOrganization &&
      (pathname === "/workspace" ||
         pathname.includes("/admin-workspaces") ||
         pathname.includes("/mytasks"))
   ) {
      return { text: "Back to organizations", path: "/organizations" };
   } else {
      return { text: "Back to dashboard", path: "/dashboard" };
   }
};
