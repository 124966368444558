import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";
import { ReactComponent as ProjectsSpaceIcon } from "./projectsSpaceIcon.svg";
import { ReactComponent as FeedbackSpaceIcon } from "./feedbackSpaceIcon.svg";

function SpaceSelectionPage() {
   return (
      <div className="new-selection-page">
         <h2>Select a space</h2>
         <div className="selection-description">
            <p>Which type of space would you like to work on?</p>
         </div>
         <div className="selection-import-build-box-group">
            <div className="new-selection-box disabled">
               <div className="selection-icon">
                  <FeedbackSpaceIcon />
               </div>
               <h3>Feedback space</h3>
            </div>
            <Link to={"/project-selection"}>
               <div className="new-selection-box">
                  <div className="selection-icon">
                     <ProjectsSpaceIcon />
                  </div>
                  <h3>Projects' space</h3>
               </div>
            </Link>
         </div>
      </div>
   );
}

export default SpaceSelectionPage;
