import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DropDownComp } from "./components/DropDown";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { httpService } from "../../../base/services/httpService.service";
import { LocalStorageService } from "../../../base/services/local-storage.service";
import AuthService from "../../../base/services/authentication.service";
import { Spin, notification } from "antd";
import BasicModal from "../../../ui/BasicModal/BasicModal";
import ReactivatedModal from "../../workspace/components/ReactivatedModal/ReactivatedModal";
import AddPaymentMethodModal from "../../workspace/components/AddPaymentMethodModal/AddPaymentMethodModal";
import { EntityTypeEnum } from "../../../utils/enums";
import https from "../../../base/utils/http";
import { withErrorBoundary } from "../../../components/ErrorBoundary/withErrorBoundary";

import "./style.scss";

function AdminWorkspace() {
   const userDetails = JSON.parse(localStorage.getItem("roles"));
   const [isLoading, setIsLoading] = useState(false);
   const [workSpaces, setWorkSpaces] = useState([]);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [api, contextHolder] = notification.useNotification();

   const navigate = useNavigate();

   const openNotificationWithIcon = (type, description) => {
      if (type === "success") {
         api[type]({
            message: "Successfully",
            description: "Workspace Added Successfully"
         });
      } else {
         api[type]({
            message: "Error",
            description
         });
      }
   };

   useEffect(() => {
      setIsLoading(true);
      httpService.get("/Workspaces/getMyWorkspaces", (res) => {
         setWorkSpaces(res.data);
         setIsLoading(false);
      });
   }, []);

   const handleDelete = async (id) => {
      try {
         const params = new URLSearchParams({
            entityType: EntityTypeEnum.Workspace,
            isDeleted: true
         });
         const res = await https.put(
            `/DataCollection/${id}/toggleDeleteOrRestore?${params.toString()}`
         );

         notification.open({
            type: res.data.isSuccess ? "success" : "error",
            message: res.data.message || "Something went wrong! Please try again later."
         });
         if (res.data.isSuccess) {
            setWorkSpaces((prevWorkSpaces) =>
               prevWorkSpaces.filter((workspace) => workspace.id !== id)
            );
         }
      } catch (error) {
         if (
            error.response.status === 400 &&
            Array.isArray(error.response.data.errors) &&
            error.response.data.errors.includes &&
            error.response.data.errors.includes(
               "One or more of the projects in this workspace have surveys and can't be deleted!"
            )
         ) {
            setIsModalOpen(true);
         } else {
            openNotificationWithIcon("error", "Something went wrong. Please try again.");
         }

         notification.error({ message: "Error deleting workspace, please try again later." });
      }
   };

   const enterWorkspace = (workspaceId) => {
      LocalStorageService.setItem("currentWorkspaceId", workspaceId);
      LocalStorageService.removeItem("currentProjectID");
      navigate("/project-selection");
   };

   useEffect(() => {
      LocalStorageService.removeItem("currentWorkspaceId");
      LocalStorageService.removeItem("currentProjectID");
      navigate("/admin-workspaces");
   }, [navigate]);

   return (
      <div className="workspace-choose-page">
         {contextHolder}
         <h3>Welcome back, {AuthService.getUser()?.fullName}!</h3>
         <span>{(userDetails?.[0].name).replace(/([a-z])([A-Z])/g, "$1 $2")}</span>

         <div className="workspace-section">
            <h4>Your workspaces</h4>
            <p>Choose the workspace you would like to work on</p>
            <section className="workspaces">
               {isLoading ? (
                  <Spin
                     style={{
                        position: "absolute",
                        zIndex: "2",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)"
                     }}
                  />
               ) : (
                  workSpaces?.map((workspace) => {
                     return (
                        <div className="work-box" key={workspace.id}>
                           <DropDownComp handleDelete={handleDelete} id={workspace.id} />

                           <div
                              onClick={() => enterWorkspace(workspace.id)}
                              className="your-workspaces clickable"
                              key={workspace.id}
                           >
                              <div>
                                 <div style={{ height: "45px" }}>
                                    <span>
                                       <img
                                          src={`${toAbsoluteUrl(
                                             "/media/svg/custom-icons/workspace/workspace-icon.png"
                                          )}`}
                                          alt=""
                                       />
                                    </span>
                                 </div>
                                 <div style={{ height: "50px", padding: "0 10px" }}>
                                    <p>{workspace.name}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     );
                  })
               )}
               <Link to={"/workspace/new-work-space"}>
                  <div className="add-new-workspace">
                     <div>
                        <span style={{ cursor: "pointer" }}>
                           <img
                              src={`${toAbsoluteUrl(
                                 "/media/svg/custom-icons/workspace/added-icon.png"
                              )}`}
                              alt=""
                           />
                        </span>
                        <p>New workspace</p>
                     </div>
                  </div>
               </Link>
            </section>
         </div>
         <BasicModal isModalOpen={isModalOpen} handleCancel={() => setIsModalOpen(false)}>
            <h3
               style={{
                  textAlign: "center"
               }}
            >
               One or more of the projects in this workspace have surveys and can't be deleted!
            </h3>
         </BasicModal>
         <ReactivatedModal />
         <AddPaymentMethodModal />
      </div>
   );
}

export default withErrorBoundary(AdminWorkspace);
