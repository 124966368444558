import { create } from "zustand";
import { httpService } from "../../base/services/httpService.service";

export const useAuthSore = create((set) => ({
   registStep: 0,
   currentOrgId: null,
   setRegistStep: (payload) => set((state) => ({ registStep: payload })),
   setcurrentOrgId: (payload) => set((state) => ({ currentOrgId: payload }))
}));

export const useProfileStore = create((set) => ({
   formData: {},
   organizationMemberId: null,
   userLetter: null,
   isLoading: true,
   getUserAndOrganizationInfo: (organizationId, userId, loading = true) => {
      set({ isLoading: loading });
      httpService
         .get(
            `/Organizations/getUserAndOrganizationInfo?organizationId=${organizationId}&userId=${userId}`,
            (response) => {
               const { data } = response;
               const initialValues = {
                  ...data,
                  ...data?.geographicalDetail,
                  userRoleWithinOrganization: data.userRoleWithinOrganization
                     .split(/(?=[A-Z])/)
                     .join(" ")
               };
               set({
                  formData: initialValues,
                  organizationMemberId: data.organizationMemberId,
                  userLetter: data?.userFullName?.charAt(0),
                  isLoading: false
               });
            }
         )
         .catch(() => {
            set({ isLoading: false });
         });
   }
}));

export const usePersonalDataStore = create((set) => ({
   personalData: {
      userFullName: "",
      profilePictureUrl: ""
   },
   setPersonalData: (data) => set((state) => ({ personalData: { ...state.personalData, ...data } }))
}));
