import React from "react";
import { BsTrash3 } from "react-icons/bs";

import { Modal, Button } from "antd";

const DeleteModal = ({ open, setOpen, showModal, handleDelete, id }) => {
   const hideModal = () => {
      setOpen(false);
   };
   return (
      <>
            
         <Modal open={open} onOk={hideModal} okText="Yes" cancelText="No" className='workspace-modal' closable={false}>
            <div className="remove-modal d-flex flex-column align-content-center text-center">
               <p>
                  <span>
                     <BsTrash3 />
                  </span>
               </p>
               <h3>Delete Workspace</h3>
               <p>
                  Are you sure you want to delete this workspace ? This action cannot be undone and
                  will delete all the data related to this workspace, including surveys, survey
                  submissions, labels etc.
               </p>
            </div>
            <div className="delete-modal-button-group">
               <button onClick={hideModal} className="delete-modal-cancel">
                  No, keep it
               </button>
               <button onClick={() => {handleDelete(id); hideModal()}} className="yes-i-want-this">
                  Yes, delete it
               </button>
            </div>
         </Modal>
      
      </>
   );
};

export { DeleteModal };
